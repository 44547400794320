import React from 'react';
import ErrorBoundary from 'components/ErrorBoundary';
import {config} from 'config';
import {generateKey} from 'config/docRequest';
import {Grid} from '@material-ui/core';

import MaterialTable from 'material-table';

export function SubFormLIstEdit(props) {
    const [isLoading, setIsLoading]   = React.useState(false);
    const [isDefining, setIsDefining] = React.useState(false);
    
    const {
              listTitle,
              canEdit,
              canDelete,
              canAdd,
              values,
              onChange,
              item,
              detail,
              handleChange,
              setIsLoadingTop,
              parentRowData,
          } = {
        setIsLoadingTop: setIsLoading,
        canAdd:          true,
        canEdit:         true,
        canDelete:       true,
        ...props,
    };
    
    React.useMemo(() => {
        let columns = props.cols;
        
        if ('function' === typeof columns) {
            columns = columns(props, values);
        }
        if (columns instanceof Promise) {
            columns.then(data => {
                setIsDefining(data);
            });
        }
        else {
            setIsDefining(props.cols);
        }
    }, [props, values]);
    
    const tableRef = React.createRef();
    
    React.useEffect(() => {
        if (isLoading) {
            // force close DetailPanel
            tableRef.current && tableRef.current.onToggleDetailPanel([0], '');
        }
    }, [isLoading, tableRef]);
    
    let onRowDelete = oldData =>
        new Promise((resolve) => {
            setTimeout(() => {
                {
                    let data    = [...values];
                    const index = data.indexOf(
                        oldData);
                    data.splice(index, 1);
                    onChange(null, data);
                    resolve();
                    // this.setState({ data }, () =>
                    // resolve());
                }
                resolve();
            }, 100);
        });
    
    let onRowEdit = (newData, oldData) =>
        new Promise((resolve) => {
            setIsLoadingTop(true);
            const data = [...values];
            console.log('newData', newData, data);
            const index = data.indexOf(oldData);
            let tmpData = {...newData};
            ['loading_date', 'unloading_date'].forEach(fieldChange => {
                if (tmpData[fieldChange] && typeof tmpData[fieldChange] == 'string') {
                    tmpData[fieldChange] = tmpData[fieldChange].substr(0, 10);
                }
            });
            console.log('tmpData', tmpData);
            
            data[index] = tmpData;
            onChange(null, data.map(({tableData, ...i}) => i), resolve);
            console.log('newData', tmpData, data, [...data]);
            setTimeout(() => {
                resolve();
                // this.setState({ data }, () =>
                // resolve());
                setIsLoadingTop(false);
            }, 0);
        });
    
    let onRowAdd = newData =>
        new Promise((resolve) => {
            let tmpData = {...newData};
            
            // tmpData['keyId'] = generateKey(v => console.log('valuesNew generateKey',v));
            const find = isDefining.find(i => i.field === 'key_id');
            if (!find) {
                console.error('valuesNew not find key_id in :', isDefining);
            }
            // Заполнене key_id дочернего элемента
            // todo: оптимизировать
            if (parentRowData) {
                tmpData['key_id'] = parentRowData['keyId'];
            }
            else {
                tmpData['keyId'] = generateKey(() => {});
            }
            console.log('valuesNew tmpData', tmpData, isDefining, parentRowData);
            ['loading_date', 'unloading_date'].forEach(fieldChange => {
                if (tmpData[fieldChange] && typeof tmpData[fieldChange] == 'string') {
                    tmpData[fieldChange] = tmpData[fieldChange].substr(0, 10);
                }
            });
            const valuesNew = [...values, tmpData];
            console.log('valuesNew', valuesNew);
            
            onChange(null, valuesNew.map(({tableData, ...i}) => i), resolve);
            setTimeout(() => {
                resolve();
            }, 100);
        });
    
    let detailPanelRenderDefault = rowData => {
        const valuesAll   = (
            item[detail.field] || []
        ).map(({tableData, ...i}) => i); // вырезка tableData
        const valuesSub   = valuesAll.filter(
            i => i.key_id === rowData.keyId,
        );
        const valuesOther = valuesAll.filter(
            i => i.key_id !== rowData.keyId,
        );
        
        //filter(i=>i.key_id === rowData.key_id)
        const handleChangeTable = (event, valuesNew, resolve) => {
            console.log('valuesNew', valuesNew, valuesNew.map(({tableData, ...i}) => i)); // вырезка
                                                                                          // tableData
            handleChange({field: detail.field})(null, [
                ...valuesOther,
                ...valuesNew.map(({tableData, ...i}) => i),
            ], resolve);
        };
        const setRowDataKeyId   = (key_id) => {
            const index = values.indexOf(rowData);
            console.log('valuesNew key index', index);
            rowData.key_id = key_id;
            values[index]  = rowData;
            onChange(null, values.map(({tableData, ...i}) => i), tableRef.current.onQueryChange);
        };
        try {
            return (
                <Grid container>
                    <Grid item xs={1}>
                        {/*<pre>{JSON.stringify(valuesAll, null, 2)}</pre>*/}
                        {/*<pre>{JSON.stringify(valuesOther, null, 2)}</pre>*/}
                        {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
                    </Grid>
                    <Grid item xs={11}>
                        <ErrorBoundary>
                            <SubFormLIstEdit
                                item={valuesSub}
                                itemValues={valuesSub}
                                // onChange={handleChangeValues(i)}
                                // apiFilter={apiFilter &&
                                // apiFilter(values)}
                                onChange={handleChangeTable}
                                setIsLoadingTop={setIsLoading}
                                values={valuesSub}
                                parentRowData={rowData}
                                // errors={errors[detail.field]||[]}
                                {...detail}
                                cols={detail.cols
                                    ? detail.cols(item[detail.field], rowData, setRowDataKeyId)
                                    : []}
                            />
                        </ErrorBoundary>
                    </Grid>
                </Grid>
            );
        }
        catch (e) {
            return 'Error: ' + e.message;
        }
        
    };
    
    return <React.Fragment>
        {/*{(new Date()).toJSON()}*/}
        {/*<pre>values={JSON.stringify(values, null, 2)}</pre>*/}
        <MaterialTable
            isLoading={isLoading || !isDefining}
            title={listTitle}
            columns={isDefining || []}
            data={() =>
                new Promise((resolve) => {
                    resolve({data: values});
                })
            }
            tableRef={tableRef}
            actions={[
                // canEdit && {
                //     icon:    EditIcon,
                //     tooltip: 'Редактировать',
                //     onClick: (event, rowData) => onEdit(rowData)(),
                // },
                //
                {
                    icon:         'refresh',
                    tooltip:      'Обновить данные',
                    isFreeAction: true,
                    onClick:      () => tableRef.current.onQueryChange(),
                },
            ]}
            {...config.MaterialTable}
            // components={{
            //     Cell: MyMTableCell,
            // }}
            options={{
                search:                 false,
                showTitle:              false,
                paging:                 false,
                toolbarButtonAlignment: 'left',
            }}
            components={{
                // EditRow: props => <pre>{JSON.stringify(props,null,2)}</pre>
                // Toolbar: props => (
                //     <div>
                //         <MTableToolbar {...props} />
                //     </div>
                // ),
            }}
            detailPanel={detail && [
                {
                    tooltip: detail.tooltip || 'Услуги',
                    render:  detail.render || detailPanelRenderDefault,
                },
            ]}
            editable={{
                // isEditHidden: () => !canEdit,
                // isDeleteHidden: () => !canDelete,
                onRowAdd:    canAdd
                                 ? onRowAdd
                                 : null,
                onRowUpdate: canEdit
                                 ? onRowEdit
                                 : null,
                onRowDelete: canDelete
                                 ? onRowDelete
                                 : null,
            }}
        /></React.Fragment>;
}