import AdminShow from 'components/FieldFormat/AdminShow';
import React from 'react';
import {TextField} from '@material-ui/core';
import {FormDataContext} from 'FormDataContext';
import NumberFormat from 'react-number-format';

//
// function InputWithMask(props) {
//     const {inputRef, mask, ...other} = props;
//     if(!mask){
//         console.error('Mask not set for: ', other.name)
//     }
//     return (
//         <MaskedInput
//             {...other}
//             ref={(ref) => {
//                 inputRef(ref ? ref.inputElement : null);
//             }}
//             mask={mask||[]}
//             placeholderChar={'\u2000'}
//             showMask
//         />
//     );
// }

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            allowLeadingZeros={true}
            format={s=>{console.log('format',s); return s}}
            removeFormatting={ v => {
                return v.replace(/[^0-9\+]/,'');
            }}
            mask={"_"}
        />
    );
}

function MyComponent(props) {
    const {submitting, item, value, error, handleChange} = props;
    const {
              topColsBlock,
              disabled,
              field,
              title,
              format,
              options,
              api,
              required,
              apiFilter,
              mask,
              ...other
          }                                              = item;

    return <React.Fragment>
        <AdminShow/>
        <TextField
            disabled={disabled || submitting}
            label={title}
            value={value}
            required={required}
            margin={'dense'}
            fullWidth
            variant={'outlined'}
            name={field}
            onChange={handleChange}
            error={!!error}
            helperText={error}
            InputLabelProps={{shrink: true}}
            inputProps={{mask}}
            InputProps={{
                inputComponent: NumberFormatCustom,
            }}
            {...other}
        />
    </React.Fragment>;
}

function areEqual(prevProps, nextProps) {
    return prevProps.value === nextProps.value
        && prevProps.error === nextProps.error;
}

const MyComponentMemo = React.memo(MyComponent, areEqual);

function MyComponentMemoUse(props) {
    const {field}                                 = props.item;
    const {state: {values, errors}, handleChange} = React.useContext(FormDataContext);
    const handleChangeLocal                       = (e) => {
        console.log('handleChange Input', e, e.target, e.target.value);
        return handleChange(props.item)(e, e.target.value);
    };

    const value = values && values[field];
    const error = errors && errors[field];

    return <MyComponentMemo
        values={values}
        value={value || ''}
        error={error || null}
        handleChange={handleChangeLocal}
        {...props}
    />;
}

export default MyComponentMemoUse;