import React from 'react';
import {useSelector} from 'react-redux';


export default WrappedComponent => props => {
    const {cols}                = props;
    const me        = useSelector(s => s.system && s.system.me && s.system.me.items)|| {};
    console.log('props explainCols', props);
    const [columns, setColumns] = React.useState([]);
    React.useMemo(() => {
        let c = 'function' === typeof cols
            ? cols(props, {}, {from: 'requestForm', me})
            : cols;

        if (c instanceof Promise) {
            c.then(data => setColumns(data));
        } else {
            setColumns(c);
        }
        // eslint-disable-next-line
    }, [cols]);
    return <WrappedComponent {...props} cols={columns}/>;
};

