import Custom from 'components/FieldFormat/Custom';
import SimpleInput from 'components/FieldFormat/SimpleInput';
// import NumberSimpleInput from 'components/FieldFormat/NumberSimpleInput';
import Textarea from 'components/FieldFormat/Textarea';
import SelectOptions from 'components/FieldFormat/SelectOptions';
import SelectApi from 'components/FieldFormat/SelectApi';
import SubForm from 'components/FieldFormat/SubForm';
import SubTable from 'components/FieldFormat/SubTable';
import SelectDadata from 'components/FieldFormat/SelectDadata';
import FieldFormatDate from 'components/FieldFormat/Date';
import DatetimeLocal from 'components/FieldFormat/DatetimeLocal';
import Checkbox from 'components/FieldFormat/Checkbox';
import {FieldFormat} from './FieldFormat';
import SelectApiCheckbox from 'components/FieldFormat/SelectApiCheckbox';
import SelectApiIndex from 'components/FieldFormat/SelectApiIndex';

export const RenderList = (format) => {
    const formats = {
        [FieldFormat.FORMAT_CUSTOM]:              Custom,
        [FieldFormat.FORMAT_INPUT]:               SimpleInput,
        // [FieldFormat.FORMAT_NUMBER]:              NumberSimpleInput,
        // [FieldFormat.FORMAT_FLOAT]:               NumberSimpleInput,
        [FieldFormat.FORMAT_TEXTAREA]:            Textarea,
        [FieldFormat.FORMAT_SELECT]:              SelectOptions,
        [FieldFormat.FORMAT_SELECT_API]:          SelectApi,
        [FieldFormat.FORMAT_SUB_FORM]:            SubForm,
        [FieldFormat.FORMAT_SUB_TABLE]:           SubTable,
        [FieldFormat.FORMAT_SELECT_DADATA]:       SelectDadata,
        [FieldFormat.FORMAT_DATE]:                FieldFormatDate,
        [FieldFormat.FORMAT_DATETIME_LOCAL]:      DatetimeLocal,
        [FieldFormat.FORMAT_CHECKBOX]:            Checkbox,
        [FieldFormat.FORMAT_SELECT_API_CHECKBOX]: SelectApiCheckbox,
        [FieldFormat.FORMAT_SELECT_API_INDEX]:    SelectApiIndex,
    };
    return formats[format] || formats[FieldFormat.FORMAT_INPUT];
};
