import {ServiceIcon} from 'components/icons';
import {ROLES} from 'config/roles';
import PageUserRole from 'page/PageUserRole';

export default {
    listTitle: 'Роль пользователя',
    apiName:   'user_roles',
    itemName:  'Роль пользователя',
    canDelete: true,
    roles:     [
        ROLES.ROLE_USER,
    ],
    menu:      {
        title:     'Роли',
        path:      '/user_roles',
        Component: PageUserRole,
        roles:     [ROLES.ROLE_USER],
        icon:      ServiceIcon,
    },
    cols:      [
        {
            title:    'Наименование',
            field:    'name',
            required: true,
        },
        {
            title:    'Код',
            field:    'code',
            required: true,
        },
    ],
};
