import {MeContext} from 'meContext';
import React from 'react';

export default function AdminShow({children}) {
    const {roles} = React.useContext(MeContext);
    const time = new Date();
    return <React.Fragment>
        {/*{children}*/}
        {/*{roles && roles.includes('ROLE_DEBUG') && children}*/}
        {/*{roles && roles.includes('ROLE_DEBUG') && time.toJSON()}*/}
    </React.Fragment>;
}
