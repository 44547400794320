import AdminShow from 'components/FieldFormat/AdminShow';
import React, {useEffect} from 'react';
import system from 'actions/system';
import TableMaterialTableShow from 'components/TableMaterialTableShow';
import detectCols from 'libs/detectCols';
import {
    Button,
} from '@material-ui/core';
import {
    useSelector,
    useDispatch,
} from 'react-redux';

import DialogEditCreate3 from 'components/DialogEditCreate3';
import RequestForm from 'components/RequestForm';
import API from 'libs/api';

import {
    AddIcon,
} from 'components/icons';

import {useHistory} from 'react-router-dom';
import request from 'superagent';
import FilterTableParams from './FilterTableParams';

const api = new API();

// const history = createBrowserHistory({});

const defaultProps = {
    cols: detectCols,
    parent: 'a',
};

const initialState = {
    open:     false,
    anchorEl: null,
    loadData: () => {},
};

export default function PageApiShow3(props) {
    const {
              canAdd,
              fabStyle,
              canDelete,
              itemName,
              newTable,
              listTitle,
              canEdit,
              topColsBlocks,
              apiName,
              url,
              filter,
              filterParent,
              parent,
              cols,
              ...other
          }
              = {...defaultProps, ...props};
    
    const history = useHistory();
    
    const [state, setState] = React.useState(initialState);
    
    if (window.tracePath) {
        console.log('tracePath: ', props.tracePath.join('/'));
    }
    const {open, values} = state;
    
    const filterParentResult = filterParent
        ? filterParent(parent)
        : {};
    const [filterList, onFilterChange] = React.useState({});
    const query              = {...filter, ...(parent||{}).filter, ...filterParentResult, ...filterList};

    const itemsName = system.getItemsName(query);
    const dispatch  = useDispatch();
    
    const list = {
        items:     useSelector(
            state => state.system[apiName] && state.system[apiName][itemsName],
        ),
        timestamp: useSelector(
            state => state.system[apiName] && state.system[apiName]['timestamp'],
        ),
        isFetching: useSelector(
            state => state.system[apiName] && state.system[apiName]['isFetching'],
        ),
    };
    
    const loadData = React.useCallback(
        () => {
            console.log('load do 1');
            dispatch(system.fetchFilter(apiName, query));
        }, [apiName, query, dispatch]);
    
    useEffect(() => {
        // console.log('??load');
        if (!list.items || !list.timestamp || (
            list.timestamp + 300
        ) < Date.now()) {
            loadData();
            console.log('load do 2');
        }
    }, [dispatch, apiName, list, query, loadData]);

    
    const isItemCanDelete = () => {
        return true;
    };

    const onEdit = row => () => {
        // or backLink?
        history.push(
            row
                ? `${url}/${row.guid || row.id}/edit`
                // :`/legals/${filter.parent_guid}/${apiName}/new`
                : `${url}/new`,
        );
    };
        const onShow = row => () => {
        // or backLink?
        history.push(
            row
                ? `${url}/${row.guid || row.id}`
                // :`/legals/${filter.parent_guid}/${apiName}/new`
                : `${url}/not-found`,
        );
    };
    
    const onDelete = row => {
        return request.put(`/api/multi/${apiName}/${row.guid || row.id}`)
            .set('accept', 'application/json')
            .send({...row, isDeleted: true});
    };
    
    const handleClose = () => {
        setState({open: false});
    };
    
    const handleSubmit = (values, action) => {
        return api[action](apiName, values);
    };
    
    // console.log('preCols',typeof preCols.then);
    // console.log('preCols()',preCols(),props);
    
    // const cols = 'function' === typeof preCols
    //     ? preCols(props, list)
    //     : preCols
    // ;
    //
    // if (canEdit) {
    //     cols.unshift(
    //         {
    //             field:  'edit',
    //             title:  <MoreIcon/>,
    //             object: Edit,
    //             format: FieldFormat.FORMAT_NONE,
    //             //props: {}
    //         },
    //     );
    // }
    
    return <React.Fragment>
        <AdminShow>[PageApiShow3]</AdminShow>
        {/*<RowMenu />*/}
        {canAdd && <Button
            aria-label="Add"
            title={'Добавить'}
            variant={'contained'}
            color={'primary'}
            size={'small'}
            style={fabStyle}
            onClick={onEdit()}
            startIcon={<AddIcon/>}
        >
            Добавить
        </Button>}
        <FilterTableParams apiName={apiName} cols={cols} handleChange={onFilterChange} initialValues={filterList}/>
        <TableMaterialTableShow
            list={list}
            cols={cols}
            listTitle={listTitle}
            newTable={true}
            onEdit={onEdit}
            onShow={onShow}
            onDelete={onDelete}
            loadData={loadData}
            canEdit={canEdit}
            canDelete={canDelete}
            url={url}
            {...other}
            tracePath={[...props.tracePath, 'TableMaterialTableShow']}
        />
        
        <DialogEditCreate3
            dbId={'id'}
            fullScreen
            itemName={itemName}
            open={open}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            onSuccess={loadData}
            initialValues={values}
            FormComponent={RequestForm}
            canDelete={canDelete}
            cols={cols}
            topColsBlocks={topColsBlocks}
            canDeleteItem={isItemCanDelete}
            tracePath={[...props.tracePath, 'DialogEditCreate3']}
        />
    </React.Fragment>;
}
