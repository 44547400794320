import {ServiceIcon} from 'components/icons';
import {FieldFormat} from 'config/FieldFormat';
import {ROLES} from 'config/roles';
import PageWorkObjectType from 'page/PageWorkObjectType';

export default {
    listTitle: 'Тип объекта',
    apiName:   'work_object_types',
    itemName:  'тип объекта',
    canDelete: true,
    roles:     [
        ROLES.ROLE_USER,
    ],
    menu:      {
        title:     'Тип объекта',
        path:      '/work_object_types',
        Component: PageWorkObjectType,
        roles:     [ROLES.ROLE_USER],
        icon:      ServiceIcon,
    },
    cols:      [
        {
            title: 'Наименование',
            field: 'name',
        },
        {
            title: 'Описание',
            field: 'description',
            format: FieldFormat.FORMAT_TEXTAREA,
        },
    ],
};
