import 'react-app-polyfill/ie11';
//import 'react-app-polyfill/stable';
import {SYSTEM} from 'reducers/system';
import API from 'libs/api';

const request = new API();

const system = {
    
    fetchMe: () => {
        
        return dispatch => {
            dispatch({type: SYSTEM.REQUEST_ME});
            request.get(`users/me`)
                .then(payload => dispatch({type: SYSTEM.RECEIVE_ME, payload}));
        };
    },
    
    fetchManagers: () => {
        
        return dispatch => {
            dispatch({type: SYSTEM.REQUEST_MANAGERS});
            request.get(`users/managers`)
                .then(payload => dispatch({type: SYSTEM.RECEIVE_MANAGERS, payload}));
        };
    },
    
    fetchContractorManagers: () => {
        
        return dispatch => {
            dispatch({type: SYSTEM.REQUEST_CONTRACTOR_MANAGERS});
            request.get(`contractor/managers`)
                .then(payload => dispatch({type: SYSTEM.RECEIVE_CONTRACTOR_MANAGERS, payload}));
        };
    },
    
    fetchUsers: (query = {}) => {
        
        return dispatch => {
            dispatch({type: SYSTEM.REQUEST_USERS});
            request.get(`users`, query)
                .then(payload => dispatch({type: SYSTEM.RECEIVE_USERS, payload}));
        };
    },
    
    fetchRoles: () => {
        
        return dispatch => {
            dispatch({type: SYSTEM.REQUEST_ROLES});
            request.get(`users/roles`)
                .then(payload => dispatch({type: SYSTEM.RECEIVE_ROLES, payload}));
        };
    },
    
    fetchNewComments: () => {
        return dispatch => {
            dispatch({type: SYSTEM.REQUEST_NEW_COMMENTS});
            request.get(`comments/new`)
                .then(payload => dispatch({type: SYSTEM.RECEIVE_NEW_COMMENTS, payload}));
        };
    },
    
    fetch: (endPoint, query = {}, onError = null) => dispatch => {
        console.log('Class: default, Function: fetch, Line 63 (): '
            , endPoint);
        dispatch({type: SYSTEM.REQUEST, endPoint});
        request.get(endPoint, query, onError)
            .then(
                payload => dispatch({type: SYSTEM.RECEIVE, endPoint, payload: {items: payload}}),
                error => dispatch({type: SYSTEM.RECEIVE, endPoint, payload: {error: error.message || error}}),
            );
    },
    
    getItemsName: (query) => {
        return (
            query && Object.entries(query).map(i => i.join('=')).join('&')
        ) || 'items';
        
    },
    
    fetchFilter: (endPoint, query = {}, onError = null) => dispatch => {
        console.log('Class: default, Function: fetchFilter, Line 63 (): '
            , endPoint);
        let itemsName = system.getItemsName(query);
        dispatch({type: SYSTEM.REQUEST, endPoint, payload: {[itemsName]: []}});
        request.get(endPoint, query, onError)
            .then(
                payload => dispatch({type: SYSTEM.RECEIVE, endPoint, payload: {[itemsName]: payload}}),
                error => dispatch({type: SYSTEM.RECEIVE, endPoint, payload: {error: error.message || error}}),
            );
    },
    
    fetchFonts: (url, query = {}, onError = null) => dispatch => {
        let endPoint = 'fonts';
        dispatch({type: SYSTEM.REQUEST, endPoint});
        request.getOne(url, query, onError)
            .then(
                payload => dispatch({type: SYSTEM.RECEIVE, endPoint, payload: {items: payload}}),
                error => dispatch({type: SYSTEM.RECEIVE, endPoint, payload: {error: error.message || error}}),
            );
    },
    
    fetchOne: (endPoint, guid = {}, onError = null) => dispatch => {
        if (typeof endPoint !== 'string') {
            console.error('endPoint not string ', endPoint);
            return;
        }
        console.log('fetchOne: ', endPoint);
        const match         = endPoint.match(/\/api\/(.+)\/(.+)/) || [];
        let [, ident, guid] = match;
        dispatch({type: SYSTEM.REQUEST, endPoint: ident});
        request.getOne(endPoint, guid, onError)
            .then(
                payload => dispatch({
                    type:     SYSTEM.RECEIVE,
                    endPoint: ident,
                    payload:  {[guid]: payload || {error: 'empty body', guid: 'empty body', name: 'empty body'}},
                }),
                error => dispatch({type: SYSTEM.RECEIVE, endPoint: ident, payload: {error: error.message || error}}),
            );
    },
    
};

export default system;