import {
    Grid,
    Switch,
} from '@material-ui/core';
import {FormDataContextConsumer} from 'FormDataContext';
import React from 'react';
import {MeContext} from 'meContext';

/**
 * @return {null}
 */
export default function RequestFormDebug({errors, values, debug}) {
    const [checked, setChecked] = React.useState(false);
    const {roles}               = React.useContext(MeContext);
    const toggleChecked         = () => {
        setChecked((prev) => !prev);
    };
    
    return <React.Fragment>{roles && roles.includes('ROLE_DEBUG') && <Grid item xs={12}>
        <Switch checked={checked} onChange={toggleChecked}/>
        {checked && <pre>formDebug:
            {JSON.stringify(errors, null, 2)}
            {JSON.stringify(values, null, 2)}
        </pre>}
    </Grid>}
    </React.Fragment>;
}

export function RequestFormDebugContext(debug) {
    return <FormDataContextConsumer>{context => <RequestFormDebug
        values={context.state.values} debug={debug}
        errors={context.state.errors}/>
    }</FormDataContextConsumer>;
}