import {CircularProgress, Dialog, IconButton, TextField} from '@material-ui/core';

import {MoreVert as MoreIcon} from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ErrorBoundary from 'components/ErrorBoundary';
import FormEditCreate from 'components/FormEditCreate';
import RequestForm from 'components/RequestForm';
import SelectorApiLoader from 'components/SelectorApiLoader';
import {config} from 'config';
import {FormDataContextConsumer} from 'FormDataContext';
import React from 'react';
import request from 'superagent';
import InputAdornment from '@material-ui/core/InputAdornment';

// дозагрузка отсутствующего значения
const loadSelectedValue  = async (api, value, inputValue) => {
    // console.log('loadSelectedValue', value, `/api/${api}/${value}`);
    return (
        await request.get(`/api/${api}/${value}`)
            .set('accept', 'application/json')
            .query(inputValue
                ? {name: inputValue}
                : {},
            )
    ).body;
};
const renderInputDefault = (params, {loading, handleChange, label, errors, helperText, item, apiMoreSelect, endAdornment}) => {
    return (<ErrorBoundary>
        <TextField
            {...params}
            onChange={handleChange}
            label={label}
            fullWidth
            variant="outlined"
            margin={'dense'}
            error={!!errors}
            helperText={helperText || errors}
            required={item.required}
            InputLabelProps={{shrink: true}}
            InputProps={{
                ...params.InputProps,
                startAdornment: <FormDataContextConsumer>{context => item && item.startButtons && item.startButtons({
                    values:         context.state.values,
                    setStateValues: context.setStateValues,
                })}</FormDataContextConsumer>,
                endAdornment: (
                                  <React.Fragment>
                                      {/*<InputAdornment position="end">*/}
                                      {/*    <IconButton*/}
                                      {/*        aria-label="toggle password visibility"*/}
                                      {/*        // onClick={handleClickShowPassword}*/}
                                      {/*        // onMouseDown={handleMouseDownPassword}*/}
                                      {/*    >*/}
                                      {/*        123}*/}
                                      {/*    </IconButton>*/}
                                      {/*</InputAdornment>*/}
                                      {loading && <CircularProgress
                                          color="inherit"
                                          size={20}
                                      />}
                                      {params.InputProps.endAdornment}
                                      {item && item.selection && <IconButton onClick={apiMoreSelect}
                                                                             style={{padding: 2}}>
                                          <MoreIcon/>
                                      </IconButton>}
                                  </React.Fragment>
                              ),
            }}
        /></ErrorBoundary>
    );
};

const defaultProps = {
    renderInput: renderInputDefault,
    getOptionLabel: option => option.name || '',
    noOptionsText: 'Нет вариантов',
};

export default function FormApiAutocomplete(props) {
    if (window.tracePath) {
        console.log('tracePath: ', props.tracePath.join('/'));
    }
    const {
              api,
              disabled,
              label,
              value,
              errors,
              onChange,
              apiFilter,
              item,
              multiple,
              helperText,
              renderInput,
              getOptionLabel,
              renderOption,
              endAdornment,
              noOptionsText,
          } = {...defaultProps, ...props};
    
    const [open, setOpen]                           = React.useState(false);
    const [openDialog, setOpenDialog]               = React.useState(false);
    const [loading, setLoading]                     = React.useState(false);
    const [options, setOptions]                     = React.useState([]);
    const [inputValue, setInputValue]               = React.useState('');
    const [valueSelected, setValueSelected]         = React.useState(value); // valueName
    const [initialValues, setInitialValues]         = React.useState({}); // valueName
    const [apiMoreSelectOpen, setApiMoreSelectOpen] = React.useState(false);
    
    const {subConfig, fieldShow,fieldSearch} = {
        fieldShow: 'name',
        fieldSearch: 'name',
        ...item,
    };
    
    const handleReadOnlySubmit = async (values, action) => {
        console.log('handleReadOnlySubmit?? todo remove', values, action);
        return new Promise(submitResultFunc => {
            submitResultFunc({body: {}});
        });
    };
    
    React.useEffect(() => {
        if (!value || openDialog || typeof value == 'object') {
            setValueSelected(null);
            return;
        }
        loadSelectedValue(api, value, inputValue)
            .then(item => {
                setValueSelected(item.name);
                // setInputValue(item.name); todo: сделать заполнение поиска текущем полем
            })
            .catch(error => {
                console.log(error);
                setValueSelected(error + ' ' + value);
            });
    }, [api, value, openDialog, inputValue]);
    
    React.useEffect(() => {
        let active = true;
        if (!loading) {
            return undefined;
        }
        
        (
            async () => {
                if (!active) {
                    return;
                }
                try {
                    const response = await request.get(`/api/${api}`)
                        .set('accept', 'application/json')
                        .query({[fieldSearch]: inputValue, ...apiFilter});
                    setOptions(
                        response.body.map(item => (
                            {
                                id:   `/api/${api}/${item.guid || item.id}`,
                                guid: item.guid || item.id,
                                name: item[fieldShow],
                                item
                            }
                        )),
                    );
                }
                catch (e) {
                    setOptions([{name: 'Error: ' + e.message}]);
                }
                setLoading(false);
            }
        )();
        
        return () => {
            active = false;
        };
    }, [loading, api, inputValue, apiFilter, fieldShow]);
    
    const handleChange = event => {
        setOptions([]);
        setInputValue(event.target.value);
        setLoading(true);
    };
    
    const subFormHandleChange = values => {
        onChange(null, {
            ...values,
        });
    };
    const onChangeLocal       = (event, value) => {
        console.log('onChangeLocal', value);
        if (value && value.inputValue) {
//            setValueSelected(value.inputValue);
            setInitialValues({name: value.inputValue});
            setOpenDialog(true);
            return;
        }
        setValueSelected(value && value.name);
        onChange({
            target: {
                value: value && value.id,
                guid: value && value.guid,
                item: value && value.item,
            },
        });
    };
    
    if (value && !valueSelected && typeof value == 'string') {
        return <React.Fragment>
            <CircularProgress title={'no valueSelected'}/>
        </React.Fragment>;
    }
    const freeSolo           = Boolean(subConfig);
    const apiMoreSelect      = () => {
        setApiMoreSelectOpen(true);
    };
    const apiMoreSelectClose = () => {
        setApiMoreSelectOpen(false);
    };
    
    const onSelect = ({guid}) => {
        setApiMoreSelectOpen(false);
        onChange({
            target: {
                value: `/api/${config.contactPerson.apiName}/${guid}`,
            },
        });
    };
    
    return <React.Fragment><ErrorBoundary>
        {/*<pre>{JSON.stringify(apiFilter)}</pre>*/}
        {/*<pre>config.get(subConfig):{JSON.stringify(config.get(subConfig))}</pre>*/}
        {/*{subConfig &&<pre>addNewElement:{JSON.stringify(addNewElement)}</pre>}*/}
        <Dialog fullScreen open={apiMoreSelectOpen} onClose={apiMoreSelectClose}>
            <SelectorApiLoader
                handleClose={apiMoreSelectClose}
                onSelect={onSelect}
                tracePath={[...props.tracePath, 'SelectorApiLoader']}
            />
        </Dialog>
        {openDialog && <FormEditCreate
            dbId={'guid'}
            // itemName={'itemName'}
            open={openDialog}
            // returnOnly={true}
            handleSubmit={handleReadOnlySubmit}
            handleClose={() => setOpenDialog(false)}
            subFormHandleChange={subFormHandleChange}
            onSuccess={() => {}}
            initialValues={initialValues}
            {...config.get(subConfig)}
            FormComponent={RequestForm}
            tracePath={[...props.tracePath, 'FormEditCreate']}
        />}
        {!openDialog && <Autocomplete
            multiple={multiple}
            disabled={disabled}
            freeSolo={freeSolo}
            open={open}
            onOpen={() => {
                setOpen(true);
                setLoading(true);
            }}
            onClose={() => {
                setOpen(false);
                setInputValue(null);
            }}
            filterOptions={(options, params) => {
                // console.log(options, params);
                // return options;
                //const filtered = filter(options, params);
                const filtered = options;
                
                if (freeSolo && params.inputValue !== '') {
                    filtered.push({
                        inputValue: params.inputValue,
                        name:       `Добавить "${params.inputValue}"`,
                    });
                }
                
                return filtered;
            }}
            getOptionSelected={(option, value) => value && (
                option.name === value.name
            )}
            getOptionLabel={getOptionLabel}
            options={options}
            loading={loading}
            value={open
                ? undefined
                : {
                    'guid': value,
                    'name': valueSelected,
                }}
            onChange={onChangeLocal}
            endAdornment={endAdornment}
            noOptionsText={noOptionsText}
            renderInput={params => renderInput(params, {loading, handleChange, label, errors, item, apiMoreSelect, helperText, endAdornment})}
            renderOption={renderOption}
        />}
    </ErrorBoundary></React.Fragment>;
}
