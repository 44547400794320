export const FieldFormat = {
    FORMAT_NONE:                'none',
    FORMAT_INPUT:               'input',
    FORMAT_FLOAT:               'float',
    FORMAT_TEXTAREA:            'textarea',
    FORMAT_CHECKBOX:            'checkbox',
    FORMAT_SELECT:              'select',
    FORMAT_SELECT_API:          'select_api',
    FORMAT_SELECT_API_INDEX:    'select_api_index',
    FORMAT_SELECT_API_CHECKBOX: 'select_api_check_box',
    FORMAT_DATETIME_LOCAL:      'datetime_local',
    FORMAT_DATE:                'date',
    FORMAT_TIME:                'time',
    FORMAT_SELECT_DADATA:       'select_dadata',
    FORMAT_SUB_FORM:            'sub_form',
    FORMAT_SUB_TABLE:           'sub_table',
    FORMAT_CUSTOM:              'custom',
    FORMAT_INPUT_MASK:          'input_mask',
    FORMAT_INPUT_EMAIL:         'input_email',
    FORMAT_NUMBER:              'input_number_format',
    FORMAT_PHOTO_ADD:           'photo_add',
};

