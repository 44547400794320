import {CircularProgress} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import DialogEditCreate3 from 'components/DialogEditCreate3';
import ErrorBoundary from 'components/ErrorBoundary';
import explainCols from 'components/explainCols';
import AdminShow from 'components/FieldFormat/AdminShow';
import FormEditCreateTabs from 'components/FormEditCreateTabs';
import {AddBreadCrumb} from 'components/Menu';
import RequestForm from 'components/RequestForm';

import API, {STATE} from 'libs/api';
import detectCols from 'libs/detectCols';

import PropTypes from 'prop-types';
import React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import request from 'superagent';

const defaultProps = {
    cols:            detectCols,
    initialValues:   () => {},
    onElementUpdate: () => {},
};

function ApiDataLoader(props) {
    
    let {guid} = useParams();
    const [state, setState] = React.useState(guid
        ? STATE.INIT
        : STATE.LOADED);
    
    const {
              apiName, itemName, subApi,
              AfterComponent,
          }       = {...defaultProps, ...props};
    const [item, setItem] = React.useState(null);
    const AfterComponentWrapped = explainCols(AfterComponent);
    React.useEffect(() => {
        if (STATE.INIT !== state) {
            return;
        }
        setState(STATE.LOADING);
        const url = `/api/${apiName}/${guid}`;
        request
            .get(url)
            .set('accept', 'application/json')
            .then(response => {
                console.log('response', response);
                setItem(response.body);
                setState(STATE.LOADED);
            })
            .catch(error => {
                setItem(`${error.response && error.response.status} ${error.response && error.response.statusText} ${url}`);
                setState(STATE.ERROR);
            });
    }, [state, apiName, props.item]);
    

    switch (state) {
        case STATE.INIT:
        case STATE.LOADING:
            return (
                <React.Fragment>
                    <AddBreadCrumb label={'Загрузка...'}/>
                    <CircularProgress/>
                    <AdminShow>[ApiDataLoader]</AdminShow>
                </React.Fragment>
            );
        
        case STATE.LOADED:
            const label = item
                ? item.name
                : 'Новый';

            return (
                <React.Fragment>
                    <AdminShow>[ApiDataLoader]</AdminShow>
                    <AddBreadCrumb label={label}/>
                    <ErrorBoundary>
                    <AfterComponentWrapped
                        wrap={2}
                        {...props}
                        item={item}
                        onReload={()=>setState(STATE.INIT)}
                    />
                    </ErrorBoundary>
                </React.Fragment>
            );
        case STATE.ERROR:
        default:
            return <Alert severity="error">Error: {item}</Alert>;
    }
}

ApiDataLoader.propTypes = {
    apiName: PropTypes.string.isRequired,
};
export default ApiDataLoader;

