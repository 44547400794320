import {SubUnitIcon} from 'components/icons';
import {FieldFormat} from 'config/FieldFormat';
import roadUnit from 'config/roadUnit';
import {ROLES} from 'config/roles';
import PageSubUnits from 'page/PageSubUnits';
import {ShowUser} from '../components/showItems';

export default {
    listTitle: 'Подразделения',
    apiName:   'sub_units',
    itemName:  'подразделение',
    canAdd: true,
    canEdit: true,
    canDelete: true,
    roles:     [
        ROLES.ROLE_USER,
    ],
    menu:      {
        title:     'Подразделения',
        path:      '/sub_units',
        Component: PageSubUnits,
        roles:     [ROLES.ROLE_USER],
        icon:      SubUnitIcon,
    },
    cols:      [
        {
            title:        'Наименование',
            field:        'name',
        },
        {
            title:  'Ответственный',
            field:  'responsible',
            format: FieldFormat.FORMAT_SELECT_API,
            api:    'users',
            object: ShowUser,
            cols:   3,
            required: true,
        },
        {
            title:        'Комментарий',
            field:        'description',
            format: FieldFormat.FORMAT_TEXTAREA,
        },
    ],
    subApi:    [
        {
            xs: 6,
            title: "Участки дорог",
            item:        roadUnit,
            parentField: 'unit',
            apiFilter: (item) => (
                {
                    unit: item.id,
                }
            )
        },
    ]
    
};
