import React from 'react';
import PageApiLoader from 'components/PageApiLoader3';
import {config} from 'config';

export default function Page(props) {
    return <PageApiLoader
            {...props}
            {...config.address}
            canEdit={true}
            canAdd={true}
    />;
}
