import {ServiceIcon} from 'components/icons';
import {ColBoolean, ColObjectName, ShowApiName} from 'components/showItems';
import {ROLES} from 'config/roles';
import TCM from 'page/TCM';
import {FieldFormat} from './FieldFormat';

export default {
    listTitle: 'ТСМ',
    apiName:   't_c_ms',
    itemName:  'ТСМ',
    canDelete: true,
    roles:     [
        ROLES.ROLE_USER,
    ],
    menu:      {
        title:     'ТСМ',
        path:      '/t_c_ms',
        Component: TCM,
        roles:     [ROLES.ROLE_USER],
        icon:      ServiceIcon,
    },
    cols:      [
        {
            title:  'Дорога',
            field:  'road',
            format: FieldFormat.FORMAT_SELECT_API,
            object: ShowApiName,
            api:    'roads',
            cols:   3,
            // filter: {
            //     format: FieldFormat.FORMAT_SELECT_API_CHECKBOX,
            //     field:  'workObject.address.road[]',
            // },
            // hidden: true,
        },
        {
            title:  'Тип объекта',
            field:  'type',
            format: FieldFormat.FORMAT_SELECT_API,
            api:    'work_object_types',
            object: ColObjectName,
            cols:   3,
            required: true,
        },
        {
            title:  'Ориентир',
            field:  'landmark',
            cols:   3,
            // hidden: true,
        },
        {
            title:  'Широта',
            field:  'lat',
            cols:   3,
            // hidden: true,
        },
        {
            title:  'Долгота',
            field:  'long',
            cols:   3,
            // hidden: true,
        },
    ],
};
