import {ServiceIcon} from 'components/icons';
import {ROLES} from 'config/roles';
import PageVendors from 'page/PageVendors';

export default {
    listTitle: 'Производитель',
    apiName:   'vendors',
    itemName:  'производителя',
    canDelete: true,
    roles:     [
        ROLES.ROLE_USER,
    ],
    menu:      {
        title:     'Производитель',
        path:      '/vendors',
        Component: PageVendors,
        roles:     [ROLES.ROLE_USER],
        icon:      ServiceIcon,
    },
    cols:      [
        {
            title:        'Наименование',
            field:        'name',
        },
        {
            title:        'Комментарий',
            field:        'description',
        },
    ],
};
