import {Box, Button, Container, Grid, Paper, Tab, Tabs, Typography} from '@material-ui/core';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import ConfirmDialog from 'components/ConfirmDialog';
import AdminShow from 'components/FieldFormat/AdminShow';
import {CancelIcon, InfoIcon, SaveIcon} from 'components/icons';

import LinearProgress from 'components/LinearProgress';
import {AddBreadCrumb} from 'components/Menu';
import PageApiLoader3 from 'components/PageApiLoader3';
import FormDebug from 'components/RequestFormDebug';

import RequestFormTabs from 'components/RequestFormTabs';
import {FormDataContext, FormDataContextConsumer, FormDataContextProvider} from 'FormDataContext';
import API from 'libs/api';
import React from 'react';
import {Link} from 'react-router-dom';

function ActionButtons(props) {
    const {handleClose, canDelete, initialValues, dbId}
                                    = props;
    const {state, handleSubmitForm} = React.useContext(FormDataContext);
    const {submitting}              = state;
    const isUpdate                  = initialValues && initialValues[dbId];
    
    return <div>
        <Grid container>
            <Grid item>
                <Button
                    variant="contained" size="small"
                    color={'primary'}
                    onClick={e => handleSubmitForm(e, state.values)}
                    disabled={submitting}
                    startIcon={<SaveIcon/>}
                >
                    {isUpdate
                        ? 'Сохранить'
                        : 'Добавить'}
                </Button>
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    size="small"
                    disabled={submitting}
                    onClick={handleClose}
                    startIcon={<CancelIcon/>}
                >
                    Отмена
                </Button>
            </Grid>
            <Grid item>
                {isUpdate && canDelete &&
                <ConfirmDialog
                    onConfirm={e => handleSubmitForm(e, {...state.values, isDeleted: true})}
                    trigger={<Button
                        color={'secondary'}
                        disabled={submitting}
                    >
                        Удалить
                    </Button>}
                />
                }
            </Grid>
            <Grid item xs={12}>
                <LinearProgress show={submitting}/>
            </Grid>
        </Grid>
    </div>;
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;
    
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const LinkTab = withStyles(() => (
    {
        wrapper: {
            alignItems:     'start',
            flexDirection:  'row',
            justifyContent: 'left',
        },
    }
))(props => {
    const {value, path, label, icon: Icon, ...other} = props;
    return (
        <Tab
            component={Link}
            to={path + '/' + value}
            {...other}
            label={<React.Fragment>{Icon && <Icon/>}&nbsp;{label}</React.Fragment>}
        />
    );
});

const classes = makeStyles((theme) => (
    {
        root: {
            flexGrow:        1,
            backgroundColor: theme.palette.background.paper,
            display:         'flex',
            // height:          224,
        },
        tabs: {
            borderRight: `1px solid ${theme.palette.divider}`,
        },
    }
));

const defaultProps = {
    onFormDataAction:    values => values,
    canDeleteItem:       values => values,
    ActionButtonsTop:    ActionButtons,
    ActionButtonsBottom: () => null,
    actionAddTitle:      'Добавить',
    actionUpdateTitle:   'Сохранить',
};

export default function FormEditCreateTabs(props) {
    if (window.tracePath) {
        console.log('tracePath: ', props.tracePath.join('/'));
    }
    const {
              dbId,
              cols,
              colsInTab,
              open,
              onSuccess,
              handleSubmit,
              initialValues,
              itemName,
              topColsBlocks,
              subFormHandleChange,
              subItems,
              onFormDataAction,
              subApi,
              path,
              actionAddTitle,
              actionUpdateTitle,
              helpPrefix,
              ActionButtonsTop,
              ActionButtonsBottom,
          } = {...defaultProps, ...props};
    
    const [tab, setTab] = React.useState();
    const isUpdate      = initialValues && initialValues[dbId];
    
    const handleChangeTab = (event, tab) => {
        setTab(tab);
    };
    
    const Form = subFormHandleChange
        ? 'div'
        : 'form';
    
    const xs2 = subApi
        ? 10
        : 12;
    
    return (
        <FormDataContextProvider
            initial={initialValues}
            onFormDataAction={onFormDataAction}
            handleSubmit={handleSubmit}
            cols={cols}
            dbId={dbId}
            onSuccess={onSuccess}
        >
            <AdminShow>[FormEditCreateTabs]</AdminShow>
            <div>
                <Form>
                    <div elevation={1}>
                        <div className={classes.root}>
                            <Grid container>
                                
                                <Container maxWidth={false}>
                                    {!subFormHandleChange &&
                                    <ActionButtonsTop {...defaultProps}
                                                      {...props}
                                        // submitting={submitting}
                                        // handleDelete={handleDelete}
                                        // values={values}
                                                      isUpdate={isUpdate}
                                    />}
                                    {helpPrefix}
                                    <Typography variant={'h6'}>{isUpdate
                                        ? actionUpdateTitle
                                        : actionAddTitle} {itemName}
                                    </Typography>
                                </Container>
                                {subApi && <Grid item xs={2}>
                                    <Tabs
                                        orientation="vertical"
                                        variant="scrollable"
                                        value={tab || 'edit'}
                                        onChange={handleChangeTab}
                                        className={classes.tabs}
                                    >
                                        <LinkTab value="edit" label="Основное" path={path} icon={InfoIcon}/>
                                        {initialValues.guid && subApi && subApi.map(
                                            ({item}, key) => <LinkTab key={key} value={item.apiName} path={path}
                                                                      label={item.listTitle}
                                                                      icon={item.menu && item.menu.icon}
                                            />,
                                        )}
                                    </Tabs>
                                </Grid>}
                                <Grid item xs={xs2}>
                                    <TabPanel value={tab || 'edit'} index={'edit'}>
                                        {!colsInTab && <AddBreadCrumb label={'Редактировать'}/>}
                                        <RequestFormTabs
                                            {...props}
                                            open={open}
                                            // values={values}
                                            // handleChange={handleChange}
                                            // setState={setState}
                                            // handleSuggest={handleSuggest}
                                            // errors={errors}
                                            // submitting={submitting}
                                            topColsBlocks={topColsBlocks}
                                            cols={cols}
                                            path={path + '/edit'}
                                            subItems={subItems}
                                            debug={true}
                                            tracePath={[...props.tracePath, 'RequestFormTabs']}
                                        />
                                    </TabPanel>
                                    {subApi && subApi.map(
                                        ({item}, key) => <TabPanel key={key} value={tab}
                                                                   index={item.apiName}>
                                            {/*TODO replace PageApiLoader3*/}
                                            <AddBreadCrumb label={item.listTitle}/>
                                            <PageApiLoader3
                                                canEdit={true}
                                                canAdd={true}
                                                {...item}
                                                filter={{legal: initialValues.guid}}
                                                parent={{
                                                    [API.fieldCreateName]: [],
                                                    // legal: `/api/${apiName}/${values.guid}`,
                                                    // TODO: extend legal to parent?
                                                    legal:                 initialValues.guid,
                                                }}
                                                path={path + '/' + item.apiName}
                                                fullScreen={true}
                                                tracePath={[...props.tracePath, 'PageApiLoader3']}
                                            />
                                        </TabPanel>,
                                    )}
                                    <FormDataContextConsumer>{context => {
                                        const {submitError} = context.state;
                                        return submitError && <Alert severity="error">Ошибка: {submitError}</Alert>;
                                    }}</FormDataContextConsumer>
                                </Grid>
                                <Container maxWidth={false}>
                                    <ActionButtonsBottom {...defaultProps}
                                                         {...props}
                                        // submitting={submitting}
                                        // handleDelete={handleDelete}
                                        // values={values}
                                                         isUpdate={isUpdate}/>
                                </Container>
                            </Grid>
                        </div>
                    </div>
                
                
                </Form>
            </div>
            <FormDataContextConsumer>{context => <FormDebug
                values={context.state.values}
                errors={context.state.errors}/>
            }</FormDataContextConsumer>
        </FormDataContextProvider>
    );
}
