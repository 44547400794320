import ConfirmDialog from 'components/ConfirmDialog';
import DialogEditCreate from 'components/DialogEditCreate3';
import FormEditCreate from 'components/FormEditCreate';
import {CancelIcon, SaveIcon} from 'components/icons';
import RequestForm from 'components/RequestForm';
import {config} from 'config';
import {FieldFormat} from 'config/FieldFormat';
import issueMaterials from 'config/issueMaterials';
import {FormDataContext, FormDataContextProvider} from 'FormDataContext';
import API from 'libs/api';
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';

const defaultProps = {
    title:    'Закрытие работ по заявке',
    text:     'Подтвердите выполнение действия',
    confirm:  'Закрыть заявку',
    cancel:   'Отмена',
    onCancel: () => {},
    onReload: () => {},
};

function ActionButtonsBottom(props) {
    const {handleClose, canDelete, initialValues, handleSubmit}
                                    = props;
    const {state, handleSubmitForm} = React.useContext(FormDataContext);
    const {submitting}              = state;
    
    return <Grid container>
        <Grid item>
            <Button
                variant="contained" size="small"
                color={'primary'}
                onClick={e => handleSubmitForm(e, state.values)}
                disabled={submitting}
                startIcon={<SaveIcon/>}
            >
                Закрыть заявку
            </Button>
        </Grid>
        <Grid item>
            <Button
                variant="contained"
                size="small"
                disabled={submitting}
                onClick={handleClose}
                startIcon={<CancelIcon/>}
            >
                Отмена
            </Button>
        </Grid>
    </Grid>;
}

const api = new API();

function ActionWorkClose(props) {
    console.log('ActionWorkClose',{props})
    const {children, onCancel, onReload, show, item} = {...defaultProps, ...props};
    
    const [open, setOpen] = React.useState(false);
    
    const handleClickOpen = (event) => {
        event.preventDefault();
        setOpen(true);
    };
    
    const handleClose = (e) => {
        console.log('handleClose');
        setOpen(false);
        onCancel(e);
    };
    
    const handleSubmit  = (values, action) => {
        return api[action]('issue_close', values);
    };
    
    const onSuccess = async (e) => {
        console.log('handleSubmit');
        setOpen(false);
        onReload();
    };
    
    return <React.Fragment>
        {!show && <span onClick={handleClickOpen}>{children}</span>}
        
        <DialogEditCreate
            dbId={'guid'}
            title={'Закрыть заявку'}
            actionAddTitle={''}
            open={open || show}
            // returnOnly={true}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            onSuccess={onSuccess}
            // subFormHandleChange={console.log}
            ActionButtonsTop={() => null}
            ActionButtonsBottom={ActionButtonsBottom}
            initialValues={{
                issue:   item.id,
                overtime: item.overtime,
            }}
            cols={[
                {
                    title: 'Расходные материалы',
                    field: 'issueMaterials',
                    format: FieldFormat.FORMAT_SUB_TABLE,
                    api:          issueMaterials.apiName,
                    // todo: not use apiFilter for new objects
                    apiFilter:    (item) => item && item.issue
                        ? {
                            issue: item.issue,
                        }
                        : {
                            issue: 'new',
                            none:        console.trace('no item', item),
                        }
                    ,
                    multi:        true,
                    subConfig:    'issueMaterialsDone',
                    cols:  1,
                },
                {
                    title: 'Комментарий',
                    field: 'comment',
                    cols:  1,
                },
                // {
                //     title:  'Работы выполнены сверхурочно',
                //     field:  'overtime',
                //     cols:   3,
                //     format: FieldFormat.FORMAT_CHECKBOX,
                // },
            ]}
            FormComponent={RequestForm}
            tracePath={['FormEditCreate']}
        />
    </React.Fragment>;
}

ActionWorkClose.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    
    title:    PropTypes.string,
    text:     PropTypes.string,
    confirm:  PropTypes.string,
    cancel:   PropTypes.string,
    onCancel: PropTypes.func,
    trigger:  PropTypes.node,
};

const ActionWorkCloseDialog = props => {
    const {values, title, onClose, submitting} = props;
    return <ActionWorkClose {...props}>
        <Button
            color={'secondary'}
            disabled={submitting}
            variant={'outlined'}
        >
            {title}
        </Button>
    </ActionWorkClose>;
};

export default ActionWorkCloseDialog;