import {TransportIcon} from 'components/icons';
import {ROLES} from 'config/roles';
import PageTransports from 'page/PageTransports';

export default {
    listTitle: 'Транспорт',
    apiName:   'transports',
    itemName:  'транспорт',
    canDelete: true,
    roles:     [
        ROLES.ROLE_USER,
    ],
    menu:      {
        title:     'Транспорт',
        path:      '/transports',
        Component: PageTransports,
        roles:     [ROLES.ROLE_USER],
        icon:      TransportIcon,
    },
    cols:      [
        {
            title:        'Наименование',
            field:        'name',
        },
        {
            title:        'Комментарий',
            field:        'description',
        },
    ],
};
