import {ServiceIcon} from 'components/icons';
import {ROLES} from 'config/roles';
import PageIssueComment from 'page/PageIssueComment';
import {FieldFormat} from './FieldFormat';
import {ShowApiName, ShowDateTimeFromNow, ShowUser} from 'components/showItems';
import asGUID from 'libs/asGUID';
import asId from 'libs/asId';
import {isAdmin, isAuthor, isExecutor, isResponsible, STATUS} from 'config/issues';



export default {
    listTitle:     'Коментарий',
    apiName:       'issue_comments',
    itemName:      'коментарий',
    canAdd:        (props) => {
        const {item, parent, me} = props;
        console.log('canEdit', props,{parent, me, isAdmin: isAdmin(me), isResponsible: isResponsible(me, parent), isExecutor: isExecutor(me, parent)})
        /** Кнопка “Добавить комментарий”
           | Текущий Статус   |  Автор | Руководитель | Исполнитель | Админ |
         1 | Новая            | +      | +            | +           | +     |
         2 | В работе         | +      | +            | +           | +     |
         3 | Работы выполнены | +      | +            | -           | +     |
         4 | Закрыта          | -      | -            | -           | +     |
         */
        switch (asId(parent.status)) {
            case STATUS.NEW:
//                return isAuthor(me,parent) || isResponsible(me,parent) || isAdmin(me);
                return true;
            case STATUS.IN_WORK:
                return true;
            case STATUS.DONE:
                // Убрать у исполнителя возможность добавление материалов и комментариев к заявке
                // в статусе “Работы выполнены”.
                return isAuthor(me,parent) || isResponsible(me,parent) || isAdmin(me);
            case STATUS.CLOSED:
                // Убрать возможность редактирования текста Заявки, после её создания, у всех, кроме администратора.
                return isAdmin(me);
            default:
                return false;
        }
    },
    canDelete:     true,
    roles:         [
        ROLES.ROLE_USER,
    ],
    menu:          {
        title:     'Коментарии',
        path:      '/issue_comments',
        Component: PageIssueComment,
        roles:     [ROLES.ROLE_USER],
        icon:      ServiceIcon,
    },
    initialValues: (props, {me}) => ({
        author: me && me.guid,
    }),
    cols:          [
        {
            title:  'Заявка',
            field:  'issue',
            format: FieldFormat.FORMAT_NONE,
            api:    'issues',
        },
        {
            title:  'Автор',
            field:  'author',
            format: FieldFormat.FORMAT_NONE,
            api:    'users',
            object: ShowUser,

        },
        {
            title:  'Дата создания',
            field:  'createdAt',
            format: FieldFormat.FORMAT_NONE,
            object: ShowDateTimeFromNow,
        },
        {
            title:  'Текст',
            field:  'text',
            format: FieldFormat.FORMAT_TEXTAREA,
        },
        {
            field:      'picture_data',
            title:      'Фото',
            helperText: 'Файл для вложения',
            hidden:     true,
            format: FieldFormat.FORMAT_PHOTO_ADD,

        },
    ],
};
