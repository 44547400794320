import ActionConfirmDialog from 'components/ActionConfirmDialog';
import ErrorBoundary from 'components/ErrorBoundary';
import API from 'libs/api';
import React from 'react';
import {ApiDataLoad, ApiObjectLoadForMTable} from 'components/showItems';
import {
    Grid,
    Paper,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from '@material-ui/core';
import {IconButton} from '@material-ui/core';
import ErrorMessage from 'components/ErrorMessage';
import {AddIcon, DeleteIcon, EditIcon} from 'components/icons';
import {useHistory, useParams} from 'react-router-dom';
import FormRequestSubApiLoader from './FormRequestSubApiLoader';
import FormEditCreateTabs from './FormEditCreateTabs';
import FormEditCreate from './FormEditCreate';
import RequestForm from './RequestForm';
import explainCols from 'components/explainCols';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import DialogEditCreate from './DialogEditCreate3';
import AdminShow from './FieldFormat/AdminShow';
import {useSelector} from 'react-redux';
import ConfirmDialog from './ConfirmDialog';
import {FormDataContext} from '../FormDataContext';
import request from 'superagent';
import * as PropTypes from 'prop-types';
import IfUserCan from 'components/IfUserCan';
import {detectRenderField} from '../config/issues';

const FormEditCreateWrapped = explainCols(DialogEditCreate);

const SubList = (props) => {
    const {canEdit, sub, onReload, item, me} = props;
    const {initialValues}       = {
        initialValues: () => {
        },
        ...sub.item,
    };


    const [openDialog, setOpenDialog]             = React.useState(false);
    const [initialValuesSub, setInitialValuesSub] = React.useState(false);

    let itemProps = {
        AfterComponent: sub.LoadedData || defaultLoadedData,
        apiFilter:      sub.apiFilter || (
            (item) => (
                {
                    issue: item.id,
                }
            )
        ),
        item:           sub.item,
        parentField:    sub.parentField,
    };

    const handleOpenDialog = () => {
        setInitialValuesSub({});
        setOpenDialog(true);
    };

    const editItem = item => () => {
        setInitialValuesSub(item);
        setOpenDialog(true);
    };

    const [deleteRow, setDeleteRow] = React.useState(null);

    // todo: deleteItem
    const deleteItem = item => () => {
        setDeleteRow(item);
    };

    const onDelete = row => {
        return request
            .put(`/api/multi/${sub.item.apiName}/${row.guid || row.id}`)
            .set('accept', 'application/json')
            .send({...row, isDeleted: true});
    };


    // const [isAction, setIsAction]   = React.useState(false);

    const actionDeleteRow = () => {
        setDeleteRow(null);
        onDelete(deleteRow).then(() => {
            onReload();
        }).catch(error => {
            console.log('onDelete error', error);
        });
    };


    const handleCloseDialog = (value) => {
        setOpenDialog(false);
        // setSelectedValue(value);
    };

    const onSuccess = (value) => {
        setOpenDialog(false);
        onReload();
    };
    // const me        = useSelector(s => s.system && s.system.me && s.system.me.items)|| {};

    return <Grid item xs={sub.xs || 12}>
        <AdminShow>[SubList:N]</AdminShow>
        <Typography variant={'h5'}>{sub.title}</Typography>
        <FormRequestSubApiLoader me={me} itemProps={itemProps} item={item} editItem={editItem} deleteItem={deleteItem}/>
        {/*<ApiDataLoader apiName={'issue_comments'} filter={filter} AfterComponent={ShowSimplePage} />*/}
        {/*<ErrorMessage extended={true}>*/}
        <ConfirmDialog
            show={Boolean(deleteRow)}
            onConfirm={() => actionDeleteRow()}
            onCancel={() => setDeleteRow(null)}
        />
        <ErrorBoundary>
            {/*<IfUserCan action={canEdit} item={item}>*/}
            <IfUserCan action={sub.item.canAdd} item={sub.item} parent={item}>
                <Button
                    variant="contained"
                    size={'small'}
                    color="primary"
                    startIcon={<AddIcon/>}
                    onClick={handleOpenDialog}
                >
                    Добавить
                </Button>
            </IfUserCan>
            {/*</IfUserCan>*/}
            <FormEditCreateWrapped
                dbId={'guid'}
                open={openDialog}
                handleClose={handleCloseDialog}
                title={''}
                // itemName={'itemName'}
                // returnOnly={true}
                handleSubmit={handleSubmit(sub.item.apiName)}
                // subFormHandleChange={subFormHandleChange}
                onSuccess={onSuccess}
                {...sub.item}
                initialValues={{...initialValues(props.parent, {me}), ...initialValuesSub, [sub.parentField]: item.id}}
                itemProps={{parentField: sub.parentField}}
                FormComponent={RequestForm}
                tracePath={['SubList', 'FormEditCreate']}
            />
        </ErrorBoundary>
        {/*</ErrorMessage>*/}
    </Grid>;
};

const defaultProps = {
    rows:        [],
    item:        {},
    subApi:      [],
    cardActions: () => [],
};

const api          = new API();
const handleSubmit = apiName => (values, action) => {
    return api[action](apiName, values);
};

const defaultLoadedData = props => {
    const {values, cols, colsLines, editItem, deleteItem, me} = props;
    const linesCols                                                           = colsLines
        ? colsLines
            .map(lineNum => cols
                .filter(col => col.line === lineNum && !col.hidden),
            )
        : [cols.filter(col => !col.hidden)];
    
    const canEdit = 'function' === typeof props.canEdit
        ? props.canEdit({parent: props.item, me, item: props.item})
        : props.canEdit;

    const canDelete = 'function' === typeof props.canDelete
        ? props.canDelete({parent: props.item, me, item: props.item})
        : props.canDelete;

    const actions = canEdit || canDelete;

    return <TableContainer component={Paper}>
        {/*<pre>{JSON.stringify(props,null,2)}</pre>*/}
        <AdminShow>[defaultLoadedData]</AdminShow>
        <Table size="small" aria-label="a dense table">
            <TableHead>
                {linesCols.map(cols =>
                    <TableRow>
                        {actions && <TableCell size={'small'}>Действия</TableCell>}
                        {cols.map(col => <TableCell colSpan={col.colSpan}>{col.title}</TableCell>)}
                    </TableRow>,
                )}
            </TableHead>
            <TableBody>
                {values.map((i, key) => <React.Fragment key={key}>
                    {linesCols.map(cols =>
                        <TableRow>
                            {actions && <TableCell>
                                {canEdit && <IconButton
                                    size={'small'}
                                    title={'Редактировать'}
                                    onClick={editItem(i)}
                                ><EditIcon/></IconButton>}
                                {canDelete && <IconButton
                                    size={'small'}
                                    title={'Удалить'}
                                    onClick={deleteItem(i)}
                                ><DeleteIcon/></IconButton>}
                            </TableCell>}
                            {cols.map(col =>
                                <TableCell colSpan={col.colSpan}>{detectRenderField(cols, col.field, i)}</TableCell>,
                            )}
                        </TableRow>,
                    )}
                </React.Fragment>)}
            </TableBody>
        </Table>
    </TableContainer>;

};

function ShowSimplePage(props) {
    const {canEdit, listTitle, cardActions, apiName, cols, subApi, item, rows, url, onReload} = {...defaultProps, ...props};

    const history                     = useHistory();
    const [submitting, setSubmitting] = React.useState();

    const me = useSelector(s => s.system && s.system.me && s.system.me.items)|| {};

    const onEdit  = row => () => {
        // or backLink?
        history.push(
            row
                ? `${url}/${row.guid || row.id}/edit`
                // :`/legals/${filter.parent_guid}/${apiName}/new`
                : `${url}/new`,
        );
    };
    const onClose = values => () => {
        const action = 'put';
        setSubmitting(true);
        console.log({values});
        return api[action](apiName, values)
            .then(data => {
                console.log({data});
                setSubmitting(false);
                onReload();
                // history.push(url);
            });
    };
    // const filter = {''}

    
    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <AdminShow>[ShowSimplePage]</AdminShow>
            <Grid container spacing={2}>
                <Grid item>
                    <Typography variant={'h5'}>{listTitle}</Typography>
                </Grid>
                {cardActions({item, me})
                    .filter(v => v)
                    .map((props) => {
                        const {Component} = {Component: ActionConfirmDialog, ...props};
                        return <Grid item>
                            <Component submitting={submitting} onReload={onReload} onClose={onClose} item={item} {...props} />
                        </Grid>
                    })}
            </Grid>
        </Grid>
        <Grid item sm={6} xs={12}>
            <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Параметр</TableCell>
                            <TableCell align="right">
                                <IfUserCan action={canEdit} item={item}>
                                    <IconButton aria-label="edit" onClick={onEdit(item)}>
                                        <EditIcon/>
                                    </IconButton>
                                </IfUserCan>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {cols.filter(i => !i.hiddenInCard).map(col => (
                            <TableRow>
                                <TableCell>{col.title}</TableCell>
                                <TableCell>{detectRenderField(cols, col.field, item)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
        <AdminShow>[subApi]</AdminShow>
        {subApi.map(sub => <SubList {...props} sub={sub} onReload={onReload} item={item} me={me}/>)}

    </Grid>;
}

export default ShowSimplePage;