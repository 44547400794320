import ConfirmDialog from 'components/ConfirmDialog';
import AdminShow from 'components/FieldFormat/AdminShow';
import {
    DeleteIcon,
    EditIcon,
} from 'components/icons';
import IfUserCan, {IfUserCanFunc} from 'components/IfUserCan';
import {ApiObjectLoadForMTable} from 'components/showItems';
import detectEditComponent from 'components/FieldFormat/detectEditComponent';
import {config} from 'config';
import {detectRender} from 'libs/detectRender';
import React from 'react';
import MaterialTable from 'material-table';
import {Chip, Avatar} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';

export const transformCols = (cols, {onEdit, TitleIcon, currentValues}) => cols
    .filter(i => i.hidden !== true)
    .map((col, num) => {
        const render = detectRender({col, num, onEdit, TitleIcon});
        
        return {
            ...col,
            // title:  col.title,
            // field:  col.field,
            type:          col.type && col.type === 'float'
                               ? 'numeric'
                               : col.type,
            hidden:        col.hidden === true,
            editComponent: detectEditComponent(col, num),
            render: render,
            sorting: true,
            defaultSort: currentValues && currentValues.orderField === col.field
                             ? currentValues.direct
                             : null,
        }
    })
    .filter(i => !i.hidden)
    .filter(i => 'string' === typeof i.title);


const queryString = require('query-string');

export default function TableMaterialTableShow(props) {
    const {
              listTitle,
              list,
              onShow,
              onEdit,
              canEdit,
              canDelete,
              detail,
              menu,
              onDelete,
              loadData,
              url,
          } = props;

    const {icon: TitleIcon} = {icon: null, ...menu};
    /*    const detectHidden = col => {
            if (!col.hidden) {
                return undefined;
            }
            let _ = col.hidden;
            // let hid =
            // Hidden({...col.hidden,initialWidth:width,children:'children',implementation:'js'});
            // console.log('Hidden',Object.keys(hid));
            switch (width) {
                case 'xl':
                    return _.xl
                        || _.xsUp || _.smUp || _.mdUp || _.lgUp || _.xlUp
                        || _.xlDown;
                case 'lg':
                    return _.lg
                        || _.xsUp || _.smUp || _.mdUp || _.lgUp
                        || _.xlDown || _.lgDown;
                case 'md':
                    return _.md
                        || _.xsUp || _.smUp || _.mdUp
                        || _.xlDown || _.lgDown || _.mdDown;
                case 'sm':
                    return _.sm
                        || _.xsUp || _.smUp
                        || _.xlDown || _.lgDown || _.mdDown || _.smDown;
                
                case 'xs':
                    return _.xs
                        || _.xsUp
                        || _.xlDown || _.lgDown || _.mdDown || _.smDown || _.xsDown;
            }
            return true;
        };
        */

    const detectRender = (col, num) => rowData => {
        const handleClick = num === 0
            ? () => onShow(rowData)()
            : null;
        if (col.api && col.object) {
            return <div onClick={handleClick}>
                {ApiObjectLoadForMTable(col.api, col.object, {
                    item:   rowData[col.field],
                    row:    rowData,
                    column: col.field,
                    // multiple: col.multiple, todo: multiple support
                })}
            </div>;
        }
        else if (col.object) {
            return <div onClick={handleClick}>
                <col.object
                    item={rowData[col.field]}
                    row={rowData}
                    column={col.field}
                />
            </div>;

        }
        else {
            let value = rowData[col.field];
            if ('object' === typeof value) {
                return JSON.stringify(value);
            }

            return num === 0
                ? <Chip variant={'outline'} size="small" avatar={<TitleIcon/>} label={rowData[col.field]} onClick={handleClick}/>
                : <div>{rowData[col.field]}</div>;
        }
    };

    const parsed = queryString.parse(window.location.search);
    const pageSize = config.MaterialTable.options.pageSize;
    let order    = {};
    let page     = 0;
    let perPage  = pageSize;
    let searchText  = '';
    console.log('historyPush', {parsed});
    Object.keys(parsed).map(field => {
        let re = field.match(/order\[(\w+)\]/);
        if (re) {
            order[re[1]] = parsed[field];
        }
        page = Number(parsed['page']) || 0;
        perPage = Number(parsed['perPage']) || pageSize;
        searchText = parsed['searchText'] || '';
    });

    const tableRef                      = React.createRef();
    const [selectedRow, setSelectedRow] = React.useState(null);
    
    const [columns, setColumns] = React.useState([]);
    
    const me = useSelector(s => s.system && s.system.me && s.system.me.items) || {};

    // todo: use global setColumns
    React.useMemo(() => {
        let c = 'function' === typeof props.cols
            ? props.cols(props, list.items,{me})
            : props.cols;

        if (c instanceof Promise) {
            c.then(data => setColumns(data));
        }
        else {
            setColumns(props.cols);
        }
    }, [list.items, props]);

    const [deleteRow, setDeleteRow] = React.useState(null);
    const [isAction, setIsAction]   = React.useState(false);
    const actionDeleteRow           = () => {
        setIsAction(true);
        setDeleteRow(null);
        onDelete({...deleteRow, tableData: undefined}).then(() => {
            loadData();
            setIsAction(false);
        }).catch(error => {
            console.log('onDelete error', error);
            setIsAction(false);
        });
    };

    const history = useHistory();

    const onDeleteDialog = rowData => () => {
        setDeleteRow(rowData);
    };

    const Title = props => {
        const {label, icon: Icon} = props;
        return (
            <div style={{
                alignItems:     'center',
                flexDirection:  'row',
                justifyContent: 'left',
                display:        'flex',
                fontSize:       '24px',
            }}>
                {Icon && <Icon/>}&nbsp;{label}
            </div>
        );
    };
    const oField = Object.keys(order).pop();
    const currentValues = {
        orderField: oField,
        direct:     order[oField],
        page,
        perPage,
        searchText,
    };

    const historyPush = (values) => {
        console.log('historyPush call1',{...currentValues, values})
        const {page, perPage, orderField, direct, searchText} = {
            ...currentValues,
            ...values,
        };
        const field                               = 'order'.concat('[', orderField, ']');
        let search = '?'.concat(
            'page=', page,
            '&perPage=', perPage,
            '&searchText=', searchText,

        );
        if (orderField) {
            search = search.concat(
                '&', field, '=', direct,
            );
        }
        console.log('historyPush call2 history.push',{search,page, perPage, orderField, direct, values})
        history.push({
            pathname: url,
            search,
        });
    };

    const showColumns         = transformCols(columns,{currentValues});
    const onOrderChange       = (num, direct) => {
        const col = showColumns[num];
        console.log('historyPush onOrderChange', {col, num, direct});
        if (!col) {
            return historyPush({orderField: null, direct});
        }
        historyPush({orderField: col.field, direct});
    };
    const onChangeRowsPerPage = (perPage) => {
        console.log('historyPush onChangeRowsPerPage',{perPage})
        historyPush({perPage});
    };

    const onChangePage = (page) => {
        console.log('historyPush onChangePage',{page, data: list})
        historyPush({page});
    };

    const onSearchChange = (searchText) => {
        console.log('historyPush onSearchChange',{searchText, data: list})
        historyPush({searchText});
    }
    
    return <React.Fragment>

        <ConfirmDialog
            show={Boolean(deleteRow)}
            onConfirm={() => actionDeleteRow()}
            onCancel={() => setDeleteRow(null)}
        />
        <AdminShow>[TableMaterialTableShow]</AdminShow>
        {!list.isFetching && <MaterialTable
            isLoading={isAction}
            title={<Title icon={TitleIcon} label={listTitle}/>}
            tableRef={tableRef}
            columns={showColumns}
            onOrderChange={onOrderChange}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onChangePage={onChangePage}
            onSearchChange={onSearchChange}

            data={(
                list && list.items
            ) || []}
            // data={query =>
            //     new Promise((resolve, reject) => {
            //         resolve({data: list && list.items || []});
            //     })
            // }
            actions={[
                // {
                //     icon:         'refresh',
                //     tooltip:      'Обновить данные',
                //     isFreeAction: true,
                //     onClick:      () => tableRef.current.onQueryChange(),
                // },
                rowData => IfUserCanFunc({action:canEdit,item:rowData,me,children:{
                    icon:    EditIcon,
                    tooltip: 'Редактировать',
                    onClick: (event, rowData) => onEdit(rowData)(),
                }}),
                rowData => IfUserCanFunc({action:canDelete,item:rowData,me,children:{
                    icon:    DeleteIcon,
                    tooltip: 'Удалить',
                    onClick: (event, rowData) => onDeleteDialog(rowData)(),
                }}),
            ]}
            {...config.MaterialTable}
            onRowClick={(
                // (evt, selectedRow) => setSelectedRow(selectedRow.tableData.id)
                (evt, rowData) => onShow(rowData)()
            )}
            options={{
                ...config.MaterialTable.options,
                initialPage: page,
                pageSize:    perPage,
                searchText,
                rowStyle:    rowData => (
                    {
                        backgroundColor: selectedRow === rowData.tableData.id
                                             ? '#DDD'
                                             : undefined,
                    }
                ),
            }}
            detailPanel={detail}
        />}
    </React.Fragment>;
}
