import {ServiceIcon} from 'components/icons';
import {ROLES} from 'config/roles';
import PageService from 'page/PageService';
import PageIssues from 'page/PageIssues';
import PageWorkObjectStatus from '../page/PageWorkObjectStatus';
import {FieldFormat} from './FieldFormat';

export default {
    listTitle: 'Статус объекта',
    apiName:   'work_object_statuses',
    itemName:  'статус объекта',
    canDelete: true,
    roles:     [
        ROLES.ROLE_USER,
    ],
    menu:      {
        title:     'Статусы объекта',
        path:      '/work_object_statuses',
        Component: PageWorkObjectStatus,
        roles:     [ROLES.ROLE_USER],
        icon:      ServiceIcon,
    },
    cols:      [
        {
            title: 'Наименование',
            field: 'name',
        },
        {
            title: 'Комментарий',
            field: 'description',
            format:  FieldFormat.FORMAT_TEXTAREA,
        },
    ],
};
