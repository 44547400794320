import {faCogs, faCoins, faFolder, faPencilAlt, faSync, faUniversity, faUser} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Icon} from '@material-ui/core';

import {IconProps} from '@material-ui/core/Icon';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import React from 'react';

export {
    MoreVert as MoreIcon,
    // Euro as CurrencyIcon,
    // AccountBalance as BankIcon,
    // AccountBalance as BankAccountIcon,
    // Business as OrganizationsIcon,
    // Sync as SyncIcon,
    // People as IndividualIcon,
    AccountBox as PageClientsIcon,
    // Edit as EditIcon,
    Add as AddIcon,
    ListAlt as ListIcon,
    Menu as MenuIcon,
    Done as SaveIcon,
    DriveEtaOutlined as DriverIcon,
    PersonOutline as MemberIcon,
    Commute as TransportIcon,
    Clear as CancelIcon,
    Clear as DeleteIcon,
    BusinessCenterOutlined as OrganizationsIcon,
    ReportProblemOutlined as NotificationsIcon,
    StorageOutlined as CargoTypesIcon,
    AppsRounded as MeasureIcon,
    MapOutlined as RoutePointsIcon,
    AddToQueueOutlined as ServiceIcon,
    AccountBalanceWalletOutlined as DeliveryRequestIcon,
    AccountBalanceWalletRounded as DeliveryIcon,
    ShopOutlined as BiddingIcon,
    Home as AddressIcon,
    Transform as RoadIcon,
    Work as WorkObjectIcon,
    Group as SubUnitIcon,
    Power as PowerSupplyIcon,
    AssignmentOutlined as ContractIcon,
    BusinessOutlined as MenuGroupIcon1,
    LocalShippingOutlined as MenuGroupIcon2,
    DescriptionOutlined as MenuGroupIcon3,
    AccountBalanceWalletOutlined as BankAccountIcon, // Банковские счета
    ContactPhoneOutlined as ContactInfoIcon,  // Контактная Информация (в юр.лицах) :  ContactPhoneOutlined или ContactMailOutlined
    PeopleAltOutlined as ContactPeopleIcon, // Контактные Лица (в юр.лицах) :
    InfoOutlined as InfoIcon, // Основное (в юр.лицах и документах) :
} from '@material-ui/icons';

const faUseStyles = makeStyles((theme: Theme) =>
    createStyles({
        root:          {
            paddingRight: theme.spacing(1),
            height:       'auto',
            width:        'auto',
            // fontSize: theme.typography.pxToRem(24 - 3 * 2),
            fontSize:     '1rem',
        },
        icon:          {
            marginLeft: theme.spacing(3),
        },
        fontSizeSmall: {
            fontSize: theme.typography.pxToRem(20 - 3 * 2),
        },
        fontSizeLarge: {
            fontSize: theme.typography.pxToRem(36 - 3 * 2),
        },
    }),
);

function FaIcon(props: IconProps) {
    const classes = faUseStyles();
    return <Icon classes={classes} style={{fontSize: 16, marginLeft: 10}} {...props} />;
}

export function OrganizationsIconFA({className, fontSize, ...props}) {
    // return         <Chip
    //     size="small"
    //     icon={<Icon className="fas fa-phone-alt" fontSize="inherit" />}
    //     label="Inherit"
    // />;
    return <FaIcon className={`fas fa-city ${className}`} fontSize={fontSize} {...props}/>;
}

// export function OrganizationsIcon() {
//     return <IconButton><FontAwesomeIcon icon={faCity}
//     /></IconButton>;
// }

export function ConfigIcon() {
    return <FontAwesomeIcon size="lg" icon={faCogs}/>;
}

export function SyncIcon() {
    return <FontAwesomeIcon size="lg" icon={faSync}/>;
}

export function IndividualIcon({className, fontSize, ...props}) {
    return <FaIcon className={`fas fa-user ${className}`} fontSize={fontSize} {...props}/>;
}

export function ClientProfilesIcon() {
    return <FontAwesomeIcon size="lg" icon={faUser}/>;
}

export function EditIcon(props) {
   // return <i className="fas fa-pencil-alt" />;
    return <FontAwesomeIcon icon={faPencilAlt} {...props}/>
}

export function BankIcon(props) {
    return <FontAwesomeIcon size="lg" icon={faUniversity} {...props}/>;
}

// export function BankAccountIcon(props) {
//     return <FontAwesomeIcon size="lg" icon={faWallet} {...props}/>;
// }

export function CurrencyIcon(props) {
    return <FontAwesomeIcon size="lg" icon={faCoins} {...props}/>;
}


export function MenuGroupIcon4() {
    return <FontAwesomeIcon size="lg" icon={faFolder}/>;
}

export function DocRequestIcon() {
    return <FontAwesomeIcon size="lg" icon={faFolder}/>;
}

export function CarbodyTypeIcon() {
    return <FontAwesomeIcon size="lg" icon={faFolder}/>;
}

