import React from 'react';
import {Box} from '@material-ui/core';
import ErrorBoundary from 'components/ErrorBoundary';
import {AddBreadCrumb} from 'components/Menu';
import {SubFormLIstEdit} from 'components/FieldFormat/SubFormLIstEdit';
import {FormDataContext} from 'FormDataContext';

function TabPanel(props) {
    const {children, value, index, ...other} = props;
    
    return (
        <ErrorBoundary>
            <Box
                style={{width: '100%'}}
                role="tabpanel"
                hidden={value !== index}
                id={`scrollable-force-tabpanel-${index}`}
                aria-labelledby={`scrollable-force-tab-${index}`}
                {...other}
            >
                {value === index && children}
            </Box></ErrorBoundary>
    );
}

const ShowTabPanel = (props) => {
    const defaultProps = {
        handleSuggest: () => {},
        colsInTab:     false,
    };
    
    const {tracePath, tabSelected, i, n} = {...defaultProps, ...props};
    
    const {state: {values, errors}, handleChange} = React.useContext(FormDataContext);
    // const {state: {values, errors}, handleChange} = {state: {values: {}, errors: {}}, handleChange: () => {}};
    
    const handleChangeValues = item => (event, newValues) => {
        handleChange(item)(null, newValues);
    };
    
    const Component = i.Component || SubFormLIstEdit;
    return <TabPanel value={tabSelected} index={n} key={n}>
        <ErrorBoundary>
            <AddBreadCrumb label={i.listTitle}/>
            {i.format && 'i.format removed was <FormMultiSelect/>'}
            <Component
                inTab={true}
                formProps={props}
                itemProps={i}
                item={values}
                detail={i.detail}
                itemValues={values}
                handleChange={handleChange}
                onChange={handleChangeValues(i)}
                // apiFilter={apiFilter && apiFilter(values)}
                values={values[i.field] || []}
                errors={errors[i.field] || []}
                cols={i.cols || []}
                tracePath={[...tracePath, 'SubFormLIstEdit']}
            />
        </ErrorBoundary>
    </TabPanel>;
};

export default ShowTabPanel;