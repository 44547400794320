import {ClearOutlined, SearchOutlined} from '@material-ui/icons';
import {AddIcon} from 'components/icons';
import service from 'config/service';
import issues from 'config/issues';
import issueStatus from 'config/issueStatus';
import workObjectStatus from 'config/workObjectStatus';
import workObject from 'config/workObject';
import docRequest from 'config/docRequest';
import issueComment from 'config/issueComment';
import road from 'config/road';
import material from 'config/material';
import workObjectModel from 'config/workObjectModel';
import subUnit from 'config/subUnit';
import transport from 'config/transport';
import vendor from 'config/vendor';
import workObjectType from 'config/workObjectType';
import user from 'config/user';
import userRole from 'config/userRole';
import customer from 'config/customer';
import contract from 'config/contract';
import address from 'config/address';
import powerSupply from 'config/powerSupply';
import issueMaterials from 'config/issueMaterials';
import issueMaterialsDone from 'config/issueMaterialsDone';
import issueExecutors from 'config/issueExecutors';
import roadUnits from 'config/roadUnit';
import workObjectContract from 'config/workObjectContract';
import workObjectDevice from 'config/workObjectDevice';
import tcm from 'config/tcm';


import React from 'react';
import Button from '@material-ui/core/Button';
import DeleteOutline from '@material-ui/icons/DeleteOutline';

export const config = {
    // Format: FieldFormat,
    apiPrefix:     '/api/',
    formDebug:     true,
    service:       service,
    issues,
    issueStatus,
    workObject,
    workObjectStatus,
    issueComment,
    material,
    road,
    subUnit,
    transport,
    vendor,
    workObjectModel,
    workObjectType,
    user,
    userRole,
    customer,
    contract,
    address,
    powerSupply,
    issueMaterials,
    issueMaterialsDone,
    issueExecutors,
    roadUnits,
    workObjectContract,
    workObjectDevice,
    tcm,
    docRequest:    docRequest,
    MaterialTable: {
        options:      {
            toolbarButtonAlignment: 'left',
            padding:                'dense',
            emptyRowsWhenPaging:    false,
            pageSize:               20,
            headerStyle:            {
                backgroundColor: '#EEE',
            },
        },
        icons:        {
            Delete:      React.forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
            Search:      SearchOutlined,
            ResetSearch: ClearOutlined,
            Add:         () => {
                return <Button
                        title={'Добавить'}
                        variant={'contained'}
                        color={'primary'}
                        // onClick={addNew}
                        startIcon={<AddIcon/>}
                >
                    Добавить
                </Button>;
            },
        },
        localization: {
            pagination: {
                labelRowsSelect:    'элементов',
                labelDisplayedRows: '{from}-{to} из {count}',
            },
            toolbar:    {
                searchPlaceholder: 'Поиск...',
                nRowsSelected:     '{0} row(s) selected',
            },
            header:     {
                actions: 'Действия',
            },
            body:       {
                emptyDataSourceMessage: 'Нет данных для отображения',
                filterRow:              {
                    filterTooltip: 'Filter',
                },
                editRow:                {
                    deleteText: 'Вы уверены, что удалите эту строку?',
                },
            },
        },
    },


    get: name => {
        return config[name] || name;
    },
};
