import React from "react";
import {Typography} from '@material-ui/core';


export default  function MainPage() {


    return <React.Fragment>
        <Typography variant="h4" gutterBottom>
        </Typography>
        </React.Fragment>
        ;
}
