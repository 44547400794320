import ErrorBoundary from 'components/ErrorBoundary';
import Checkbox from 'components/FieldFormat/Checkbox';
import Custom from 'components/FieldFormat/Custom';
import FieldFormatDate from 'components/FieldFormat/Date';
import DatetimeLocal from 'components/FieldFormat/DatetimeLocal';
import SelectApi from 'components/FieldFormat/SelectApi';
import SelectApiCheckbox from 'components/FieldFormat/SelectApiCheckbox';
import SelectApiIndex from 'components/FieldFormat/SelectApiIndex';
import SelectDadata from 'components/FieldFormat/SelectDadata';
import SelectOptions from 'components/FieldFormat/SelectOptions';
import SimpleInput from 'components/FieldFormat/SimpleInput';
import SubForm from 'components/FieldFormat/SubForm';
import SubTable from 'components/FieldFormat/SubTable';
import Textarea from 'components/FieldFormat/Textarea';
import {FormDataContextConsumer} from 'FormDataContext';
import React from 'react';
import {
    FormControl, FormGroup, FormLabel,
    Grid,
} from '@material-ui/core';

import ErrorMessage from 'components/ErrorMessage';
import {FieldFormat} from 'config/FieldFormat';
import FormDebug from 'components/RequestFormDebug';
import MaskInput from './FieldFormat/MaskInput';
import NumberFormat from './FieldFormat/NumberFormat';

const eachErrorMessage = ([field, value]) => <ErrorMessage
    title={field + ': ' + value} extended={false}
/>;

export default class RequestForm extends React.Component {
    
    static defaultProps = {
        handleChange: () => console.warn('handleChange not set'),
        values:       {},
        errors:       {},
        debug:        true,
        tracePath: ['none'],
        cols: [],
    };
    
    
    render() {
        // console.trace('tracePath');
        if (window.tracePath) {
            console.log('tracePath: ', this.props.tracePath.join('/'));
        }
        const {
                  handleSuggest,
                  submitting,
                  cols,
                  debug,
                  topColsBlocks,
                  parent,
                  tracePath,
              }
                  = this.props;
    
        const fieldsShow = cols.filter(
            i => i.format !== FieldFormat.FORMAT_NONE).map(item => {
                // const {topColsBlock, disabled, readOnly, field, title, format, options, api, required, apiFilter, ...other} = item;
                switch (item.format) {
                    case FieldFormat.FORMAT_CUSTOM:
                        item.render = <Custom item={item} submitting={submitting}/>;
                        break;
                    default:
                    case FieldFormat.FORMAT_INPUT:
                        item.render = <SimpleInput item={item} submitting={submitting}/>;
                        break;
                    case FieldFormat.FORMAT_INPUT_MASK:
                        item.render = <MaskInput item={item} submitting={submitting}/>;
                        break;
                    case FieldFormat.FORMAT_NUMBER:
                        item.render = <NumberFormat item={item} submitting={submitting}/>;
                        break;
                    case FieldFormat.FORMAT_TEXTAREA:
                        item.render = <Textarea item={item} submitting={submitting}/>;
                        break;
                    case FieldFormat.FORMAT_SELECT:
                        item.render = <SelectOptions item={item} submitting={submitting}/>;
                    break;
                case FieldFormat.FORMAT_SELECT_API_CHECKBOX:
                    item.render = <SelectApiCheckbox item={item} submitting={submitting} tracePath={tracePath}/>;
                    break;
                case FieldFormat.FORMAT_SELECT_API_INDEX:
                    item.render = <SelectApiIndex item={item} submitting={submitting} tracePath={tracePath}/>;
                        break;
                    case FieldFormat.FORMAT_SELECT_API:
                        item.render = <SelectApi item={item} submitting={submitting} tracePath={tracePath} parent={parent}/>;
                        break;
                    case FieldFormat.FORMAT_SUB_FORM:
                        item.render = <SubForm item={item} submitting={submitting} tracePath={tracePath} parent={parent}/>;
                        break;
                    case FieldFormat.FORMAT_SUB_TABLE:
                        item.render = <SubTable item={item} submitting={submitting} tracePath={tracePath} parent={parent}/>;
                        break;
                    case FieldFormat.FORMAT_SELECT_DADATA:
                        item.render = <SelectDadata item={item} submitting={submitting} tracePath={tracePath} parent={parent} handleSuggest={handleSuggest}/>;
                        break;
                    case FieldFormat.FORMAT_DATE:
                        item.render = <FieldFormatDate item={item} submitting={submitting} tracePath={tracePath} parent={parent}/>;
                        break;
                    case FieldFormat.FORMAT_DATETIME_LOCAL:
                        item.render = <DatetimeLocal item={item} submitting={submitting} tracePath={tracePath} parent={parent}/>;
                        break;
                    case FieldFormat.FORMAT_CHECKBOX:
                        item.render = <Checkbox item={item} submitting={submitting} tracePath={tracePath} parent={parent}/>;
                        break;
                }
                return item;
            },
        );
        const fieldsShowMap = (props, key) => {
            const {cols, render} = {cols: 1, ...props};
            
            let sm = 12 / cols;
        
            return <Grid item key={key} sm={sm} xs={12}>
                <ErrorBoundary>{render}</ErrorBoundary>
            </Grid>;
        };
        const topColsBlocksMap = ({topColsBlocks, fieldsShow}) => (block, blockKey) => {
            const {cols, title, num} = {
                num:  blockKey + 1,
                cols: topColsBlocks.length,
                ...block,
            };
            return (
                <Grid key={blockKey} item sm={12 / cols}>
                    <FormControl component="fieldset" style={{width: '100%'}}>
                        <FormLabel component="legend">{title}</FormLabel>
                        <FormGroup>
                            <Grid container spacing={3}>
                                {fieldsShow
                                    .filter(b => b.topColsBlock === num)
                                    .map(fieldsShowMap)}
                            </Grid>
                        </FormGroup></FormControl>
                </Grid>
            );
        };
        return (
            <Grid container spacing={3}>
                {topColsBlocks
                    ? topColsBlocks.map(topColsBlocksMap({fieldsShow, topColsBlocks}))
                    : fieldsShow.map(fieldsShowMap)
                }
                <FormDataContextConsumer>{context => {
                    let errors = context.state && context.state.errors;
                    return errors && Object.entries(errors)
                        .filter(([field]) => !cols.find(i => i.field === field))
                        .slice(0, 10)
                        .map(eachErrorMessage);
                }}</FormDataContextConsumer>
                <FormDataContextConsumer>{context => <FormDebug values={context.state && context.state.values} debug={debug} errors={context.state && context.state.errors}/>}</FormDataContextConsumer>
            </Grid>
        );
    }
}