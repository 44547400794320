import React from 'react';
import request from 'superagent';
import {
    CircularProgress,
    TextField,
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    debounce,
} from 'lodash';

const defaultProps = {
    freeSolo:      true,
    searchField:   'inn',
    method:      'post',
    freeSoloField: 'country',
};

export default function FormApiAutoSuggest(props) {
    const {
              api,
              label,
              value,
              onSuggest,
              placeholder,
              apiFilter,
              required,
              helperText,
              freeSolo,
              searchField,
              method,
              freeSoloField,
          } = {...defaultProps, ...props};
    
    const [open, setOpen]             = React.useState(false);
    const [loading, setLoading]       = React.useState(false);
    const [options, setOptions]       = React.useState([]);
    const [inputValue, setInputValue] = React.useState(value);
    
    const fetch = React.useMemo(
        () =>
            debounce((query, callback) => {
                (
                    async () => {
                        setLoading(true);
                        try {
                            if(method === 'post'){
                                const response = await request.post(`/api/${api}`)
                                    .set('accept', 'application/json')
                                    .send({...query, ...apiFilter});
                                callback(response.body.suggestions.map(suggest => (
                                    {
                                        suggest: suggest,
                                        name:    suggest.value,
                                    }
                                )));
                            }
                            else {
                                const response = await request.get(`/api/${api}`)
                                    .set('accept', 'application/json')
                                    .query({...query, ...apiFilter});
                                callback(response.body);
                            }
                        }
                        catch (e) {
                            callback([{suggest: {}, name: 'Error: ' + e.message}]);
                        }
                        setLoading(false);
                    }
                )();
            }, 400),
        [api, apiFilter,method],
    );
    
    React.useEffect(() => {
        let active = true;
        if (inputValue) {
            console.log('fetch');
            fetch({[searchField]: inputValue}, (results) => {
                if (active) {
                    setOptions(results || []);
                }
            });
        }
        return () => {
            active = false;
        };
    }, [api, inputValue, fetch, searchField]);
    
    const handleChange = event => {
        setInputValue(event.target.value);
    };
    
    const onChangeLocal = (event, value) => {
        console.log('onChangeLocal', value);
        if (!value) {
            // eslint-disable-next-line
            return;
        }
        else if (value.suggest) {
            onSuggest(value && value.suggest);
        }
        else if (value.inputValue) {
            // onChange(null, value.inputValue);
            onSuggest({
                data: {
                    [freeSoloField]: value.inputValue,
                    useCustom:     freeSoloField,
                },
            });
        }
        else {
            onSuggest({data: value});
        }
    };
    /*
    const ValidationTextField = withStyles({
        root: {
            '& input:valid + div + fieldset':       {
                borderLeftWidth: 6,
                borderWidth:     2,
            },
            '& input:valid:focus + div + fieldset': {
                borderLeftWidth: 6,
                padding:         '4px !important', // override inline-style
            },
        },
    })(TextField);
    */
    
    let TextFieldComponent = required
        ? TextField
        : TextField;
    //todo: fix ValidationTextField
    
    return <React.Fragment>
        {/*<pre>{JSON.stringify(apiFilter)}</pre>*/}
        {/*<pre>config.get(subConfig):{JSON.stringify(config.get(subConfig))}</pre>*/}
        {/*{subConfig &&<pre>addNewElement:{JSON.stringify(addNewElement)}</pre>}*/}
        
        <Autocomplete
            freeSolo={Boolean(freeSolo)}
            open={open}
            onOpen={() => {
                setOpen(true);
                setInputValue(value);
                //setLoading(true);
            }}
            onClose={() => {
                setOpen(false);
                setInputValue(null);
            }}
            filterOptions={(options, params) => {
                const filtered = options;
                
                if (freeSolo && params.inputValue !== '') {
                    filtered.push({
                        // id: 'addid',
                        // guid: 'addguid',
                        inputValue: params.inputValue,
                        name:       `Использовать "${params.inputValue}"`,
                    });
                }
                
                return filtered;
            }}
            getOptionSelected={(option, value) => value && (
                option.name === value.name
            )}
            getOptionLabel={option => option.name || option || ''}
            options={!loading
                ? options
                : []}
            loading={loading}
            value={open
                ? undefined
                : {
                    name: value,
                }}
            onChange={onChangeLocal}
            noOptionsText={'Нет вариантов'}
            renderInput={params => (
                <TextFieldComponent
                    {...params}
                    onChange={handleChange}
                    label={label}
                    helperText={helperText || 'Начните вводить для поиска'}
                    fullWidth
                    variant="outlined"
                    // variant={required ? "filled": "outlined"}
                    margin={'dense'}
                    // color={'secondary'}
                    required={Boolean(required)}
                    InputLabelProps={{shrink: true}}
                    placeholder={placeholder}
                    inputProps={{
                        ...params.inputProps,
                        value: open
                                   ? inputValue
                                   : params.inputProps.value,
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                                          <React.Fragment>
                                              {loading
                                                  ? <CircularProgress
                                                      color="inherit"
                                                      size={20}/>
                                                  : null}
                                              {params.InputProps.endAdornment}
                                          </React.Fragment>
                                      ),
                    }}
                />
            )}
        />
    </React.Fragment>;
}
