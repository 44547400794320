import React from 'react';
import {ApiObjectLoadForMTable} from 'components/showItems';
import Chip from '@material-ui/core/Chip';

export const detectRender = ({col, num, onEdit, TitleIcon}) => rowData => {
    if (col.render && typeof col.render === 'function') {
        // disable for field with lookup
        return col.render(rowData);
    }

    if (col.lookup) {
        // disable for field with lookup
        return undefined;
    }
    const handleClick = num === 0 && onEdit
        ? () => onEdit(rowData)()
        : null;
    if (col.api && col.object) {
        return <div onClick={handleClick}>
            {ApiObjectLoadForMTable(col.api, col.object, {
                item:   rowData[col.field],
                row:    rowData,
                column: col.field,
                multiple: col.multiple,
            })}
        </div>;
    } else if (col.object) {
        return <div>
            <col.object
                item={rowData[col.field]}
                row={rowData}
                column={col.field}
                onClick={handleClick}
            />
        </div>;
        
    } else {
        let value = rowData[col.field];
        if ('object' === typeof value) {
            return JSON.stringify(value);
        }
        
        return num === 0
            ? <Chip variant={'outline'}
                    size="small"
                    avatar={TitleIcon && <TitleIcon/>}
                    label={rowData[col.field]}
                    onClick={handleClick}/>
            : <div>{rowData[col.field]}</div>;
    }
};
