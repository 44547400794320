export default function detectCols(props, list) {
    const cols = props.cols
        || Object
            .keys((
                list && list.items && list.items[0]
            ) || {})
            .map(i => (
                {title: i, field: i}
            ))
        || [];
    
    if (!props.cols) {
        console.log('Generated cols:', JSON.stringify(cols, null, 2));
    }
    return cols;
};
