import React from 'react';
import MomentUtils from '@date-io/moment';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {FormDataContext} from 'FormDataContext';

export default function({ item}) {
    const { field, title, required} = item;
    
    const {state: {values, errors}, handleChange} = React.useContext(FormDataContext);
    
    const onChangeDatePicker = handleChange => moment => {
        let value = moment
            ? moment.format('YYYY-MM-DD hh:mm:ss')
            : null;
        handleChange({target: {value}},
        );
    };
    
    return <React.Fragment>
        <MuiPickersUtilsProvider
            locale={'ru'}
            utils={MomentUtils}>
            <KeyboardDatePicker
                autoOk
                fullWidth
                //disableToolbar
                variant="inline"
                clearable="true"
                // disableFuture
                // openTo="year"
                format="DD.MM.YYYY HH:mm:ss"
                label={title}
                margin={'dense'}
                InputLabelProps={{shrink: true}}
                inputVariant={'outlined'}
                views={['year', 'month', 'date']}
                value={values && values[field] || null}
                error={!!errors[field]}
                required={required}
                onChange={onChangeDatePicker(handleChange(item))}
            />
        </MuiPickersUtilsProvider>
    </React.Fragment>;
}