import MomentUtils from '@date-io/moment';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import AdminShow from 'components/FieldFormat/AdminShow';
import {FormDataContext} from 'FormDataContext';
import React from 'react';

function MyComponent({item, value, error, handleChangeValue}) {
    const {title, required}  = item;
    const onChangeDatePicker = moment => {
        let value = (moment && moment.isValid())
            ? moment.format('YYYY-MM-DD')
            : '';
        handleChangeValue(value);
    };

    return <React.Fragment>
        <AdminShow>[Date]</AdminShow>
        <MuiPickersUtilsProvider
            locale={'ru'}
            utils={MomentUtils}>
            <KeyboardDatePicker
                autoOk
                fullWidth
                //disableToolbar
                variant="dialog"
                clearable
                clearText={'Clear'}
                // disableFuture
                // openTo="year"
                format="DD.MM.YYYY"
                label={title}
                margin={'dense'}
                inputVariant={'outlined'}
                views={['year', 'month', 'date']}
                value={value}
                error={!!error}
                required={required}
                onChange={onChangeDatePicker}
                InputLabelProps={{shrink: true}}
            />
        </MuiPickersUtilsProvider>
    </React.Fragment>;
}

function areEqual(prevProps, nextProps) {
    return prevProps.value === nextProps.value
        && prevProps.error === nextProps.error;
}

const MyComponentMemo = React.memo(MyComponent, areEqual);

function MyComponentMemoUse(props) {
    const {field}                                 = props.item;
    const {state: {values, errors}, handleChange} = React.useContext(FormDataContext);

    const handleChangeValue = (value) => {
        return handleChange(props.item)(null, value);
    };

    const value = values && values[field];
    const error = values && errors && errors[field];

    return <MyComponentMemo
        value={value || null}
        error={error || null}
        handleChangeValue={handleChangeValue}
        {...props}
    />;
}

export default MyComponentMemoUse;