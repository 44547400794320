import React from 'react';
import FormApiAutoSuggest from 'components/FormApiAutoSuggest';
import {FormDataContext} from 'FormDataContext';

export default function({item, tracePath, parent}) {
    const {topColsBlock, disabled, field, title, format, options, api, required, apiFilter, ...other} = item;
    
    const {state, handleChange, setState, onFormDataAction} = React.useContext(FormDataContext);
    const {values, errors}                                  = state;
    
    const handleSuggest = item => suggest => {
        console.log('handleSuggest', suggest, suggest.data);
        if (suggest.data && item.mapSuggestions) {
            let newValues = onFormDataAction(
                item.mapSuggestions(suggest.data, values),
                {
                    action:  'suggest',
                    old:     values,
                    suggest: suggest,
                    field,
                    item,
                    value:   suggest.data,
                },
            );
            setState(state => (
                {
                    ...state,
                    values:      {
                        ...state.values,
                        ...newValues,
                    },
                    submitError: false,
                }
            ));
        }
    };
    
    return <React.Fragment>
        <FormApiAutoSuggest
            item={item}
            label={title}
            value={values[field]}
            api={api}
            required={required}
            apiFilter={apiFilter && apiFilter(values, parent)}
            error={!!errors[field]}
            onSuggest={handleSuggest(item)}
            onChange={handleChange(item)}
            InputLabelProps={{shrink: true}}
            {...other}
            tracePath={[...tracePath, 'FormApiAutoSuggest']}
        />
    </React.Fragment>;
}