import request from 'superagent';

const errorHandler = Symbol('errorHandler');

const FIELD_CREATE_NAME = '_CREATE';

export const STATE = {
    INIT:    0,
    LOADING: 1,
    LOADED:  2,
    ERROR:   3,
};

export default class API {
    static fieldCreateName = FIELD_CREATE_NAME;

    constructor() {
        this.host     = window.location.origin;
        this.authPath = `${this.host}/connect/google?`;
        this.apiHost  = `${this.host}/api/`;
        this.apiHost  = `/api`;
        this.upload   = '/upload';
    }

    getApiHost = () => {
        return this.apiHost;
    };
    getUploadImg = (img = '') => {
        if(img.match(/^http/)){
            return img
        }
        return `${this.upload}/${img}`
    }

    get = async (endPoint, data = {}, onError = null) => {
        try {
            const response = await request
                .get(`${this.apiHost}/${endPoint}`)
                .set('accept', 'application/json')
                .query(data);
            return response.body;
        }
        catch (err) {
            return this[errorHandler](err, onError);
        }
    };

    getOne = async (endPoint, guid = {}, data = {}, onError = null) => {
        try {
            const response = await request
            .get(endPoint)
            .set('accept', 'application/json')
            .query(data);
            return response.body;
        }
        catch (err) {
            return this[errorHandler](err, onError);
        }
    };

    getText              = async (endPoint, data = {}) => {
        try {
            const response = await request
            .get(`${this.apiHost}/${endPoint}`)
            .query(data);
            return response.text;
        }
        catch (err) {
            return this[errorHandler](err);
        }
    };

    getSubQueriesCutData = data => {
        let subQueries = Object.entries(data[FIELD_CREATE_NAME]);
        let cutData    = {...data};
        subQueries.forEach(([field]) => delete cutData[field]);
        delete cutData[FIELD_CREATE_NAME];
        return {subQueries, cutData};
    };

    subItemsCreateAndLink = async (endPoint, data, dataGuid) => {
        try {
            let {subQueries} = this.getSubQueriesCutData(data);
            let api          = new API();

            const results = await (
                async () => {
                    let results = {};
                    for (const [field, {apiName, parentField, parentList}] of subQueries) {
                        console.log('api.post', apiName, field, data[field], data, dataGuid);
                        let uri;
                        if (Array.isArray(data[field])) {
                            uri = [];
                            for (const itemData of data[field]) {
                                const response = await api.post(apiName, {
                                    ...itemData,
                                    [parentField]: `${this.apiHost}/${endPoint}/${dataGuid}`,
                                });
                                uri.push(`${this.apiHost}/${apiName}/${response.body.guid}`);
                            }
                            data[parentList] = uri;
                        }
                        else {
                            const response = await api.post(apiName, {
                                ...data[field],
                                [parentField]: `${this.apiHost}/${endPoint}/${data.guid}`,
                            });
                            uri            = `${this.apiHost}/${apiName}/${response.body.guid}`;
                            data[parentList].push(uri);
                        }

                        results[field] = uri;
                    }
                    return results;
                }
            )();

            return await request.put(
                `${this.apiHost}/${endPoint}/${dataGuid}`)
                .set('accept', 'application/ld+json')
                .send({...data, ...results, [FIELD_CREATE_NAME]: undefined});
        }
        catch (e) {
            console.log('FIELD_CREATE_NAME catch', e);
        }
    };

    post = async (endPoint, data, onError = null) => {
        console.log('post data', data);
        try {
            let result;
            if (data[FIELD_CREATE_NAME] || true) {
                result = await request.post(
                  `${this.apiHost}/multi/${endPoint}`).set('accept', 'application/json').send(data);
            }
            else {

                result = await request.post(
                    `${this.apiHost}/${endPoint}`).set('accept', 'application/json').send(data);
            }
            console.log('post result', result);
            return result;
        }
        catch (e) {
            try {
                await this[errorHandler](e, onError);
            }
            catch (e) {
                // TODO: remove double catch
                return {body: {errors: e}};
            }
            return e.response;
        }
    };

    put = async (endPoint, data) => {
        if (data[FIELD_CREATE_NAME] || true) {
            return request.put(
                `${this.apiHost}/multi/${endPoint}/${data.id || data.guid}`)
                .set('accept', 'application/json')
                .send(data)
                .catch(this[errorHandler]);
        }
        return request.put(
            `${this.apiHost}/${endPoint}/${data.id || data.guid}`)
            .set('accept', 'application/json')
            .send(data)
            .catch(this[errorHandler]);
    };

    patch = (endPoint, data) => {
        return request
            .patch(`${this.apiHost}/${endPoint}/${data.id || data.guid}`)
            .set('accept', 'application/json')
            .send(data)
            .catch(this[errorHandler]);
    };

    delete = (endPoint, data = {}) => {
        return request
            .del(`${this.apiHost}/${endPoint}/${data.id || data.guid}`, data)
            .set('accept', 'application/json')
            .catch(this[errorHandler]);
    };

    upload = (endPoint, formData) => {
        return request
            .post(`${this.apiHost}/${endPoint}`)
            .send(formData)
            .catch(this[errorHandler]);
        
    };

    [errorHandler](err, onError = null) {
        if ('function' == typeof onError) {
            onError(err);
            return;
        }
        let errorMessage = err.message;
        switch (err.status) {
            case 401:
                //window.location.replace('/login');
                //window.location.replace(this.authPath);
                break;
            default:
                if (err.response && 'application/problem+json' === err.response.type) {
                    errorMessage = {};
                    if (err.response.body.violations) {
                        err.response.body.violations.forEach(i => {
                            errorMessage[i.propertyPath] = i.message;
                        });
                    }
                    else {
                        errorMessage.message = err.response.body.detail || err.response.body.title;
                    }
                }
                else if (err.response && err.response.body && err.response.body.error) {
                    errorMessage = err.response.body.error;
                }
                else if (err.response && err.response.body && err.response.body.detail) {
                    errorMessage = err.response.body.detail;
                }
                break;
        }
        return new Promise((resolve, reject) => reject(errorMessage));
    }
}