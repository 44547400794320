import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import {useSelector} from 'react-redux';

import {
    BrowserRouter as Router,

    // Switch,
    // Route,
    // Link
} from 'react-router-dom';

import {
    AppBar,
    Grid,
    CssBaseline,
    Toolbar,
    Typography,
    IconButton,
    SwipeableDrawer,
    LinearProgress,
    Badge,
    Tooltip,
} from '@material-ui/core';


import {
    MenuIcon,
    NotificationsIcon,
    MoreIcon,
} from 'components/icons';

import {
    MenuTree,
    MenuComponents,
} from 'components/Menu';

import BreadCrumbs from 'components/BreadCrumbs';
import {ROLES} from './config/roles';

import MyProfile from 'components/MyProfile';
import {BreadsContext} from 'BreadsContext';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: 'auto',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

export default function App(props) {
    const classes = useStyles();
    
    const [options, setOptions] = React.useState([]);

    const [open, setOpen]  = useState(false);
    const handleDrawerOpen = () => {
        setOpen(!open);
    };
    const { window } = props;
    const container = window !== undefined ? () => window().document.body : undefined;
    const me        = useSelector(s => s.system && s.system.me && s.system.me.items);
    const {roles} = me;
    return (
            <Router>
            <div className={classes.root}>
                <CssBaseline/>
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                // className={clsx(classes.menuButton, open && classes.hide)}
                        >
                            <MenuIcon/>
                        </IconButton>

                        <Typography variant="h6" noWrap>
                            TODO Title
                        </Typography>
                        <div className={classes.grow}/>
                        <div className={classes.sectionDesktop}>

                            {/*<AccountCircle />*/}
                            <Grid container
                                  spacing={4}
                                  alignItems="baseline"
                                  direction={'row'}
                                  justify="center"
                            >
                                <Grid item></Grid>
                            </Grid>

                        </div>
                        <div className={classes.sectionMobile}>
                            <IconButton
                                    aria-label="show more"
                                    // aria-controls={mobileMenuId}
                                    aria-haspopup="true"
                                    // onClick={handleMobileMenuOpen}
                                    color="inherit"
                            >
                                <MoreIcon/>
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                <nav className={classes.drawer} aria-label="mailbox folders">
                {/*<Hidden xsDown implementation="css">*/}
                <Drawer
                        container={container}
                        variant="permanent"
                        // anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={true}
                        onClose={handleDrawerOpen}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            // keepMounted: true, // Better open performance on mobile.
                        }}
                >
                    <br/><br/><br/><br/>
                    <Grid item><MyProfile/></Grid>
                    <MenuTree onClick={handleDrawerOpen} roles={roles}/>
                </Drawer>
                {/*</Hidden>*/}
                </nav>
                <div className={classes.content}>
                    <Toolbar />
                    <BreadsContext.Provider value={{...options, setOptions}}>
                    <div className={classes.toolbar}/>
                    {/*<Grid container className={classes.root} spacing={2}>*/}
                    <BreadCrumbs />
                    <MenuComponents roles={roles}/>
                    {/*</Grid>*/}
                    </BreadsContext.Provider>
                </div>
            </div>
            </Router>
    );
}
