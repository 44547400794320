import React from "react";
import {config} from 'config';
import PageApiLoader3 from 'components/PageApiLoader3';

export default function Page(props) {
    if (window.tracePath) console.log('tracePath: ', props.tracePath.join('/'));
    return <PageApiLoader3
        {...props}
        {...config.docRequest}
        canEdit={true}
        canAdd={true}
        tracePath={[...props.tracePath,'PageApiLoader3']}
    />;
}