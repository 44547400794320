import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import AdminShow from 'components/FieldFormat/AdminShow';
import {EditIcon} from 'components/icons';
import {transformCols} from 'components/TableMaterialTableShow';
import {config} from 'config';
import {STATE} from 'libs/api';

import MaterialTable, {MTableToolbar} from 'material-table';
import React from 'react';
import request from 'superagent';
import {useSelector} from 'react-redux';

export function SubFormEditCreateTable(props) {
    const {listTitle, canEdit, onEdit, values, onChange, item, itemValues, menu} = props;
    const {api, apiFilter}                                                 = item;
    
    const {icon: TitleIcon} = {icon: null, ...menu};
    
    const [state, setState] = React.useState(STATE.INIT);
    
    const query             = apiFilter(itemValues);
    React.useEffect(() => {
        let active = true;
        if (!active || !itemValues || STATE.INIT !== state) {
            return;
        }
        setState(STATE.LOADING);
        request
            .get(`/api/${api}`)
            .set('accept', 'application/json')
            .query(query)
            .then(response => {
                onChange(null, response.body);
                setState(STATE.LOADED);
            })
            .catch(error => {
                console.error('SubFormEditCreateTable', error);
                setState(STATE.ERROR);
            });
        return () => {
            active = false;
        };
        // eslint-disable-next-line
    }, [state, api, query, itemValues.guid, onChange]);
    
    const [columns, setColumns] = React.useState([]);
    const me        = useSelector(s => s.system && s.system.me && s.system.me.items)|| {};
    // todo: use global setColumns
    React.useMemo(() => {
        let c = 'function' === typeof props.cols
            ? props.cols(props, values,{me})
            : props.cols;
        
        if (c instanceof Promise) {
            c.then(data => setColumns(data));
        }
        else {
            setColumns(c);
        }
        // eslint-disable-next-line
    }, [props.cols]);
    
    console.log('show state', state, columns.length);
    switch (state) {
        case STATE.INIT:
        case STATE.LOADING:
            return <CircularProgress size={24}/>;
        default:
        case STATE.ERROR:
            return <React.Fragment>Error load data</React.Fragment>;
        case STATE.LOADED:
            break;
    }
    if (!columns || !columns.length) {
        return <CircularProgress size={24}/>;
    }
    
    return <React.Fragment>
        <AdminShow>[SubFormEditCreateTable]</AdminShow>
        <MaterialTable
            title={listTitle}
            columns={transformCols(columns, {onEdit, TitleIcon})}
            data={[...values]}
            actions={[
                // canEdit && {
                //     icon:    EditIcon,
                //     tooltip: 'Редактировать',
                //     onClick: (event, rowData) => onEdit(rowData)(),
                // },
            ]}
            {...config.MaterialTable}
            // components={{
            //     Cell: MyMTableCell,
            // }}
            options={{
                search:                 false,
                showTitle:              true,
                paging:                 false,
                toolbarButtonAlignment: 'left',
            }}
            components={{
                Container: (props) => props.children,
                Toolbar: props => (
                    <div>
                        <MTableToolbar {...props} />
                    </div>
                ),
            }}
            // todo: update cellEditable
            cellEditable={{
                onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                    console.log('onCellEditApproved',newValue, oldValue, rowData, columnDef);
                    return new Promise((resolve, reject) => {
    
                        const data = [...values];
                        const newData = {...rowData};
                        delete newData.tableData;
                        const field = columnDef.field;
                        
                        newData[field] = newValue;
                        console.log('newData', newData, data);
                        const index = data.indexOf(rowData);
                        data[index] = newData;
                        onChange(null, data);
                        resolve();

                    });
                }
            }}
            editable2={{
                onRowAdd:    newData =>
                                 new Promise((resolve) => {
                                     setTimeout(() => {
                                         {
                                             const data = [...values];
                                             data.push(newData);
                                             console.log('newData', newData, data);
                                             onChange(null, data, () => resolve());
                                         }
                                         resolve();
                                     }, 0);
                                 }),
                onRowUpdate: (newData, oldData) =>
                                 new Promise((resolve) => {
                                     const data = [...values];
                                     console.log('newData', newData, data);
                                     const index = data.indexOf(oldData);
                                     data[index] = newData;
                                     onChange(null, data);
                                     console.log('newData', newData, data);
                                     setTimeout(() => {
                                         resolve();
                                     }, 0);
                                 }),
                onRowDelete: oldData =>
                                 new Promise((resolve) => {
                                     setTimeout(() => {
                                         {
                                             let data    = [...values];
                                             const index = data.indexOf(oldData);
                                             data.splice(index, 1);
                                             onChange(null, data);
                                             resolve();
                                             // this.setState({ data }, () => resolve());
                                         }
                                         resolve();
                                     }, 1000);
                                 }),
            }}
        /></React.Fragment>;
}