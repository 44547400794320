import React from 'react';
import {AddressIcon} from 'components/icons';
import {ColObjectName, ShowDate} from 'components/showItems';
import {FieldFormat} from 'config/FieldFormat';
import {ROLES} from 'config/roles';
import PageAddresses from 'page/PageAddresses';
import PageContracts from 'page/PageContracts';
import optionsLines from './optionsLines';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import {MoveToInbox as MoreIcon, Error as AlertIcon} from '@material-ui/icons';
import {Dialog, DialogTitle, Tooltip} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import request from 'superagent';
import SumDelivery from '../components/FieldFormat/SumDelivery';
import ConfirmDialog from '../components/ConfirmDialog';

export const formatMark = val => {
    console.log('formatMark',val)
    const v = val.toString();
    const match = v.match(/(\d+)\+(:?\d+)/),
        parts = match || v.match(/(\d{0,4}?)(\d{0,3})$/) || [];
    
    let a = (
        '0000' + parts[1]
    ).substr(-4);
    let b = (
        '000' + parts[2]
    ).substr(-3);
    
    return a + '+' + b;
}

export const ColShowMark = ({item}) => {
    return formatMark(item || 0);
};

const ColShowMark2 = ({item}) => {
    if (!item) {
        return '';
    }
    const val   = (
              item || 0
          ).toString(),
          match = val.match(/(\d+)\+(:?\d+)/),
          parts = match || val.match(/(\d{0,4}?)(\d{0,3})$/) || [];

    let a = (
        '0000' + parts[1]
    ).substr(-4);
    let b = (
        '000' + parts[2]
    ).substr(-3);

    return a + '+' + b;
};

function SetGPS(props) {
    const [open, setOpen]          = React.useState(false);
    const {values, setStateValues} = props;
    const [items, setItems]        = React.useState({});
    const [loading, setLoading]    = React.useState(false);
    const api                      = 'detect_gps';
    React.useEffect(() => {
        try {
            setLoading(true);
            request.get(`/api/${api}`)
                   .set('accept', 'application/json')
                   .query({
                       'road': (
                                   values.road || ''
                               ).replace('/api/roads/', ''),
                       'mark': values.mark1,
                   })
                   .then(response => {
                       setItems(response.body);
                       setLoading(false);
                   });

        } catch (e) {
            setItems(['Error: ' + e.message]);
            setLoading(false);
        }
    }, [values.road, values.mark1]);

    if (!values.road) {
        return null;
    }

    const selectItem = item => event => {
        setStateValues(values => (
            {
                ...values,
                gps1: item.gps1,
                gps2: item.gps2,
            }
        ));
    };

    return <React.Fragment>
        {items.gps1 && <IconButton onClick={selectItem(items)} size={'small'}>
            <Tooltip title={'Заполнить координаты автоматически'}><MoreIcon/></Tooltip>

        </IconButton>}

    </React.Fragment>;
}

function SetMark(props) {
    const [open, setOpen]          = React.useState(false);
    const {values, setStateValues} = props;
    const [items, setItems]        = React.useState([]);
    const [loading, setLoading]    = React.useState(false);
    const api                      = 'detect_mark';
    React.useEffect(() => {
        try {
            setLoading(true);
            request.get(`/api/${api}`)
                   .set('accept', 'application/json')
                   .query({
                       'road': (
                                   values.road || ''
                               ).replace('/api/roads/', ''),
                       'gps1': values.gps1,
                       'gps2': values.gps2,
                   })
                   .then(response => {
                       setItems(response.body);
                       setLoading(false);
                   });

        } catch (e) {
            setItems(['Error: ' + e.message]);
            setLoading(false);
        }
    }, [values.road, values.gps1, values.gps2]);

    if (!values.road) {
        return null;
    }

    const selectItem = mark => event => {

        // Проверить сумму расстояний до них Р1+Р2 . Если она больше 1,5 км, то вывести ошибку "точка вне дороги"
        // М=Р1*1000/(Р1+Р2)			Здесь К1 - номер столба, меньший из выбранных двух, Р1 - расстояние до него.
        setStateValues(values => (
            {
                ...values,
                mark1: mark,
            }
        ));
    };

    if (items.length !== 2) {
        return null;
    }

    const [P1, P2] = items;
    const sumLen   = Number(P1.distance) + Number(P2.distance);
    if (sumLen > 1.5) {
        return <Tooltip title={'точка вне дороги'}><AlertIcon/></Tooltip>;
    }
    let mark = Math.round(Number(P1.distance) * 1000 / sumLen);

    return <React.Fragment>
        {items.length === 2 && <IconButton onClick={selectItem(mark)} size={'small'}>
            <Tooltip title={'Заполнить расстояние автоматически'}><MoreIcon/></Tooltip>
        </IconButton>}
    </React.Fragment>;

}

function SelectUnit(props) {
    const [open, setOpen]          = React.useState(false);
    const {values, setStateValues} = props;
    const [items, setItems]        = React.useState([]);
    const [loading, setLoading]    = React.useState(false);
    const api                      = 'sub_units';
    React.useEffect(() => {
        try {
            setLoading(true);
            request.get(`/api/${api}`)
                   .set('accept', 'application/json')
                   .query({
                       'roadUnits.road':           values.road || -1,
                       'roadUnits.routeFrom[lte]': values.mark1,
                       'roadUnits.routeTo[gte]':   values.mark2,
                       // 'roadUnits.isDeleted':      false,
                   })
                   .then(response => {
                       setItems(response.body);
                       selectItem(response.body[0]||{})();
                       setLoading(false);
                   });

        } catch (e) {
            setItems(['Error: ' + e.message]);
            setLoading(false);
        }
    }, [values.road, values.mark1, values.mark2]);


    const value = (
        values.subUnit || ''
    ).toString().replace('/api/sub_units/', '');

    const selectItem = item => event => {
        setStateValues(values => (
            {
                ...values,
                subUnit: item.id,
                subUnitLook: item.id,
            }
        ));
        setOpen(false);
    };

    if (!values.road) {
        return null;
    }
    return null;
    return <React.Fragment>
        <IconButton onClick={() => setOpen(true)} size={'small'}>
            {items.length > 0 && <MoreIcon/>}
        </IconButton>
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Подходящие подразделения</DialogTitle>
            <List>
                {items.length
                    ? items.map((i) => (
                        // <ListItem button onClick={() => handleListItemClick(email)} key={email}>
                        <ListItem button onClick={selectItem(i)}>
                            <ListItemText primary={i.name}/>
                        </ListItem>
                    ))
                    : <ListItem>Нет подходящих подразделений</ListItem>
                }

            </List>
        </Dialog>
    </React.Fragment>;
}

export default {
    listTitle:        'Адрес',
    apiName:          'addresses',
    itemName:         'адрес',
    canDelete:        true,
    roles:            [
        ROLES.ROLE_USER,
    ],
    menu:             {
        title:     'Адрес',
        path:      '/addresses',
        Component: PageAddresses,
        roles:     [ROLES.ROLE_USER],
        icon:      AddressIcon,
    },
    onFormDataAction: (values, props) => {
        const {event, field, action} = props;
        const {item}                 = event && event.target || {};
        let valuesAdd                = {};

        const roadName = field && field === 'road' && item && item.name || values.roadName;

        if (roadName) {
            const roadMark1 = values.mark1
                ? formatMark(values.mark1)
                : null;
            const roadMark2 = values.mark2
                ? formatMark(values.mark2)
                : null;
            const mark      = [roadMark1, roadMark2].filter(v => v).join(' - ');
            valuesAdd.name  = [roadName,mark].join(', ');
            valuesAdd.roadName  = roadName;
        }

        return {
            ...values,
            ...valuesAdd,
        };
    },
    initialValues:    () => (
        {
            // mark1: '000+000'
        }
    ),
    cols:             [
        {
            title:    'Дорога',
            field:    'roadName',
            disabled: true,
            format:   FieldFormat.FORMAT_NONE,
        },
        {
            title:  'needConfirmMark1',
            field:  'needConfirmMark1',
            format: FieldFormat.FORMAT_CUSTOM,
            hidden: true,
            hiddenInCard: true,
            custom: props => {
                const {errors, values, setState} = props;
                const {needConfirmMark1}         = errors || {};
                const {val}                      = values || {};
                if (needConfirmMark1) {
                    return <ConfirmDialog
                        show={true}
                        text={needConfirmMark1.concat(' сохранить?')}
                        onConfirm={() => setState(state => ({
                            ...state,
                            values: {
                                ...state.values,
                                needConfirmMark1: 1,
                            },
                            errors: {
                                ...state.errors,
                                needConfirmMark1: undefined,
                                mark1:            undefined,
                            },
                        }))}
                        onCancel={() => setState(state => ({
                            ...state,
                            errors: {
                                ...state.errors,
                                needConfirmMark1: undefined,
                                mark1:            undefined,
                            },
                        }))}
                    />;

                }
                return null;
            },
        },

        {
            title:    'Дорога',
            field:    'road',
            format:   FieldFormat.FORMAT_SELECT_API,
            api:      'roads',
            object:   ColObjectName,
            cols:     3,
            hidden:   true,
            hiddenInCard:   true,
            required: true,
        },
        {
            title:       'Отметка 1',
            field:       'mark1',
            placeholder: '0000+000',
            required:    true,
            cols:        3,
            object:      ColShowMark,
            format:      FieldFormat.FORMAT_INPUT_MASK,
            mask:        [/\d/, /\d/, /\d/, /\d/, '+', /\d/, /\d/, /\d/],
            fixValue:    value => Number((value||0).toString().replace(/\D+/, '')),
            startButtons: ({values, setStateValues}) => [
                <SetGPS values={values} setStateValues={setStateValues}/>,
            ],
        },
        {
            title:       'Отметка 2',
            field:       'mark2',
            placeholder: '0000+000',
            cols:        3,
            object:      ColShowMark2,
            format:      FieldFormat.FORMAT_INPUT_MASK,
            mask:        [/\d/, /\d/, /\d/, /\d/, '+', /\d/, /\d/, /\d/],
            fixValue:    value => Number((value||0).toString().replace(/\D+/, '')),
        },
        {
            format: FieldFormat.FORMAT_CUSTOM,
            custom: props => <hr/>,
        },
        {
            title:   'Направление',
            field:   'direction',
            format:  FieldFormat.FORMAT_SELECT,
            options: [
                {
                    id:   'Прямое',
                    name: 'Прямое',
                },
                {
                    id:   'Обратное',
                    name: 'Обратное',
                },
                {
                    id:   'Прямое/Обратное',
                    name: 'Прямое/Обратное',
                },
            ],
            cols:    3,
            hidden:  true,
        },
        {
            title:  'Широта, отметка 1',
            field:  'lat',
            cols:   6,
            hidden: true,
        },
        {
            title:        'Долгота, отметка 1',
            field:        'lang',
            cols:         6,
            hidden:       true,
            startButtons: ({values, setStateValues}) => [
                <SetMark values={values} setStateValues={setStateValues}/>,
            ],
        },
        {
            title:  'Широта, отметка 2',
            field:  'lat2',
            cols:   6,
            hidden: true,
        },
        {
            title:  'Долгота, отметка 2',
            field:  'lang2',
            cols:   6,
            hidden: true,
        },
        {
            title:  'Область',
            field:  'region',
            cols:   3,
            hidden: true,
        },
        {
            title:  'Ориентир',
            field:  'landmark',
            cols:   3,
            hidden: true,
        },
        {
            title:    'Подразделение',
            field:    'subUnitLook',
            // field:    'subUnit',
            format:   FieldFormat.FORMAT_SELECT_API,
            api:      'sub_units',
            object:   ColObjectName,
            cols:     3,
            hidden:   true,
            // required: true,
            disabled: true,
            // selection: true,
            startButtons: ({values, setStateValues}) => [
                <SelectUnit values={values} setStateValues={setStateValues}/>,
            ],
        },
        {
            title:  'Электроснабжение',
            field:  'powerSupply',
            format: FieldFormat.FORMAT_SELECT_API,
            api:    'power_supplies',
            object: ColObjectName,
            cols:   3,
            hidden: true,
        },
        {
            title:   'Количество полос прямое направление',
            field:   'linesForward',
            format:  FieldFormat.FORMAT_SELECT,
            options: optionsLines,
            cols:    3,
            hidden:  true,
        },
        {
            title:   'Количество полос обратное направление',
            field:   'linesBackward',
            format:  FieldFormat.FORMAT_SELECT,
            options: optionsLines,
            cols:    3,
            hidden:  true,
        },
        {
            title:  'Категория дороги',
            field:  'roadCategory',
            cols:   3,
            hidden: true,
        },
        {
            title:  'Комментарий',
            field:  'description',
            format: FieldFormat.FORMAT_TEXTAREA,
            hidden: true,
        },
        // {
        //     title: 'Аббревиатура',
        //     field: 'code',
        //     cols:  3,
        // },
        // {
        //     title: 'Высота',
        //     field: 'altitude',
        //     cols:  3,
        // },
        // {
        //     title: 'Макс скорость',
        //     field: 'speedLimit',
        //     cols:  3,
        // },
    ],
};
