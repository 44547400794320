import React from 'react';
import {MenuItem, TextField} from '@material-ui/core';
import {FormDataContext} from 'FormDataContext';

export default function({item}) {
    const {field, title, options, required} = item;
    
    const {state: {values, errors}, handleChange} = React.useContext(FormDataContext);
    const setState                                = (params) => {
        // todo: remove
        console.error('TODO: remove Call setState with params:', params);
    };
    return <React.Fragment>
        <TextField
            label={title}
            fullWidth
            
            // onChange={handleChange(item)}
            onChange={event => {
                handleChange(item)(null, event.target.value);
                if (item.onSelect) {
                    item.onSelect({
                        option: options.find(option => option.id === event.target.value),
                        setState,
                        values,
                        errors,
                    });
                }
            }}
            select
            value={values[field] || ''}
            error={!!errors[field]}
            margin={'dense'}
            variant={'outlined'}
            required={required}
        >
            {(
                options || []
            ).map(({name, id}) => <MenuItem key={id} value={id}>{name}</MenuItem>)}
        </TextField>
    </React.Fragment>;
}