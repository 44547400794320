import AdminShow from 'components/FieldFormat/AdminShow';
import React from 'react';
import {TextField, Tooltip} from '@material-ui/core';
import {FormDataContext} from 'FormDataContext';
import InputAdornment from '@material-ui/core/InputAdornment';
import {AccountCircle} from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import { grey } from '@material-ui/core/colors';

const useStylesBootstrap = makeStyles((theme) => ({
    arrow:   {
        color: theme.palette.grey[500],
    },
    tooltip: {
        backgroundColor: theme.palette.grey[500],
    },
    _log:  console.log(theme.palette)
}));
function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
}

function MyComponent(props) {
    const {submitting, item, value, error, handleChange, hintTitle}                                   = props;
    const {topColsBlock, disabled, field, title, format, options, api, required, apiFilter, ...other} = item;

    return <React.Fragment>
        <AdminShow/>
        <TextField
                disabled={disabled || submitting}
                label={title}
                value={value}
                required={required}
                margin={'dense'}
                fullWidth
                variant={'outlined'}
                name={field}
                onChange={handleChange}
                error={!!error}
                helperText={error}
                InputLabelProps={{shrink: true}}
                InputProps={hintTitle ? {
                    endAdornment: (
                                          <InputAdornment position="end">
                                              <BootstrapTooltip title={hintTitle}>
                                                  <InfoIcon style={{ color: grey[500] }} />
                                              </BootstrapTooltip>
                                          </InputAdornment>
                                  ),
                } : null}
                {...other}
        />
    </React.Fragment>;
}

function areEqual(prevProps, nextProps) {
    return prevProps.value === nextProps.value
            && prevProps.hintTitle === nextProps.hintTitle;
}

const MyComponentMemo = React.memo(MyComponent, areEqual);

function MyComponentMemoUse(props) {
    const {field}                                 = props.item;
    const {state: {values, errors}, handleChange} = React.useContext(FormDataContext);
    const handleChangeLocal                       = (e) => {
        console.log('handleChange Input', e, e.target, e.target.value);
        return handleChange(props.item)(e, e.target.value);
    };
    const hintTitle                               = values.tarifDocument
            ? 'По документу стоимость доставки' : null;

    const value = values && values[field];
    const error = errors && errors[field];

    return <MyComponentMemo
            values={values}
            value={value || ''}
            error={error || null}
            handleChange={handleChangeLocal}
            hintTitle={hintTitle}
            {...props}
    />;
}

export default MyComponentMemoUse;