import {RequestFormDebugContext} from 'components/RequestFormDebug';
import {ApiObjectLoadForMTable} from '../showItems';
import Chip from '@material-ui/core/Chip';
import React from 'react';
import ErrorBoundary from 'components/ErrorBoundary';
import {RenderList} from 'config/RenderList';
import {FieldFormat} from 'config/FieldFormat';
import AdminShow from 'components/FieldFormat/AdminShow';
import {FormDataContextConsumer, FormDataContextProvider} from 'FormDataContext';

export default function detectEditComponent(item, num) {
    const {format, editComponent, field, lookup, type} = {
        format: FieldFormat.FORMAT_INPUT,
        ...item,
    };
    if (editComponent) {
        return editComponent;
    }
    const Render = RenderList(format);
    if (format === FieldFormat.FORMAT_NONE || type) {
        // Временно исключить новый рендер для того где указано lookup и type
        return null;
    }
    
    return (props) => {
        // console.log('detectEditComponent',{props})
        const {columnDef, error, helperText, rowData, value, onChange} = props;
        return <ErrorBoundary info={'materialTable.render:' + format}>
            <AdminShow>[MTContext]</AdminShow>
            <FormDataContextProvider initial={{[field]: value}}>
                <FormDataContextConsumer>{context => {
                    console.log('handleChange subValues FormDataContextConsumer', {context, value, props});
                    if (JSON.stringify(value) !== JSON.stringify(context.state.values[field])) {
                        onChange(context.state.values[field]);
                    }
                }}</FormDataContextConsumer>
                <Render
                    item={item}
                    // submitting={submitting}
                    tracePath={[format]}
                    parent={props}
                    variant={'standard'}
                />
                <RequestFormDebugContext />
            </FormDataContextProvider>
        </ErrorBoundary>;
    };
}
