/* eslint-disable no-use-before-define */

import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {makeStyles} from '@material-ui/core/styles';
import {config} from 'config';
import {ApiDataLoad} from '../showItems';

const icon        = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

// tagSizeSmall
const useStyles = makeStyles(() => (
    {
        tag: {
            maxWidth: '75%',
        },
    }
));

export default function FormApiAutocompleteCheckbox(props) {
    const {
              label,
              value,
              api,
              apiFilter,
              placeholder,
              onChange,
          } = props;
    // return JSON.stringify({label,
    //     value,
    //     api,
    //     placeholder});

    const [options, setOptions] = React.useState([]);
    const loading               = options.length === 0;
    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (
            async () => {
                try {
                    const response = await ApiDataLoad(config.apiPrefix + api, {...apiFilter});
                    // const response = await request
                    //     .get(config.apiPrefix + api)
                    //     .set('accept', 'application/json')
                    //     // todo: .query({name: inputValue, ...apiFilter});
                    //     .query({...apiFilter});

                    if (active) {
                        setOptions(response);
                    }
                } catch (e) {
                    setOptions([{name: 'HTTP Error: '+e.message}]);
                }
            }
        )();

        return () => {
            active = false;
        };
    }, [loading, api,apiFilter]);

    const onChangeLocal = (event, value) => {
        console.log('onChangeLocal', value.map(i => i.guid || i.id));
        onChange(null, value.map(i => i.guid || i.id));
    };
    // tagSizeSmall
    const classes       = useStyles();
    return (
        <Autocomplete
            multiple
            options={options}
            classes={{tag: classes.tag}}
            limitTags={1}
            ChipProps={{
                size: 'small',
            }}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            value={options && options.filter(i => value.includes(i.guid || i.id))}
            onChange={onChangeLocal}
            noOptionsText={'Нет вариантов'}
            renderOption={(option, {selected}) => (
                <React.Fragment>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={selected}
                    />
                    {option.name}
                </React.Fragment>
            )}
            style={{width: '100%'}}
            renderInput={(params) => (
                <TextField {...params}
                           fullWidth
                           variant="outlined"
                           margin={'dense'}
                           label={label}
                           placeholder={placeholder}/>
            )}
        />
    );
}
