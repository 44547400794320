import {ColShowMark} from 'config/address';
import React from 'react';
import {ServiceIcon} from 'components/icons';
import {ShowApiName} from 'components/showItems';
import {FieldFormat} from 'config/FieldFormat';
import {ROLES} from 'config/roles';
import PageRoadUnits from 'page/PageRoadUnits';

export default {
    listTitle: 'Дороги подразделение',
    apiName:   'road_units',
    itemName:  'Дороги подразделение',
    canAdd: true,
    canDelete: true,
    canEdit:   true,
    roles:     [
        ROLES.ROLE_USER,
    ],
    menu:      {
        title:     'Дороги подразделение',
        path:      '/road_units',
        Component: PageRoadUnits,
        roles:     [ROLES.ROLE_USER],
        icon:      ServiceIcon,
    },
    // colsLines: [undefined,2],
    cols:      ({itemProps}) => {
        let road =
                itemProps && itemProps.parentField === 'road'
                    ? []
                    : [
                        {
                            title:  'Дорога',
                            field:  'road',
                            format: FieldFormat.FORMAT_SELECT_API,
                            api:    'roads',
                            object: ShowApiName,
                        },
                    ]
        ;
        
        let unit = itemProps && itemProps.parentField === 'unit'
            ? []
            : [
                {
                    title:    'Подразделение',
                    field:    'unit',
                    format:   FieldFormat.FORMAT_SELECT_API,
                    api:      'sub_units',
                    object:   ShowApiName,
                    required: true,
                },
            ];
        
        return [
            ...road,
            {
                title:  'Отметка с',
                field:  'routeFrom',
                hidden: true,
                placeholder: '0000+000',
                required:    true,
                cols:        2,
                
                format:      FieldFormat.FORMAT_INPUT_MASK,
                mask:        [/\d/, /\d/, /\d/, /\d/, '+', /\d/, /\d/, /\d/],
                fixValue:    value => Number((value||0).toString().replace(/\D+/, '')),
            },
            {
                title:  'Отметка по',
                field:  'routeTo',
                hidden: true,
                placeholder: '0000+000',
                required:    true,
                cols:        2,
                object:      ColShowMark,
                format:      FieldFormat.FORMAT_INPUT_MASK,
                mask:        [/\d/, /\d/, /\d/, /\d/, '+', /\d/, /\d/, /\d/],
                fixValue:    value => Number((value||0).toString().replace(/\D+/, '')),
            },
            {
                title:  'Отметка',
                field:  'routeFrom',
                object: ({row}) => <span><ColShowMark item={row.routeFrom}/> - <ColShowMark item={row.routeTo}/></span>,
                format: FieldFormat.FORMAT_NONE,
            },
            ...unit,
            {
                title:   'Комментарии',
                field:   'description',
                format:  FieldFormat.FORMAT_TEXTAREA,
                line:    2,
                colSpan: 3,
            },
        ];
    },
};
