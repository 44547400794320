import {ServiceIcon} from 'components/icons';
import {ROLES} from 'config/roles';
import PageCustomers from 'page/PageCustomers';

export default {
    listTitle: 'Заказчик',
    apiName:   'customers',
    itemName:  'Заказчика',
    canDelete: true,
    roles:     [
        ROLES.ROLE_USER,
    ],
    menu:      {
        title:     'Заказчик',
        path:      '/customers',
        Component: PageCustomers,
        roles:     [ROLES.ROLE_USER],
        icon:      ServiceIcon,
    },
    cols:      [
        {
            title: 'Наименование',
            field: 'name',
            cols:  3,
        },
        {
            title: 'Юридический адресс',
            field: 'legalAddress',
            cols:  3,
        },
        {
            title: 'Контактное лицо',
            field: 'contactPerson',
            cols:  3,
        },
        {
            title: 'Телефон',
            field: 'phone',
            cols:  3,
        },
        {
            title: 'Email для уведомлений',
            field: 'informEmail',
            cols:  3,
        },
        {
            title: 'Телефон для уведомлений',
            field: 'informPhone',
            cols:  3,
        },
    ],
};
