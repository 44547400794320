import React from 'react';
import {RoadIcon} from 'components/icons';
import roadUnit from 'config/roadUnit';
import {ROLES} from 'config/roles';
import PageRoads from 'page/PageRoads';
import {Paper, Table, TableBody, TableCell, TableContainer, TableRow} from '@material-ui/core';
import {detectRender} from 'libs/detectRender';

export default {
    listTitle:        'Дороги',
    apiName:          'roads',
    itemName:         'дорогу',
    canAdd:           true,
    canEdit:          true,
    canDelete:        true,
    roles:            [
        ROLES.ROLE_USER,
    ],
    menu:             {
        title:     'Дороги',
        path:      '/roads',
        Component: PageRoads,
        roles:     [ROLES.ROLE_USER],
        icon:      RoadIcon,
    },
    onFormDataAction: (values, props) => {
        return {
            ...values,
            name: values.number || values.routes
                      ? `${values.number || ''} ${values.routes || ''}`
                      : 'Нет названия',
        };
    },
    cols:             [
        {
            title:    'Наименование',
            field:    'name',
            disabled: true,
        },
        {
            title: 'Маршрут Трассы',
            field: 'routes',
        },
        {
            title: 'Номер',
            field: 'number',
        },
        {
            title: 'Субьект РФ',
            field: 'subject',
        },
    ],
    subApi:           [
        {
            xs:          6,
            title:       'Подразделения',
            item:        roadUnit,
            parentField: 'road',
            apiFilter:   (item) => (
                {
                    road: item.id,
                }
            ),
        },
    ],
};
