import {ServiceIcon} from 'components/icons';
import {ColObjectName, ShowUser} from 'components/showItems';
import {ROLES} from 'config/roles';
import {FieldFormat} from 'config/FieldFormat';
import PageWorkObjectDevices from 'page/PageWorkObjectDevices';

export default {
    listTitle: 'Объект Состав',
    apiName:   'work_object_devices',
    itemName:  'Объект Состав',
    canAdd:    true,
    canDelete: true,
    canEdit:   true,
    roles:     [
        ROLES.ROLE_USER,
    ],
    menu:      {
        title:     'Объект Состав',
        path:      '/work_object_devices',
        Component: PageWorkObjectDevices,
        roles:     [ROLES.ROLE_USER],
        icon:      ServiceIcon,
    },
    cols:      [
        // {
        //     title:  'Объект',
        //     field:  'workObject',
        //     format: FieldFormat.FORMAT_SELECT_API,
        //     api:    'work_objects',
        //     object: ColObjectName,
        // },
        {
            title:    'Наименование',
            field:    'name',
            cols:     4,
            required: true,
        },
        {
            title:  'Модель',
            field:  'model',
            format: FieldFormat.FORMAT_SELECT_API,
            api:    'work_object_models',
            object: ColObjectName,
            cols:   4,
            // required: true,
            // hidden: true,
        },
        {
            title:  'Производитель',
            field:  'vendor',
            format: FieldFormat.FORMAT_SELECT_API,
            api:    'vendors',
            object: ColObjectName,
            cols:   4,
            hidden: true,
        },
        {
            title:  'Серийный номер',
            field:  'serialNumber',
            cols:   4,
            hidden: true,
        },
        {
            title:  'Паспорт',
            field:  'passport',
            cols:   4,
            hidden: true,
        },
        {
            title:  'Телефонный номер',
            field:  'phoneNumber',
            cols:   4,
            hidden: true,
        },
        {
            title:  'Тариф',
            field:  'tariff',
            cols:   4,
            hidden: true,
        },
        {
            title:  'Публичный IP',
            field:  'publicIP',
            cols:   4,
            hidden: true,
        },
        {
            title:  'Порт',
            field:  'port',
            cols:   4,
            hidden: true,
        },
        {
            title:  'Пользователь',
            field:  'username',
            cols:   4,
            hidden: true,
        },
        {
            title:  'Пароль',
            field:  'password',
            cols:   4,
            hidden: true,
        },
        {
            title:  'URL Ресурса',
            field:  'url',
            cols:   4,
            hidden: true,
        },
        // {
        //     title: 'RTSP Поток',
        //     field: 'rtsp',
        //     cols:   4,
        //     hidden: true,
        // },
        // {
        //     title: 'Количество камер',
        //     field: 'countCamera',
        //     cols:   4,
        //     hidden: true,
        // },
        // {
        //     title: 'Количество камер с подсветкой',
        //     field: 'countCameraLight',
        //     cols:   4,
        //     hidden: true,
        // },
        {
            title:  'Комментарий',
            field:  'description',
            format: FieldFormat.FORMAT_TEXTAREA,
            // hidden: true,
            cols:   1,
        },
        // {
        //     title: 'Количество ПУИД',
        //     field: 'countPUID',
        //     cols:   4,
        //     hidden: true,
        // },
    ],
};
