import {ContractIcon} from 'components/icons';
import {ColObjectName, ShowDate} from 'components/showItems';
import {FieldFormat} from 'config/FieldFormat';
import {ROLES} from 'config/roles';
import workObjectContract from 'config/workObjectContract';
import PageContracts from 'page/PageContracts';

export default {
    listTitle: 'Договор',
    apiName:   'contracts',
    itemName:  'договор',
    canAdd: true,
    canEdit: true,
    canDelete: true,
    roles:     [
        ROLES.ROLE_USER,
    ],
    menu:      {
        title:     'Договор',
        path:      '/contracts',
        Component: PageContracts,
        roles:     [ROLES.ROLE_USER],
        icon:      ContractIcon,
    },
    cols:      [
        {
            title: 'Номер',
            field: 'number',
            cols:  3,
        },
        {
            title:  'Заказчик',
            field:  'customer',
            format: FieldFormat.FORMAT_SELECT_API,
            api:    'customers',
            object: ColObjectName,
            cols:   3,
        },
        {
            title: 'Скан',
            field: 'scan',
            cols:  3,
        },
        {
            title:  'Дата От',
            field:  'dateFrom',
            format: FieldFormat.FORMAT_DATE,
            object: ShowDate,
            cols:   3,
        },
        {
            title:  'Дата До',
            field:  'dateTo',
            format: FieldFormat.FORMAT_DATE,
            object: ShowDate,
            cols:   3,
        },
        {
            title:  'Обязательства',
            field:  'obligations',
            format: FieldFormat.FORMAT_TEXTAREA,
        },
        {
            title:  'Комментарии',
            field:  'description',
            format: FieldFormat.FORMAT_TEXTAREA,
        },
    ],
    subApi:    [
        {
            xs:          6,
            title:       'Объект',
            item:        workObjectContract,
            parentField: 'contract',
        },
    ],
};
