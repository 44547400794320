import {ServiceIcon} from 'components/icons';
import {ROLES} from 'config/roles';
import PageMaterials from 'page/PageMaterials';

export default {
    listTitle: 'Расходные материалы',
    apiName:   'materials',
    itemName:  'материал',
    canDelete: true,
    roles:     [
        ROLES.ROLE_USER,
    ],
    menu:      {
        title:     'Расходные материалы',
        path:      '/materials',
        Component: PageMaterials,
        roles:     [ROLES.ROLE_USER],
        icon:      ServiceIcon,
    },
    cols:      [
        {
            title:        'Наименование',
            field:        'name',
        },
        {
            title:        'Единыцы измерения',
            field:        'unit',
        },
        {
            title:        'Комментарий',
            field:        'description',
        },
    ],
};
