export const ROLES = {
    ROLE_USER:       'ROLE_USER',
    ROLE_CLIENT:     'ROLE_CLIENT',
    ROLE_INDIVIDUAL: 'ROLE_INDIVIDUAL',
    ROLE_ADMIN:      'ROLE_ADMIN',
    
};

/**
 * Поиск соответствия ролей пользователя и группы или Админ
 * @param itemRole
 * @param userRoles
 * @returns {boolean}
 */
export const matchUserAddItemRoles = (itemRole, userRoles) => {
    
    return Boolean(
        userRoles.find(rUser => ROLES.ROLE_ADMIN === rUser)
        || (itemRole
        && itemRole.find(rItem => userRoles.find(rUser => rItem === rUser))))
        ;
};

