import {FieldFormat} from 'config/FieldFormat';
import API from 'libs/api';
import React from 'react';
import RequestFormDebug from './components/RequestFormDebug'

const FormDataContext = React.createContext({});

const {Provider, Consumer} = FormDataContext;

const fixValuesTypes = (cols, values) => {
    console.log('fixValuesTypes', values);
    cols.forEach(({field, type, fixValue}) => {
        if (field && 'int' === type) {
            values[field] = !isNaN(values[field])
                ? Number(values[field])
                : 0;
        }
        else if (fixValue && typeof fixValue === 'function') {
            console.log('fixValuesTypes');
            values[field] = fixValue(values[field], values);
        }
    });
    return values;
};

function FormDataContextProviderPre({initial, children, onFormDataAction, handleSubmit, dbId, cols, onSuccess}) {
    const [state, setState] = React.useState(
        {
            errors: {},
            values: onFormDataAction(initial || {}, { action: 'initial'}),
        });
    // todo: not call onFormDataAction every time
    // const [errors, setErrors] = React.useState({});
    const {values, errors}  = state;
    
    const handleChange = item => (event, newValue, setStateCallBack) => {
        setState(state => {
            
            console.log('handleChange event: ', event, newValue);
            //const onFormDataAction = values => values;
            
            if (newValue === undefined && (
                !event || !event.target
            )) {
                console.log('handleChange no event: '
//          , typeof event, JSON.stringify(event),!event,!event.target);
                    , typeof event, event, newValue);
                return state;
            }
            const {field, format, api, parentField, parentList} = item;
            const {values, errors}                              = state;
            // delete errors[field];
            // cl(event.target);
            let value;
            if (newValue !== undefined) {
                value = newValue;
            }
            else {
                value = 'checkbox' === event.target.type
                    ? event.target.checked
                    : event.target.value;
            }
            if ([
                    // FieldFormat.FORMAT_SELECT_API,
                    FieldFormat.FORMAT_SUB_FORM,
                    FieldFormat.FORMAT_SUB_TABLE,
                ].includes(format)
                // && 'string' === typeof values[field]
                && 'object' === typeof newValue
            ) {
                values[API.fieldCreateName] = {
                    ...values['_CREATE'],
                    [field]: {
                        apiName: api,
                        parentField,
                        parentList,
                    },
                };
            }
            
            // if (subFormHandleChange) {
            //     subFormHandleChange(newValues);
            // }
            console.log('handleChange state: ', state.values, field, value, event);
            
            return {
                values:      onFormDataAction(
                    {...values, [field]: value},
                    {old: values, field, value, action: 'change', item, event},
                ),
                errors,
                submitError: false,
            };
        }, setStateCallBack);
    };
    
    const handleSuccessSubmit = (data) => {
        console.log('handleSuccessSubmit data', data);
        if (204 === data.statusCode) {
            setState(state => ({
                ...state,
                submitting: false,
                errors:     {},
            }));
        }
        console.log('handleSuccessSubmit', data);
        const {errors} = data.body || {};
        if (!errors) {
            console.log('onSuccess',onSuccess);
            onSuccess(data);
            // handleClose();
        }
        console.log(errors);
        setState(state => ({
            ...state,
            submitting: false,
            errors:     {...errors},
        }));
    };
    
    const handleErrorSubmit = (err) => setState({
        ...state,
        submitting:  false,
        submitError: err.message || err,
    });
    
    const handleSubmitForm = (event, values) => {
        event.preventDefault();
        const isUpdate = initial && initial[dbId];
        
        setState({
            ...state,
            submitting:  true,
            submitError: false,
        });

        handleSubmit(
            fixValuesTypes(cols,values),
            isUpdate
                ? 'put'
                : 'post',
        )
            .then(handleSuccessSubmit)
            .catch(handleErrorSubmit);
    };
    const setStateValues = func => {
        setState(state => {
            return {
                ...state,
                values: {
                    ...state.values,
                    ...onFormDataAction(func(state.values),{old:state.values,action: 'change'}),
                },
            };
        });
    };
    return <Provider value={{state, values, errors, handleChange, setState, setStateValues, onFormDataAction, handleSubmitForm}}>
        {children}
        <RequestFormDebug values={values} />
    </Provider>;
}

function FormDataContextProvider(props) {
    return FormDataContextProviderPre({
        onFormDataAction: values => values,
        ...props,
    });
}

export {Provider, FormDataContext, FormDataContextProvider, Consumer as FormDataContextConsumer};