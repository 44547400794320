import {ServiceIcon} from 'components/icons';
import issueComment from 'config/issueComment';
import {ROLES} from 'config/roles';
import PageIssueMaterials from 'page/PageIssueMaterials';
import PageService from 'page/PageService';
import PageIssues from 'page/PageIssues';
import {FieldFormat} from './FieldFormat';
import {ColObjectName, ShowApiName, ShowDate, ShowDateTime, ShowDateTimeFromNow, ShowUser} from 'components/showItems';

export default {
    listTitle: 'Материалы заявки',
    apiName:   'issue_materials',
    itemName:  'материал заявки',
    canAdd: true,
    canEdit: true,
    canDelete: true,
    roles:     [
        ROLES.ROLE_USER,
    ],
    menu:      {
        title:     'Материалы заявки',
        path:      '/issue_materials',
        Component: PageIssueMaterials,
        roles:     [ROLES.ROLE_USER],
        icon:      ServiceIcon,
    },
    cols:      [
        // {
        //     title: 'Заявка',
        //     field: 'issue',
        //     format: FieldFormat.FORMAT_NONE,
        //     api: 'issues',
        //     object: ShowApiName,
        //     cols:  3,
        // },
        {
            title:  'Материал',
            field:  'material',
            format: FieldFormat.FORMAT_NONE,
            editable: 'never',
            api:    'materials',
            object:    ColObjectName,
            cols:   3,
        },
        {
            title:  'Количество',
            field:  'count',
            cols:   3,
        },
    ],
};
