import {ServiceIcon} from 'components/icons';
import {ColBoolean, ShowApiName} from 'components/showItems';
import {ROLES} from 'config/roles';
import PageUsers from 'page/PageUsers';
import {FieldFormat} from 'config/FieldFormat';

export default {
    listTitle:     'Пользователи',
    apiName:       'users',
    itemName:      'пользователя',
    canDelete:     true,
    roles:         [
        ROLES.ROLE_USER,
    ],
    menu:          {
        title:     'Пользователи',
        path:      '/admin/users',
        Component: PageUsers,
        roles:     [ROLES.ROLE_USER],
        icon:      ServiceIcon,
    },
    initialValues: () => (
        {
            enabled: true,
        }
    ),
    cols:          (props, item, other) => {
        const {me}           = {
            me: {},
            ...other,
        };
        const iSAdminAndSelf = me && item && me.roles && me.roles.includes(ROLES.ROLE_ADMIN)
            && me.guid !== item.guid;
        console.log('iSAdminAndSelf', { iSAdminAndSelf, me, item })

        return [
            {
                title: 'Имя',
                field: 'name',
            },
            {
                title:      'Логин',
                field:      'email',
                helperText: 'Обязательно электронная почта',
                format:     FieldFormat.FORMAT_INPUT_EMAIL,
                // mask:        [/\d/, /\d/, /\d/, /\d/, '+', /\d/, /\d/, /\d/],
            },
            {
                title: 'Аккаунт Телеграм',
                field: 'telegram',
            },
            {
                title:  'Открытый пароль',
                field:  'plain_password',
                hidden: true,
            },
            {
                title:  'Активен',
                field:  'enabled',
                format: iSAdminAndSelf
                            ? FieldFormat.FORMAT_CHECKBOX
                            : FieldFormat.FORMAT_NONE,
                object: ColBoolean,
            },
            {
                title:  'Роли',
                field:  'role',
                format: iSAdminAndSelf || !item
                            ? FieldFormat.FORMAT_SELECT_API
                            : FieldFormat.FORMAT_NONE,
                api:    'user_roles',
                object: ShowApiName,
            },
            {
                title:  'Подразделение',
                field:  'subUnit',
                format: FieldFormat.FORMAT_SELECT_API,
                api:    'sub_units',
                object: ShowApiName,
            },
            {
                title:  'Комментарий',
                field:  'description',
                format: FieldFormat.FORMAT_TEXTAREA,
            },
        ];
    },
};
