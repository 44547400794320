import AdminShow from 'components/FieldFormat/AdminShow';
import React from 'react';
import ErrorBoundary from 'components/ErrorBoundary';
import FormEditCreateTabs from 'components/FormEditCreateTabs';
import Wrapper from 'components/FullScreenWrapper';
import RequestForm from 'components/RequestForm';
import {
    Dialog,
    DialogTitle,
    DialogActions,
} from '@material-ui/core';


const defaultProps = {
    dbId:          'guid',
    onFormDataAction: values => values,
    handleClose:   () => console.log('no handleClose'),
    handleSubmit:  async () => console.log('no handleSubmit'),
    onSuccess:     () => console.error('no onSuccess'),
    canDeleteItem: () => true,
    
    open:          true,
    FormComponent: RequestForm,
    cols:          [],
    maxWidth:      'md',
    fullWidth:     true,
    buttonSave:      'Добавить',
};

export default function DialogEditCreate(props) {
    const {
              open,
              fullScreen,
              title,
              initialValues,
              dbId,
              itemName,
              handleClose,
              handleSubmit,
              onSuccess,
              maxWidth,
              fullWidth,
          } = {...defaultProps, ...props};
    
    const FullScreenWrapper = fullScreen
        ? Wrapper
        : React.Fragment;
    
    const isUpdate = initialValues && initialValues[dbId];
    
    const dialogTitle = title !== undefined
        ? title
        : (
        isUpdate
            ? 'Редактировать'
            : 'Добавить'
    ) + ' ' + itemName;
    

    return (
        <Dialog
            open={open}
            fullScreen={fullScreen}
            onClose={handleClose}
            maxWidth={maxWidth}
            fullWidth={fullWidth}
        >
            <FullScreenWrapper title={fullScreen ? dialogTitle:null} handleClose={handleClose}>
                {!fullScreen && <DialogTitle>{dialogTitle}</DialogTitle>}
                <ErrorBoundary>
                    <AdminShow>[DialogEditCreate]</AdminShow>
                <FormEditCreateTabs
                    {...props}
                    dbId={'id'}
                    itemName={itemName}
                    open={true}
                    handleSubmit={handleSubmit}
                    handleClose={handleClose}
                    onSuccess={onSuccess}
                    initialValues={initialValues}
                    FormComponent={RequestForm}
                    // cols={cols}
                    // fullScreen={fullScreen}
                    // path={path + '/' + guid}
                    tracePath={[...props.tracePath, 'FormEditCreateTabs']}
                />
                </ErrorBoundary>
                <DialogActions />
                
                
            </FullScreenWrapper>
        </Dialog>
    );
}
