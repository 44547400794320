import {
    FavoriteBorder, ListAlt as MenuIcon,

} from '@material-ui/icons';
import {ServiceIcon} from 'components/icons';
import {
    config,
} from 'config';
import address from 'config/address';

import {ROLES} from 'config/roles';

import {
    MenuGroupIcon1,
    MenuGroupIcon2,
    MenuGroupIcon3,
    SyncIcon,
} from 'components/icons';
import PageDocRequests from './PageDocRequests';
import PageIssues from './PageIssues';
import PageIssueComment from './PageIssueComment';

const menuItems = [
    {
        title:     'Заявки',
        icon:      ServiceIcon,
        roles:     [ROLES.ROLE_USER],
        path:      '/issues',
        style:     {
            borderRight:     'solid 3px #8E8',
            backgroundColor: '#88EE8820',
        },
        Component: PageIssues,
    },
    '-',
    {
        title: 'Справочники',
        icon:  MenuGroupIcon3,
        roles: [ROLES.ROLE_USER, ROLES.ROLE_CLIENT],
        path:  '/docs',
        sub:   [
                config.workObject.menu,
                config.workObjectStatus.menu,
                config.workObjectModel.menu,
                config.workObjectType.menu,
                config.material.menu,
                config.road.menu,
                config.subUnit.menu,
                config.transport.menu,
                config.vendor.menu,
                config.customer.menu,
                config.contract.menu,
                config.address.menu,
                config.powerSupply.menu,
        ],
    },
    '-',
    {
        title:   'Управление',
        roles:   [ROLES.ROLE_ADMIN],
        icon:    ServiceIcon,
        subOpen: false,
        style:   {
            borderRight:     'solid 3px #8E8',
            backgroundColor: '#88EE8820',
        },
        sub:     [
            // config.issueStatus.menu,
            // config.tcm.menu,
            config.user.menu,
            // config.userRole.menu,
        ],
    },
];

export default menuItems;