import explainCols from 'components/explainCols';
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import request from 'superagent';
import {STATE} from 'libs/api';


export default function FormRequestSubApiLoader(props) {
    const {apiFilter, item, AfterComponent} = props.itemProps || {};
    const {apiName}                         = item || {};
    const AfterComponentWrapped = explainCols(AfterComponent);
    // console.log('apiName', apiName, props.item.guid, apiFilter, apiFilter(props.item));
    // console.log('FormRequestSubApiLoader', props);
    
    const [state, setState] = React.useState(STATE.INIT);
    const [list, setList]   = React.useState([]);
    
    React.useEffect(() => {
        if (STATE.INIT !== state) {
            return;
        }
        setState(STATE.LOADING);
        request
            .get(`/api/${apiName}`)
            .set('accept', 'application/json')
            .query(apiFilter(props.item))
            .then(response => {
                setList(response.body);
                setState(STATE.LOADED);
            })
            .catch(error => {
                console.error('FormRequestSubApiLoader', error);
                setList(error.message);
                setState(STATE.ERROR);
            });
    }, [state, apiName, apiFilter, props.item]);
    switch (state) {
        case STATE.INIT:
        case STATE.LOADING:
            return <CircularProgress/>;
        case STATE.LOADED:
            return <AfterComponentWrapped {...props} {...item} values={list}/>;
        case STATE.ERROR:
        default:
            return <Alert severity="error">Error: {list} {`/api/${apiName}`}</Alert>;
    }
};