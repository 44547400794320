import {CircularProgress} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import DialogEditCreate3 from 'components/DialogEditCreate3';
import AdminShow from 'components/FieldFormat/AdminShow';
import FormEditCreateTabs from 'components/FormEditCreateTabs';
import {AddBreadCrumb} from 'components/Menu';
import RequestForm from 'components/RequestForm';

import API, {STATE} from 'libs/api';
import detectCols from 'libs/detectCols';

import PropTypes from 'prop-types';
import React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import request from 'superagent';
import {useSelector} from 'react-redux';

const defaultProps = {
    cols:            detectCols,
    initialValues:   () => {},
    onElementUpdate: () => {},
};

function TableApiParamsLoader(props) {
    if (window.tracePath) {
        console.log('tracePath: ', props.tracePath.join('/'));
    }
    
    let {guid, action, sublist} = useParams();
    const [state, setState] = React.useState(guid
        ? STATE.INIT
        : STATE.LOADED);
    
    const {
              apiName, itemName, subApi,
              backLink, path,
              initialValues,
              onElementUpdate,
              fullScreen,
          }       = {...defaultProps, ...props};
    const history = useHistory();
    
    // const item = useSelector(state => state.system[apiName] && state.system[apiName][guid]);
    const [item, setItem] = React.useState(null);
    
    const openDialogEdit = state => () => {
        if (backLink) {
            console.log('use backLink');
            history.push(backLink);
        }
        else if (guid) {
            console.log('use push 1');
            history.push(
                state
                    ? `${path}/${guid}/edit`
                    : `${path}`,
            );
        }
        else {
            console.log('use push 2');
            history.push(`${path}`);
        }
    };
    
    const api = new API();
    
    const handleSubmit = apiName => (values, action) => {
        return api[action](apiName, values);
    };

    React.useEffect(() => {
        if (STATE.INIT !== state) {
            return;
        }
        setState(STATE.LOADING);
        const url = `/api/${apiName}/${guid}`;
        request
            .get(url)
            .set('accept', 'application/json')
            .then(response => {
                console.log('response', response);
                setItem(response.body);
                setState(STATE.LOADED);
            })
            .catch(error => {
                console.log('TableApiParamsLoader', JSON.stringify(error));
                setItem(`${error.response.status} ${error.response.statusText} ${url}`);
                setState(STATE.ERROR);
            });
    }, [state, apiName, props.item]);
    
    const [cols, setColumns] = React.useState([]);

    //const me = React.useContext(MeContext);
    const me        = useSelector(s => s.system && s.system.me && s.system.me.items)|| {};
    React.useMemo(() => {
        if (STATE.LOADED !== state) {
            console.trace('no item',item)
            return;
        }
        let c = 'function' === typeof props.cols
            ? props.cols(props, item, {me})
            : props.cols;
        
        if (c instanceof Promise) {
            c.then(data => setColumns(data));
        }
        else {
            setColumns(c);
        }
    }, [props.cols, item, state]);

    switch (state) {
        case STATE.INIT:
        case STATE.LOADING:
            return (
                <React.Fragment>
                    <AddBreadCrumb label={'Загрузка...'}/>
                    <CircularProgress/>
                    <AdminShow>[TableApiParamsLoader]</AdminShow>
                </React.Fragment>
            );
        
        case STATE.LOADED:
            const label = item
                ? item.name
                : 'Новый';
            
            const showList = !action && !sublist;
            
            return (
                <React.Fragment>
                    <AdminShow>[TableApiParamsLoader]</AdminShow>
                    <AddBreadCrumb label={label}/>
                    {fullScreen && <DialogEditCreate3
                        {...props}
                        dbId={'id'}
                        itemName={itemName}
                        open={true}
                        handleSubmit={handleSubmit(apiName)}
                        handleClose={openDialogEdit(false)}
                        subApi={subApi}
                        tab={sublist}
                        onSuccess={onElementUpdate}
                        initialValues={{...initialValues(props.parent), ...item, ...props.filter}}
                        FormComponent={RequestForm}
                        cols={cols}
                        fullScreen={fullScreen}
                        path={path + '/' + guid}
                        tracePath={[...props.tracePath, 'DialogEditCreate3']}
                    />}
                    {!showList && !fullScreen && <FormEditCreateTabs
                        {...props}
                        dbId={'id'}
                        itemName={itemName}
                        open={true}
                        handleSubmit={handleSubmit(apiName)}
                        handleClose={openDialogEdit(false)}
                        subApi={subApi}
                        tab={sublist}
                        onSuccess={onElementUpdate}
                        initialValues={{...initialValues(props.parent), ...item, ...props.filter}}
                        FormComponent={RequestForm}
                        cols={cols}
                        fullScreen={fullScreen}
                        path={path + '/' + guid}
                        tracePath={[...props.tracePath, 'FormEditCreateTabs']}
                    />}
                
                </React.Fragment>
            );
        case STATE.ERROR:
        default:
            return <Alert severity="error">Error: {item}</Alert>;
    }
}

TableApiParamsLoader.propTypes = {
    apiName: PropTypes.string.isRequired,
};
export default TableApiParamsLoader;

