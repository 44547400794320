import {FormControl, FormGroup, FormLabel} from '@material-ui/core';
import AdminShow from 'components/FieldFormat/AdminShow';
import FormRequestSubApiLoader from 'components/FormRequestSubApiLoader';
import RequestForm from 'components/RequestForm';
import RequestFormDebug, {RequestFormDebugContext} from 'components/RequestFormDebug';
import {SubFormEditCreate} from 'components/SubFormEditCreate';
import {config} from 'config';
import {FormDataContext, FormDataContextConsumer, FormDataContextProvider} from 'FormDataContext';
import React from 'react';

function MyComponent({item, tracePath, subValues, handleChange, onFormDataAction}) {
    const {title}    = item;
    const LoadedData = props => {
        const {values, apiName} = props;
        if (apiName) {
            handleChange(null, values);
        }
        console.log('subValues LoadedData', values);
        
        return <React.Fragment>
            <AdminShow>[FormDataContextProvider]</AdminShow>
            <FormDataContextProvider initial={values} onFormDataAction={onFormDataAction}>
                <FormDataContextConsumer>{context => {
                    console.log('handleChange subValues FormDataContextConsumer', context);
                    if (JSON.stringify(values) !== JSON.stringify(context.state.values)) {
                        handleChange(null, context.state.values);
                    }
                }}</FormDataContextConsumer>
                <SubFormEditCreate
                    dbId={'guid'}
                    // itemName={'itemName'}
                    open={true}
                    // returnOnly={true}
                    // handleSubmit={handleReadOnlySubmit}
                    // handleClose={() =>setOpenDialog(false)}
                    // onChange={onChange}
                    // subFormHandleChange={handleChange(item)}
                    // subFormHandleChange={subFormHandleChange}
                    // onSuccess={() => {}}
                    // values={values[field]}
                    // initialValues={initialValues}
                    {...config.get(item.subConfig)}
                    FormComponent={RequestForm}
                    tracePath={[...tracePath, 'SubFormEditCreate']}
                />
                <RequestFormDebugContext />
            </FormDataContextProvider>
        </React.Fragment>
            ;
        
    };
    
    let itemProps = typeof subValues == 'string'
        ? {
            AfterComponent: LoadedData,
            apiFilter:      () => {},
            item:           {
                apiName: subValues.replace('/api/', ''),
            },
        }
        : null;
    
    return <React.Fragment>
        <AdminShow>[SubForm]</AdminShow>
        <FormControl component="fieldset" variant={'outlined'} fullWidth>
            <FormLabel component="legend">{title}</FormLabel>
            <FormGroup>
                {itemProps
                    ? <FormRequestSubApiLoader itemProps={itemProps}/>
                    : <LoadedData values={subValues}/>}
            </FormGroup>
        </FormControl>
    </React.Fragment>;
}

function areEqual(prevProps, nextProps) {
    return prevProps.value === nextProps.value;
}

const MyComponentMemo = React.memo(MyComponent, areEqual);

function MyComponentMemoUse(props) {
    const {field}                                 = props.item;
    const {state: {values, errors}, handleChange} = React.useContext(FormDataContext);
    
    const {onFormDataAction} = {
        onFormDataAction: v => v,
        ...config.get(props.item.subConfig),
    };
    
    const handleChangeLocal = (e, values) => {
        
        if (values) {
            return handleChange(props.item)(e, onFormDataAction(values, {action: 'subform'}));
        }
        else {
            return handleChange(props.item)(e, values);
        }
    };
    
    const subValues = values && values[field];
    const error     = errors && errors[field];
    
    return <MyComponentMemo
        values={values}
        subValues={subValues || ''}
        error={error || null}
        handleChange={handleChangeLocal}
        onFormDataAction={onFormDataAction}
        {...props}
    />;
}

export default MyComponentMemoUse;