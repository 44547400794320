import { Checkbox, Chip, Tooltip, Avatar, Paper, withStyles, ButtonBase, Dialog } from '@material-ui/core'

import {
    BankAccountIcon,
    BankIcon,
    ClientProfilesIcon,
    CurrencyIcon,
    DocRequestIcon,
    IndividualIcon,
    OrganizationsIcon,
} from 'components/icons';
import {config} from 'config';
import {STATUS} from 'config/issues';
import asId from 'libs/asId';

import moment from 'moment';
import 'moment/locale/ru';
import React from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {Link, useRouteMatch} from 'react-router-dom';

import request from 'superagent';
import system from '../actions/system';
import Grid from '@material-ui/core/Grid'
import DescriptionIcon from "@material-ui/icons/Description";

/**
 * @return {string}
 */
export function ColBoolean({item}) {
    return <Checkbox
        checked={Boolean(item)}
        color={'primary'}
    />;
}

const NoItem     = '---';
const DateFormat = 'DD.MM.YYYY';
const DateTimeFormat = 'HH:mm DD.MM.YYYY';

// const DateTimeFormat = 'YYYYMMDD';


/**
 * @return {string}
 */
export function ShowDate({item}) {
    const last = moment(item);
    return last.format(DateFormat);
}
export function ShowDateTime({item}) {
    const last = moment(item);
    return last.format(DateTimeFormat);
}

export function ShowDateTimeFromNow({item}) {
    if (!item) {
        return '---';
    }
    const last = moment(item);
    return last.isBefore()
            ? (
                <Tooltip placement="bottom-start" title={last.format(DateTimeFormat)}>
                    <div>{last.fromNow()}</div>
                </Tooltip>
            )
            : last.format(DateTimeFormat);
}

export function ShowDateTimeIssueClosed({row,item}) {
    if (asId(row.status) !== STATUS.CLOSED) {
        return '---';
    }
    const last = moment(item);
    return last.isBefore()
            ? (
                <Tooltip placement="bottom-start" title={last.format(DateTimeFormat)}>
                    <div>{last.fromNow()}</div>
                </Tooltip>
            )
            : last.format(DateTimeFormat);
}

export function showBank({row, item}) {
    const o = typeof item == 'object'
        ? item
        : row;
    return o && o.name
        ? <Chip
            variant={'outlined'}
            to={`/banks/${o.guid}`}
            icon={<BankIcon/>}
            color={'primary'}
            label={o.name}
        />
        : NoItem;
}

export function ShowCurrency({row, item}) {
    const o = typeof item == 'object'
        ? item
        : row;
    return o && o.name
        ? <Chip
            variant={'outlined'}
            color={'primary'}
            to={`/currencies/${o.guid}`}
            icon={<CurrencyIcon/>}
            label={o.name}
        />

        : NoItem;
}

export function ShowBankAccount({row, item}) {
    const o = typeof item == 'object'
        ? item
        : row;
    return o && o.name
        ? <Chip
            component={Link}
            icon={<BankAccountIcon/>}
            label={o.name}
            to={`/bank_accounts/${o.guid}`}
            variant={'outlined'}
            clickable
        />
        : NoItem;
}

export function ColContactInfo({item}) {
    return item
        ? <div>{item.name}:<br/> <b>{item.info}</b></div>
        : '---';
}

export function RowContactInfo({item}) {
    return item
        ? <div>{item.name}:<br/> <b>{item.info}</b></div>
        : '---';
}

function showName(item) {
    return item.isDeleted
        ? <strike>{item.name}</strike>
        : item.name;
}

export function ColObjectName({item}) {
    return item && item.name
        ? showName(item)
        : '---';
}

export function ColRoadName({item}) {
    return item && item.road
        ? `Дорога ${item.road}`
        : '---';
}

export function ColObjectFullName({item}) {
    return item && item.full_name
        ? item.full_name
        : '---';
}

export function ColContactPerson({item}) {
    return <div><b>{item.workPosition}:</b><br/> {item.name}</div>;
}

export function ShowContactPerson({item}) {
    // let to = `/contact_person/${item.guid}`;
    return item && item.name
        ? (
            <Tooltip title={<React.Fragment>
                <div>{item.workPosition}</div>
                <div>{item.address}</div>
            </React.Fragment>}
            >
                <Chip
                    icon={<IndividualIcon/>}
                    label={item.name}
                    // to={to}
                    // component={Link}
                    // clickable
                    color={'primary'}
                    variant={'outlined'}
                />
            </Tooltip>
        )
        : '---';
}

export function ShowUser({item}) {
    return item && item.name
        ? item.name
        : '---';
}

export function ShowIndividual({item}) {
    // let to      = canView ? `/individual/${item.guid}` : '';
    return item && item.name
        ? (
            <Tooltip title={<React.Fragment>
                <div>{item.fioFull || 'Не заполнено'}</div>
                <div>{item.address}</div>
            </React.Fragment>}>
                <Chip
                    icon={<IndividualIcon/>}
                    label={item.name}
                    // to={to}
                    // component={Link}
                    // clickable
                    color={'primary'}
                    variant={'outlined'}
                />
            </Tooltip>

        )
        : '---';
}

export function ShowClient({row, item}) {
    const o = typeof item == 'object'
        ? item
        : row;
    return o && o.name
        ? (
            <Tooltip title={<React.Fragment>
                <div>{o.name}</div>
            </React.Fragment>}>
                <Link to={`/client_profiles/${o.guid}`}>
                    <ClientProfilesIcon/> {o.name}
                </Link>
            </Tooltip>
        )
        : '---';
}

export function ShowDocRequest({row, item}) {
    const o = typeof item == 'object'
        ? item
        : row;
    return o && o.name
        ? (
            <Tooltip title={<React.Fragment>
                <div>{o.name}</div>
            </React.Fragment>}>
                <Link to={`/doc_requests/${o.guid}`}>
                    <DocRequestIcon/> {o.name}
                </Link>
            </Tooltip>
        )
        : '---';
}

export function ShowApiName({row, item}) {
    const o = typeof item == 'object'
        ? item
        : row;
    return o && o.name
        ? (
            <div>
                <Tooltip title={<React.Fragment>
                    <div>{showName(o)}</div>
                    {o.id && <div>id: {o.id}</div>}
                </React.Fragment>}>
                    <span style={{borderBottom: 'dashed 1px'}}>{showName(o)}</span>
                </Tooltip>
            </div>
        )
        : '---';
}

export function bytesToSize(bytes) {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes == 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

/**
 * {
    "id": 3,
    "user": "/api/users/a611232b-84ae-4e0e-a13f-1ae8a5b5fbf2",
    "issueComment": "/api/issue_comments/340",
    "upload_at": "2022-07-12T04:26:15+00:00",
    "fileType": "IssueComment",
    "filename": "photos/file_7.jpg",
    "path": "/62ccf7e713de1.jpg",
    "size": 52921,
    "isDeleted": false,
    "uploadAt": "2022-07-12T04:26:15+00:00"
}
 * @param item
 * @returns {JSX.Element}
 * @constructor
 */

// https://stackoverflow.com/questions/38049966/get-image-preview-before-uploading-in-react
export function ShowFile ({ item }) {
  console.log('ShowFile', item)

  const PaperStyled = withStyles((theme) => (
    {
      root: {
        maxWidth:           "200px",
        padding: theme.spacing(2),
      },
    }
  ))(Paper);

  const imageTypes  = [
      'image/jpeg', "IssueComment",
  ];
    const [show, setShow] = React.useState(false)
    return imageTypes.includes(item.fileType)
        ? <PaperStyled>
            <Dialog open={show} onClose={() => setShow(false)}>
                <img alt="attach" style={{maxWidth: '100%', maxHeight: '100%'}} src={`/upload${item.path}`}/>
            </Dialog>
            <Grid container spacing={2}>
                <Grid item>
                    <ButtonBase onClick={() => setShow(true)}>
                        <img alt="attach" style={{maxWidth: '100%', maxHeight: '100%'}} src={`/upload${item.path}`}/>
                    </ButtonBase>
                </Grid>
                <Grid item>
                    Размер: {bytesToSize(item.size)}
                </Grid>
            </Grid>
        </PaperStyled>
        :
        <div>
            <a target={'_blank'} href={`/api/file/get/${item.id}`}> <DescriptionIcon/> {item.filename}</a> (Размер: {bytesToSize(item.size)})
        </div>
}

export function ShowDangerClass({row, item}) {
    const o = typeof item == 'object'
        ? item
        : row;
    return o && o.name
        ? (
            <Tooltip placement="right" title={<React.Fragment>
                <div>usage:{o.usage}</div>
                <div>description:{o.description}</div>
            </React.Fragment>}>
                <span style={{borderBottom: 'dashed 1px'}}>{o.name}</span>
            </Tooltip>
        )
        : '---';
}

export function ShowApiLink(apiName) {
    return ({row, item}) => {
        const o = typeof item == 'object'
            ? item
            : row;
        return o && o.name
            ? (
                <Tooltip title={<React.Fragment>
                    <div>{o.name}</div>
                </React.Fragment>}>
                    <Link to={`/${apiName}/${o.guid}`}>
                        {o.name}
                    </Link>
                </Tooltip>
            )
            : '---';
    };
}

/**
 * load object for column
 * @param apiName
 * @param guid
 * @param object
 * @returns {JSX.Element}
 * @constructor
 */
export function RealApiObjectLoad({apiName, guid, object}) {
    const decodeGuid = guid
        ? decodeURI(guid)
        : null;
    const dispatch   = useDispatch();
    const item       = useSelector(state => state.system[apiName] && state.system[apiName][decodeGuid]);
    React.useEffect(() => {
        if (!item && decodeGuid) {
            dispatch(system.fetchOne(config.apiPrefix + apiName + '/' + decodeGuid));
        }
    }, [item, apiName, decodeGuid, dispatch]);
    // return <pre>{JSON.stringify(item)}</pre>;
    const Show = object;
    return item && Show
        ? <Show item={item}/>
        : <pre>load={decodeGuid}</pre>;
    // return item
    //        ? <Object item={item}/>
    //        : guid;
}

/*
const fetch = React.useMemo(
    () =>
        debounce((query, callback) => {
            ( async () => {
                setLoading(true);
                const response = await request.post(`/api/${api}`).
                    set('accept', 'application/json').
                    send({...query, ...apiFilter});

                callback(response.body.suggestions.map(suggest => (
                    {
                        suggest: suggest,
                        name: suggest.value,
                    }
                )));
                setLoading(false);
            })()
        }, 400),
    [],
);
*/

export const ApiDataLoad = (apiURI) => {
    // const fetch = React.useMemo(  async (apiURI) =>{
    const fetch = async () => {
        if (!apiURI) {
            return;
        }
        let result = (
            await request.get(apiURI).set('accept', 'application/json').query({})
        ).body;
        // console.log('ApiDataLoad result', result);
        return result;
        // },[apiURI]);
    };

    return fetch();
    // fetch(apiURI);
};

export const ApiDataLoadLookup = (apiURI, key = 'guid', value = 'name') => {
    return ApiDataLoad(apiURI)
        .then(
            data => {
                let result     = {};
                let resultData = [];
                data.forEach((i, n) => {
                    result[i[key]] = value
                        ? i[value]
                        : i;
                    resultData[n]  = i;
                });
                return key
                    ? result
                    : resultData;
            },
            error => error,
        );

    // console.log('ApiDataLoadLookup', result, resultData);
};

export const ApiObjectLoad = (apiName, object) => {
    return ({item}) => {
        return <RealApiObjectLoad
            apiName={apiName}
            guid={item}
            object={object}
        />;
    };
};

export const ApiObjectLoadForMTable = (apiName, object, {item}) => {
    if (!item) {
        return '';
    }
    const match = item && typeof item == 'string'
        ? item.match(/\/api\/(.+)\/(.+)/)
        : [];

    let guid = match
        ? decodeURI(match[2])
        : null;

    return <RealApiObjectLoad
        apiName={apiName}
        guid={guid}
        object={object}
    />;

};