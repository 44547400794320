// These must be the first lines in src/index.js
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

// import * as serviceWorker from './serviceWorker';

import {Provider} from 'react-redux';
import store from 'reducers/store';

import App from 'App';
import MeLoader from 'MeLoader';
window.tracePath = true;

ReactDOM.render(
        <Provider store={store}>
            <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"/>
            <MeLoader App={App} />
        </Provider>
        ,
        document.getElementById('root'),
);
