import ActionConfirmDialog from 'components/ActionConfirmDialog';
import ActionWorkDoneDialog from 'components/ActionWorkDone';
import issueExecutors from 'config/issueExecutors';
import ActionWorkCloseDialog from 'components/ActionWorkClose';
import ActionWorkDialog from 'components/ActionWork';
import React from 'react';
import {ServiceIcon} from 'components/icons';
import issueComment from 'config/issueComment';
import issueMaterials from 'config/issueMaterials';
import {ROLES} from 'config/roles';
import PageIssues from 'page/PageIssues';
import {FieldFormat} from './FieldFormat';
import {
    ApiObjectLoadForMTable, ColBoolean, RealApiObjectLoad,
    ShowApiName,
    ShowDateTimeFromNow, ShowDateTimeIssueClosed, ShowFile,
    ShowUser,
} from 'components/showItems'
import {
    Grid,
    Paper,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell, Chip,
} from '@material-ui/core';
import asGUID from 'libs/asGUID';
import asId from 'libs/asId';

export const STATUS = {
    NEW:     1, // Новая
    IN_WORK: 2, // в работе
    DONE:    3, // Работы выполнены
    CLOSED:  4, // Закрыта
};

const ROLE = {
    ADMIN:       'admin',
    RESPONSIBLE: 'responsible',
    EXECUTOR:    'executor',
    AUTHOR:      'author',
};

export function detectRenderField(cols, field, rowData) {
    let col = cols.find(i => i.field === field);
    if (!col) {
        return '-' + field;
    }
    if (col.api && col.object) {
        return <div>
            {ApiObjectLoadForMTable(col.api, col.object, {
                item:   rowData[col.field],
                row:    rowData,
                column: col.field,
                // multiple: col.multiple, todo: multiple support
            })}
        </div>;
    }
    else if (col.object) {
        return <div>
            <col.object
                item={rowData[col.field]}
                row={rowData}
                column={col.field}
            />
        </div>;
        
    }
    else {
        let value = rowData[col.field];
        if ('object' === typeof value) {
            return JSON.stringify(value);
        }
        return <div>
            {rowData[col.field]}
        </div>;
        
    }
}

export function isAdmin(me) {
    if (!me || !me.roles || !Array.isArray(me.roles)) {
        return false;
    }
    return me.roles.includes(ROLES.ROLE_ADMIN)
        ? ROLE.ADMIN
        : null;
}

export function isExecutor(me, item) {
    if (!me || !me.guid || !item || !Array.isArray(item.executorGuid)) {
        return null;
    }
    return item.executorGuid.includes(me.guid)
        ? ROLE.EXECUTOR
        : null;
}

export function isResponsible(me, item) {
    if (!me || !me.guid || !item || !item.responsibleFromSubUnit) {
        return null;
    }
    return me.guid === asGUID(item.responsibleFromSubUnit)
        ? ROLE.RESPONSIBLE
        : null;
}

export function isAuthor(me, item) {
    if (!me || !me.guid || !item || !item.author) {
        return null;
    }
    return me.guid === asGUID(item.author)
        ? ROLE.AUTHOR
        : null;
}

const canEdit = (props) => {
    const {item, me} = props;
    console.log('canEdit', props,
        {item, me, isAdmin: isAdmin(me), isResponsible: isResponsible(me, item), isExecutor: isExecutor(me, item)});
    /** Изменение данных заявки (“Карандаш”)
     - | Текущий Статус   |  Автор | Руководитель | Исполнитель | Админ |
     1 | Новая            | +      | -            | -           | +     |
     2 | В работе         | -      | -            | -           | +     |
     3 | Работы выполнены | -      | -            | -           | +     |
     4 | Закрыта          | -      | -            | -           | +     |
     */
    
    // Убрать возможность редактирования текста Заявки, после её создания, у всех, кроме администратора.
    
    // Убрать у исполнителя возможность добавление материалов и комментариев к заявке
    // в статусе “Работы выполнены”.
    
    switch (asId(item.status)) {
        case STATUS.NEW:
            return isAuthor(me, item) || isAdmin(me);
        case STATUS.IN_WORK:
            return isAdmin(me);
        case STATUS.DONE:
            return isAdmin(me);
        case STATUS.CLOSED:
            return isAdmin(me);
        default:
            return false;
    }
};

export default {
    listTitle:        'Заявки',
    apiName:          'issues',
    itemName:         'заявку',
    canAdd:           true,
    canEdit:          canEdit,
    canDelete:        (props) => {
        const {item, me} = props;
        console.log('canDelete', props,
            {item, me, isAdmin: isAdmin(me), isResponsible: isResponsible(me, item), isExecutor: isExecutor(me, item)});
        return isAdmin(me);
    },
    roles:            [
        ROLES.ROLE_USER,
    ],
    menu:             {
        title:     'Заявки',
        path:      '/issues',
        Component: PageIssues,
        roles:     [ROLES.ROLE_USER],
        icon:      ServiceIcon,
    },
    initialValues:    () => ({
        status: 1,
    }),
    onFormDataAction: (values, props) => {
        const {old, action, value, field, event} = props;
        switch (action) {
            case 'change':
                if (field === 'workObject') {
                    values.responsibleFromSubUnit = event.target.item
                        ? event.target.item.responsibleFromSubUnit
                        : null;
                }
                if (['road', 'modelType'].includes(field)) {
                    values.workObject = null;
                }
                break;
        }
        console.trace('props', values, props);
        return values;
    },
    //subUnitResponsible
    cardActions:      ({item, me}) => {
        const workFlow = {
            [STATUS.NEW]:     {
                [STATUS.CLOSED]: [ROLE.RESPONSIBLE, ROLE.ADMIN],
            },
            [STATUS.IN_WORK]: {
                [STATUS.DONE]:   [ROLE.EXECUTOR],
                [STATUS.CLOSED]: [ROLE.RESPONSIBLE, ROLE.ADMIN],
            },
            [STATUS.DONE]:    {
                [STATUS.IN_WORK]: [ROLE.RESPONSIBLE, ROLE.ADMIN],
                [STATUS.CLOSED]:  [ROLE.RESPONSIBLE, ROLE.ADMIN],
            },
            [STATUS.CLOSED]:  {
                [STATUS.IN_WORK]: [ROLE.RESPONSIBLE, ROLE.ADMIN],
            },
        };
        if (!me || !me.roles || !item) {
            return [];
        }
        console.log('me.roles', me.roles);
        const status = asId(item.status);

        // если исполнитель в заявке со статусом «в работе»
        // нажимает кнопку «закрыть» то она получает статус «Работы выполнены».
        // const userCanDone = (item.executorGuid || []).includes(me.guid) && status === STATUS.IN_WORK
        //     // Если Ответственный = исполнитель - кнопка Закрыть заявку
        //     && !((item.executorGuid || []).includes(me.guid))
        //     || (me.roles.includes(ROLES.ROLE_ADMIN) && status === STATUS.IN_WORK);
        const userIssueRoles = [isAdmin(me), isExecutor(me, item), isResponsible(me, item)].filter(v => v);
        const canInWorkRules = workFlow[status] && workFlow[status][STATUS.IN_WORK] || [];
        const canDoneRules   = workFlow[status] && workFlow[status][STATUS.DONE] || [];
        const canCloseRules  = workFlow[status] && workFlow[status][STATUS.CLOSED] || [];
    
        const userCanInWork = userIssueRoles.filter(role => canInWorkRules.includes(role)).length;
        const userCanDone   = userIssueRoles.filter(role => canDoneRules.includes(role)).length;
        const userCanClose  = userIssueRoles.filter(role => canCloseRules.includes(role)).length;
    
        console.log(
            'cardActions',
            {status, userIssueRoles, userCanInWork, userCanDone, userCanClose, executorGuid: item.executorGuid, me},
        );
        
        // если ответственный в заявке со статусом «в работе»
        // нажимает кнопку «закрыть» то «Закрыта».
        // const userCanClose = (me.guid === asGUID(item.responsibleFromSubUnit) && status === STATUS.IN_WORK)
        //     // кнопка «Закрыть» - доступна на статусах «Новая» и «В работе» для администратора,  ответственного и исполнителя.
        //     || (
        //         (
        //             me.guid === asGUID(item.responsibleFromSubUnit)
        //             || me.roles.includes(ROLES.ROLE_ADMIN)
        //             || (item.executorGuid || []).includes(me.guid)
        //         )
        //         && [STATUS.NEW, STATUS.IN_WORK].includes(status)
        //     );
        // Администратор и ответственный имею право вернуть статус заявки
        // со статуса «Закрыта» и «Работы выполнены» на «В работе»
        // const userCanInWork = (me.guid === asGUID(item.responsibleFromSubUnit) || me.roles.includes(ROLES.ROLE_ADMIN))
        //     && [STATUS.DONE, STATUS.CLOSED].includes(status);


        return [
            userCanDone && {
                Component: ActionWorkDoneDialog,
                title:     'Работы выполнены',
                values:    {...item, status: STATUS.DONE},
            },
            
            userCanClose && {
                Component: ActionWorkCloseDialog,
                title:     'Закрыть заявку',
                values:    {...item, status: STATUS.CLOSED},
            },
            
            userCanInWork && {
                Component: ActionWorkDialog,
                title:     'В работу',
                values:    {...item, status: STATUS.IN_WORK, overtime: false},
            },
        ];
    },
    cols:             [
        {
            title:    'Тип',
            field:    'modelType',
            cols:     3,
            api:      'work_object_types',
            format:   FieldFormat.FORMAT_SELECT_API,
            object:   ShowApiName,
            filter:   {
                format: FieldFormat.FORMAT_SELECT_API_CHECKBOX,
                field:  'workObject.type[]',
            },
            required: true,
        },
        {
            title:    'Дорога',
            field:    'road',
            format:   FieldFormat.FORMAT_SELECT_API,
            object:   ShowApiName,
            api:      'roads',
            cols:     3 / 2,
            filter:   {
                format: FieldFormat.FORMAT_SELECT_API_CHECKBOX,
                field:  'workObject.address.road[]',
            },
            required: true,
            // hidden: true,
        },
        // {
        //     title: 'Дорога',
        //     field: 'addressName',
        //     cols:  4,
        //     format: FieldFormat.FORMAT_NONE,
        // },
        {
            title:  'Отметка',
            field:  'mark',
            cols:   4,
            format: FieldFormat.FORMAT_NONE,
        },
        {
            title:      'Объект',
            field:      'workObject',
            format:     FieldFormat.FORMAT_SELECT_API,
            api:        'work_objects',
            helperText: 'Список, только после выбора типа и дороги',
            apiFilter:  values => (
                {
                    'type':         values.modelType || -1,
                    'address.road': values.road || -1,
                }
            ),
            object:     ShowApiName,
            cols:       1,
            hidden:     true,
            required:   true,
        },
        {
            title:    'Причина',
            field:    'name',
            cols:     1,
            required: true,
        },
        {
            title:  'Дата создания',
            field:  'createdAt',
            format: FieldFormat.FORMAT_NONE,
            object: ShowDateTimeFromNow,
            cols:   4,
        },
        // {
        //     title:  'Ответственный',
        //     field:  'responsible',
        //     format: FieldFormat.FORMAT_SELECT_API,
        //     api:    'users',
        //     object: ShowUser,
        //     cols:   3,
        //     required: true,
        // },
        {
            title:    'Ответственный',
            field:    'responsibleFromSubUnit',
            format:   FieldFormat.FORMAT_SELECT_API,
            api:      'users',
            object:   ShowUser,
            disabled: true,
            cols:     3,
            // required: true,
        },
        {
            title:    'Исправить до',
            field:    'fixBefore',
            format:   FieldFormat.FORMAT_DATETIME_LOCAL,
            object:   ShowDateTimeFromNow,
            cols:     3,
            required: true,
        },
        {
            title:    'Автор',
            field:    'author',
            format:   FieldFormat.FORMAT_NONE,
            api:      'users',
            object:   ShowUser,
            cols:     3,
            disabled: true,
        },
        {
            title:    'Статус',
            field:    'status',
            format:   FieldFormat.FORMAT_SELECT_API,
            api:      'issue_statuses',
            object:   ShowApiName,
            cols:     3,
            required: true,
            disabled: true,
            filter:   {
                format: FieldFormat.FORMAT_SELECT_API_CHECKBOX,
                field:  'status[]',
            },
        },
        {
            title:  'Транспорт',
            field:  'transport',
            format: FieldFormat.FORMAT_SELECT_API,
            api:    'transports',
            object: ShowApiName,
            cols:   3,
            hidden: true,
        },
        {
            title:  'Дата закрытия заявки',
            field:  'closedAt',
            format: FieldFormat.FORMAT_NONE,
            object: ShowDateTimeIssueClosed,
        },
        // {
        //     title:  'Сверхурочно',
        //     field:  'overtime',
        //     format: FieldFormat.FORMAT_NONE,
        //     object: ColBoolean,
        // },
        // {
        //     title:  'Отметка С',
        //     field:  'markFrom',
        //     cols:   3,
        //     hidden: true,
        // },
        // {
        //     title:  'Отметка До',
        //     field:  'markTo',
        //     cols:   3,
        //     hidden: true,
        // },
        // {
        //     title:  'GPS1',
        //     field:  'gps1',
        //     cols:   3,
        //     hidden: true,
        // },
        // {
        //     title:  'GPS2',
        //     field:  'gps2',
        //     cols:   3,
        //     hidden: true,
        // },
    ],
    subApi:           [
        {
            xs:          6,
            title:       'Расходные материалы',
            item:        issueMaterials,
            parentField: 'issue',
            apiFilter:   item => (
                {
                    issue: item.id,
                }
            ),
        },
        {
            xs:          6,
            title:       'Исполнитель',
            item:        issueExecutors,
            parentField: 'issue',
            apiFilter:   item => (
                {
                    issue: item.id,
                }
            ),
        },
        {
            LoadedData:  props => {
                const {values, cols} = props;

                return <TableContainer component={Paper}>
                    {/*<pre>{JSON.stringify(props,null,2)}</pre>*/}
                    <Table size="small" aria-label="a dense table">
                        <TableBody>
                            {values.map((i, key) => <React.Fragment key={key}>
                                <TableRow>
                                    <TableCell>
                                        <Grid container spacing={1} alignItems={'center'}>
                                            <Grid item>
                                                <Chip label={detectRenderField(cols, 'author', i)}/>
                                            </Grid>
                                            <Grid item spacing={4}>
                                                {detectRenderField(cols, 'createdAt', i)}
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        {detectRenderField(cols, 'text', i)}
                                        {i.issueCommentFiles.map(apiFile => {
                                            return <RealApiObjectLoad
                                              apiName={'issue_comment_files'}
                                              guid={asId(apiFile)}
                                              object={ShowFile}
                                            />
                                        })}
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>)}
                        </TableBody>
                    </Table>
                </TableContainer>;

            },
            title:       'История сообщений',
            item:        issueComment,
            parentField: 'issue',
            apiFilter:   item => (
                {
                    issue: item.id,
                }
            ),
        },
    ],
};

