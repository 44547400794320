import {ServiceIcon} from 'components/icons';
import {ColObjectName, ShowUser} from 'components/showItems';
import {ROLES} from 'config/roles';
import {FieldFormat} from 'config/FieldFormat';
import PageService from 'page/PageService';
import PageWorkObjectModels from 'page/PageWorkObjectModels';

export default {
    listTitle: 'Модели объектов',
    apiName:   'work_object_models',
    itemName:  'модель объекта',
    canDelete: true,
    roles:     [
        ROLES.ROLE_USER,
    ],
    menu:      {
        title:     'Модели объектов',
        path:      '/work_object_models',
        Component: PageWorkObjectModels,
        roles:     [ROLES.ROLE_USER],
        icon:      ServiceIcon,
    },
    cols:      [
        {
            title: 'Наименование',
            field: 'name',
            cols: 3,
        },
        {
            title:  'Тип объекта',
            field:  'type',
            format: FieldFormat.FORMAT_SELECT_API,
            api:    'work_object_types',
            object: ColObjectName,
            cols: 3,
        },
        {
            title:  'Согласующий закрытие',
            field:  'closeConfirm',
            format: FieldFormat.FORMAT_SELECT_API,
            api:    'users',
            object: ShowUser,
            cols: 3,
        },
        {
            title: 'Протяженность',
            field: 'routeLength',
            cols: 2,
        },
        {
            title:  'Производитель',
            field:  'vendor',
            format: FieldFormat.FORMAT_SELECT_API,
            api:    'vendors',
            object: ColObjectName,
            cols: 2,
        },
        {
            title:  'Комментарий',
            field:  'description',
            format: FieldFormat.FORMAT_TEXTAREA,
        },
    ],
};
