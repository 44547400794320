import {AppBar, Button, Container, CssBaseline, IconButton, Toolbar, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Close as CloseIcon} from '@material-ui/icons';
import React from 'react';

const useStyles = makeStyles((theme) => (
    {
        appBar: {
            position: 'relative',
        },
        title:  {
            marginLeft: theme.spacing(2),
            flex:       1,
        },
    }
));

export default function Wrapper({children, handleClose, title}) {
    const classes = useStyles();
    return <React.Fragment>
        <AppBar className={classes.appBar}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose}
                            aria-label="close">
                    <CloseIcon/>
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    {title}
                </Typography>
                <Button autoFocus color="inherit" onClick={handleClose}>
                    Отмена
                </Button>
            </Toolbar>
        </AppBar>
        <br/>
        <CssBaseline/>
        <Container maxWidth={false}>
            {children}
        </Container>
    </React.Fragment>;
}
