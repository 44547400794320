import {ServiceIcon} from 'components/icons';
import issueComment from 'config/issueComment';
import {isAdmin, isExecutor, isResponsible, STATUS} from 'config/issues';
import {ROLES} from 'config/roles';
import asGUID from 'libs/asGUID';
import asId from 'libs/asId';
import PageIssueMaterials from 'page/PageIssueMaterials';
import PageService from 'page/PageService';
import PageIssues from 'page/PageIssues';
import {FieldFormat} from './FieldFormat';
import {ShowApiName, ShowDate, ShowDateTime, ShowDateTimeFromNow, ShowUser} from 'components/showItems';

export default {
    listTitle: 'Материалы заявки',
    apiName:   'issue_materials',
    itemName:  'материал заявки',
    canAdd:    (props) => {
        /** Кнопка “Добавить материалы”
         | Текущий Статус   |  Автор | Руководитель | Исполнитель | Админ |
         1 | Новая            | -      | -            | -           | -     |
         2 | В работе         | -      | +            | +           | +     |
         3 | Работы выполнены | -      | +            | -           | +     |
         4 | Закрыта          | -      | -            | -           | +     |
         */
        const {item, parent, me} = props;
        console.log('canAdd', props,{parent, me, isAdmin: isAdmin(me), isResponsible: isResponsible(me, parent), isExecutor: isExecutor(me, parent)})
        if (!me) {
            return false;
        }
        
        switch (asId(parent.status)) {
            case STATUS.NEW:
                return false;
            case STATUS.IN_WORK:
                return isResponsible(me, parent) || isExecutor(me, parent) || isAdmin(me);
            case STATUS.DONE:
                return isResponsible(me, parent) || isAdmin(me);
            case STATUS.CLOSED:
                return isAdmin(me);
            default:
                return false;
        }
    },
    canEdit:   (props) => {
        /** Кнопка “Добавить материалы”
         | Текущий Статус   |  Автор | Руководитель | Исполнитель | Админ |
         1 | Новая            | -      | -            | -           | -     |
         2 | В работе         | -      | +            | +           | +     |
         3 | Работы выполнены | -      | +            | -           | +     |
         4 | Закрыта          | -      | -            | -           | +     |
         */
        const {item, parent, me} = props;
        console.log('canAdd', props,{parent, me, isAdmin: isAdmin(me), isResponsible: isResponsible(me, parent), isExecutor: isExecutor(me, parent)})
        if (!me) {
            return false;
        }
    
        switch (asId(parent.status)) {
            case STATUS.NEW:
                return false;
            case STATUS.IN_WORK:
                return isResponsible(me, parent) || isExecutor(me, parent) || isAdmin(me);
            case STATUS.DONE:
                return isResponsible(me, parent) || isAdmin(me);
            case STATUS.CLOSED:
                return isAdmin(me);
            default:
                return false;
        }
    },
    canDelete: (props) => {
        /** Кнопка “Добавить материалы”
         | Текущий Статус   |  Автор | Руководитель | Исполнитель | Админ |
         1 | Новая            | -      | -            | -           | -     |
         2 | В работе         | -      | +            | +           | +     |
         3 | Работы выполнены | -      | +            | -           | +     |
         4 | Закрыта          | -      | -            | -           | +     |
         */
        const {item, parent, me} = props;
        console.log('canAdd', props,{parent, me, isAdmin: isAdmin(me), isResponsible: isResponsible(me, parent), isExecutor: isExecutor(me, parent)})
        if (!me) {
            return false;
        }
    
        switch (asId(parent.status)) {
            case STATUS.NEW:
                return false;
            case STATUS.IN_WORK:
                return isResponsible(me, parent) || isExecutor(me, parent) || isAdmin(me);
            case STATUS.DONE:
                return isResponsible(me, parent) || isAdmin(me);
            case STATUS.CLOSED:
                return isAdmin(me);
            default:
                return false;
        }
    },
    roles:     [
        ROLES.ROLE_USER,
    ],
    menu:      {
        title:     'Материалы заявки',
        path:      '/issue_materials',
        Component: PageIssueMaterials,
        roles:     [ROLES.ROLE_USER],
        icon:      ServiceIcon,
    },
    cols:      [
        // {
        //     title: 'Заявка',
        //     field: 'issue',
        //     format: FieldFormat.FORMAT_NONE,
        //     api: 'issues',
        //     object: ShowApiName,
        //     cols:  3,
        // },
        {
            title:  'Материал',
            field:  'material',
            format: FieldFormat.FORMAT_SELECT_API,
            api:    'materials',
            object: ShowApiName,
            cols:   3,
        },
        {
            title:  'Количество',
            field:  'count',
            cols:   3,
        },
        {
            title:  'Комментарий',
            field:  'description',
            format: FieldFormat.FORMAT_TEXTAREA,
        },
    ],
};
