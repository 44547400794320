import AdminShow from 'components/FieldFormat/AdminShow';
import React from 'react';
import DescriptionIcon from '@material-ui/icons/Description';
import {
    Box,
    Avatar,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Button,
    Slider, withStyles, Paper,

} from '@material-ui/core'
import {
    Save,
    Close,
    Edit

} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { FormDataContext } from 'FormDataContext';
import Cropper from 'react-easy-crop'
import API from 'libs/api';
import {bytesToSize} from "components/showItems";

const api = new API()

const useStyles = makeStyles(() => (
    {

        root: {
            display: 'flex',
            alignItems: "center",

        },
        dialogTitle: {
            marginTop: '1rem',
            textAlign: "left",
        },
        dialogContent: {
        },
        imgButton: {
            display: 'flex',
            justifyContent: "center",
            padding: "1rem 0",
        },
        dialogAction: {
            display: "flex",
            justifyContent: "flex-start",
        },
        dialogActionButton: {
            marginRight: "10px"
        },
        avatar: {
            width: '125px',
            height: '125px',
            cursor: "pointer",
        },
        textTitle: {
            marginLeft: "20px",
        },
        boxAvatar: {
            position: 'relative',
        },
        editAvatar: {
            position: "absolute",
            bottom: "8px",
            left: "93px",
            zIndex: "2",
            cursor: "pointer",
        },
        editAvatarCircle: {
            position: "absolute",
            bottom: "-25px",
            left: "75px",
            background: "white",
            padding: "2rem",
            borderRadius: "50%",
            zIndex: "1",
            cursor: "pointer",
        },


    }
));

const textPhoto = {
    dialog: {
        success: 'Выбранная фотография:',
        error: 'Выбран не тот формат, выберите фотографию с расширением png, и или jpg',
        save: "Соxранить",
        close: "Закрыть",
        edit: "редактировать",
    }
}

const fileTypesForInput = ['image/png', 'image/jpeg'];

const clickAvatar = () => {
    document.getElementById('fileButton').click()
}

function MyComponent(props) {
    const { item,values } = props;
    const { topColsBlock, disabled, field, title, format, options, helperText, required, apiFilter, ...other } = item;
    const { state, setState } = React.useContext(FormDataContext)

    const classes = useStyles();
    const [fileUpload, setFfileUpload] = React.useState('')
    const [dialog, setDialog] = React.useState('');
    const [crop, setCrop] = React.useState({ x: 0, y: 0 })
    const [zoom, setZoom] = React.useState(1)
    const [croppedAreaPixelsState, setCroppedAreaPixelsState] = React.useState('')
    const doCrop = false;
    const handlerChangeImg = (e) => {
        setCrop({ x: 0, y: 0 });
        setZoom(1)
        try {
            const file       = e.target.files[0]
            console.log('file', file)
            const reader     = new FileReader()
            reader.onloadend = () => {
                setFfileUpload(reader.result)
                setState(state => ({
                    ...state,
                    values :{
                        ...state.values,
                        picture_data: reader.result,
                        picture_type: file.type,
                        picture_image: fileTypesForInput.includes(file.type),
                        picture_name: file.name,
                        picture_size: file.size,
                    }
                }))
                if (doCrop) {
                    setDialog(textPhoto.dialog.success)
                }
            }
            reader.readAsDataURL(file)
            // if (fileTypesForInput.includes(file.type)) {
            //     return null
            // }
            // if (doCrop) {
            //     setDialog(textPhoto.dialog.error)
            // }
        }
        catch {
            console.log('Error!!!')
        }

    }

    const closeAll = () => {
        setFfileUpload('')
        setDialog('')
    }


    const uploadPhoto = () => {
        getCroppedImg(fileUpload, croppedAreaPixelsState).then(r => {
            const reader = new FileReader();
            reader.readAsDataURL(r);
            reader.onloadend = () => {
                const base64data = reader.result;
                setFfileUpload(base64data)
                setState(state => ({
                    ...state,
                    values :{
                        ...state.values,
                        picture_data: base64data
                    }
                }))
                closeAll()
            }
        })
    }

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixelsState(croppedAreaPixels)
    }

    const getCroppedImg = (imageSrc, crop) => {
        const image = new Image()
        image.src = imageSrc
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        /* setting canvas width & height allows us to 
        resize from the original image resolution */
        canvas.width = 250
        canvas.height = 250
        ctx.drawImage(
            image,
            crop.x,
            crop.y,
            crop.width,
            crop.height,
            0,
            0,
            canvas.width,
            canvas.height
        )

        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                resolve(blob)
            }, 'image/jpeg')
        })
    }
    const PaperStyled = withStyles((theme) => (
      {
          root: {
              maxWidth:           "200px",
              padding: theme.spacing(2),
          },
      }
    ))(Paper);

    return <React.Fragment>
        <AdminShow>[PhotoAdd]</AdminShow>
        <Dialog
            open={dialog}
            onClose={closeAll}
        >
            <DialogTitle id="alert-dialog-title"
                className={classes.dialogTitle}
            >
                <Box className={classes.dialogAction}>
                    {
                        fileUpload &&
                        <Button
                            variant="contained"
                            color={'primary'}
                            startIcon={<Save />}
                            onClick={uploadPhoto}
                            className={classes.dialogActionButton}
                        >
                            {textPhoto.dialog.save}
                        </Button>
                    }

                    <Button
                        variant="contained"
                        startIcon={<Close />}
                        onClick={closeAll}
                    >
                        {textPhoto.dialog.close}
                    </Button>
                </Box>
                {dialog}
            </DialogTitle>
            {
                fileUpload && 
                <DialogContent className={classes.dialogContent}>
                <Box>
                    <Box style={{ height: "50vh", position: 'relative', overflow: "hiden" }}>
                        <Cropper
                            image={fileUpload}
                            crop={crop}
                            zoom={zoom}
                            // aspect={1}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                        />
                    </Box>
                    <Box>
                        <Slider
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            onChange={(e, zoom) => setZoom(zoom)}
                            classes={{ root: 'slider' }}
                        />
                    </Box>

                </Box>
            </DialogContent>

            }
            
        </Dialog>
        <Box className={classes.root}>
            <input type="file" id="fileButton" onChange={handlerChangeImg} hidden={Boolean(fileUpload)}/>
            {/*<Box className={classes.boxAvatar} onClick={clickAvatar}>*/}
            {/*    <Avatar*/}
            {/*        src={values['picture_data'] ? values['picture_data'] : api.getUploadImg(values['picture'])}*/}
            {/*        className={classes.avatar}*/}
            {/*    />*/}
            {/*    <Edit className={classes.editAvatar} />*/}
            {/*    <Box className={classes.editAvatarCircle} />*/}
            {/*</Box>*/}
            {fileUpload && <PaperStyled onClick={clickAvatar}>
                {state.values.picture_image
                    ? <img alt="attach" style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                    }} src={fileUpload}/>
                    : <div>
                        <DescriptionIcon style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                        }}/>
                        <br/>
                        Файл:{bytesToSize(state.values.picture_size)}
                    </div>
                }
            </PaperStyled>
            }
            <Typography variant='body2' className={classes.textTitle}>
                {helperText}
            </Typography>
        </Box>


    </React.Fragment>;
}

function areEqual(prevProps, nextProps) {
    return prevProps.value === nextProps.value
        && prevProps.error === nextProps.error;
}

const MyComponentMemo = React.memo(MyComponent, areEqual);

function MyComponentMemoUse(props) {
    const { field } = props.item;
    const { state: { values, errors }, handleChange } = React.useContext(FormDataContext);
    const handleChangeLocal = (e) => {
        console.log('handleChange Input', e, e.target, e.target.value);
        return handleChange(props.item)(e, e.target.value);
    };

    const value = values && values[field];
    const error = errors && errors[field];

    return <MyComponentMemo
        values={values}
        value={value || ''}
        error={error || null}
        handleChange={handleChangeLocal}
        {...props}
    />;
}

export default MyComponentMemoUse;