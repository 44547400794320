
import React from 'react';

import {Box, Button, Grid, Paper, Tab, Tabs, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import ConfirmDialog from 'components/ConfirmDialog';
import AdminShow from 'components/FieldFormat/AdminShow';
import {CancelIcon, InfoIcon, SaveIcon} from 'components/icons';

import LinearProgress from 'components/LinearProgress';
import {AddBreadCrumb} from 'components/Menu';
import PageApiLoader3 from 'components/PageApiLoader3';
import FormDebug from 'components/RequestFormDebug';
import {FormDataContext, FormDataContextConsumer, FormDataContextProvider} from 'FormDataContext';
import API from 'libs/api';
import {Link} from 'react-router-dom';
import {RequestFormDebugContext} from './RequestFormDebug';
import RequestForm from './RequestForm';
import {FieldFormat} from '../config/FieldFormat';
import {getCookie, setCookie} from 'cookie';
import {useSelector} from 'react-redux';

function ActionButtons(props) {
    const {actionAddTitle, actionUpdateTitle, canDeleteItem, handleClose, canDelete, initialValues, dbId} = props;

    const {state, handleSubmitForm} = React.useContext(FormDataContext);
    const {submitting}              = state;
    const isUpdate                  = initialValues && initialValues[dbId];

    return <div>
        <Grid container>
            <Grid item>
                <Button
                    variant="contained" size="small"
                    color={'primary'}
                    onClick={e => handleSubmitForm(e, state.values)}
                    disabled={submitting}
                    startIcon={<SaveIcon/>}
                >
                    {isUpdate
                        ? actionUpdateTitle
                        : actionAddTitle}
                </Button>
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    size="small"
                    disabled={submitting}
                    onClick={handleClose}
                    startIcon={<CancelIcon/>}
                >
                    Отмена
                </Button>
            </Grid>
            <Grid item>
                {isUpdate && canDelete &&
                canDeleteItem(state.values) &&
                <ConfirmDialog
                    onConfirm={e => handleSubmitForm(e, {...state.values, isDelete: true})}
                    trigger={<Button
                        color={'secondary'}
                        disabled={submitting}
                    >
                        Удалить
                    </Button>}
                />
                }
            </Grid>
            <Grid item xs={12}>
                <LinearProgress show={submitting}/>
            </Grid>
        </Grid>
    </div>;
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

function LinkTab(props) {
    const {value, path} = props;
    return (
        <Tab
            component={Link}
            to={path + '/' + value}
            // onClick={(event) => {
            //     event.preventDefault();
            // }}
            {...props}
        />
    );
}

const classes = makeStyles((theme) => (
    {
        root: {
            flexGrow:        1,
            backgroundColor: theme.palette.background.paper,
            display:         'flex',
            // height:          224,
        },
        tabs: {
            borderRight: `1px solid ${theme.palette.divider}`,
        },
    }
));

const defaultProps = {
    onFormDataAction:  values => values,
    canDeleteItem:     values => values,
    actionAddTitle:    'Добавить',
    actionUpdateTitle: 'Сохранить',
    FormComponent:     RequestForm,
    handleChange:      (values) => console.log(values),
};

export default function FilterTableParams(props) {

    const {
              dbId,
              open,
              onSuccess,
              handleSubmit,
              initialValues,
              itemName,
              topColsBlocks,
              subFormHandleChange,
              onFormDataAction,
              FormComponent,
              subApi,
              path,
              actionAddTitle,
              actionUpdateTitle,
              helpPrefix,
              apiName,
              handleChange,
          } = {...defaultProps, ...props};
    
    // const [values, setValues] = React.useState(initialValues);

    const [cols, setColumns] = React.useState([]);

    //const me = React.useContext(MeContext);
    const me        = useSelector(s => s.system && s.system.me && s.system.me.items)|| {};
    React.useMemo(() => {


        let c = 'function' === typeof props.cols
            ? props.cols(props, {}, {me})
            : props.cols;

        if (c instanceof Promise) {
            c.then(data => setColumns(data));
        }
        else {
            setColumns(c);
        }
    }, [props.cols]);


    const filterCols = cols
        .filter(i => i.filter)
        .map(i => ({
            ...i,
            ...i.filter,
        }));
    
    if (!filterCols.length) {
        return null;
    }
    
    let values;
    try {
        const saved = apiName
            ? getCookie('filter.'.concat(apiName))
            : '';
        values      = JSON.parse(saved) || initialValues || {};

        // Обрать сохранненные несуществующие фильтры
        Object.keys(values).map(i => {
            if (!filterCols.find(f => f.filter.field === i)) {
                delete values[i];
            }
        });
    }
    catch (e) {
        values = initialValues || {};
    }
    
    const Form = subFormHandleChange
        ? 'div'
        : 'form';

    return (
        <FormDataContextProvider
            initial={values}
            onFormDataAction={onFormDataAction}
            handleSubmit={handleSubmit}
            cols={cols}
            dbId={dbId}
            onSuccess={onSuccess}
        >
            <FormDataContextConsumer>{context => {
                // console.log('handleChange subValues FormDataContextConsumer', context);
                if (JSON.stringify(initialValues) !== JSON.stringify(context.state.values)) {
                    // setValues(values);
                    console.log('filter.'.concat(apiName),JSON.stringify(context.state.values),JSON.stringify(initialValues));
                    setCookie('filter.'.concat(apiName),JSON.stringify(context.state.values));
                    handleChange(context.state.values);
                }
            }}</FormDataContextConsumer>
            <AdminShow>[FilterTableParams]</AdminShow>
            {/*<pre>{JSON.stringify(initialValues,null,2)}</pre>*/}
            {/*<pre>{JSON.stringify(initialValues,null,2)}</pre>*/}
            <Paper>
                <Form>
                    <Box p={3}>
                        <Typography variant={'h6'}>Фильтр значений списка</Typography>
                        <FormComponent
                            open={open}
                            // values={values}
                            // handleChange={handleChange}
                            // setState={setState}
                            // handleSuggest={handleSuggest}
                            // errors={errors}
                            // submitting={submitting}
                            topColsBlocks={topColsBlocks}
                            cols={filterCols}
                            path={path + '/edit'}
                        />
                    </Box>
                </Form>
            </Paper>
            <RequestFormDebugContext/>
        </FormDataContextProvider>
    );
}
