import {ServiceIcon} from 'components/icons';
import {isAdmin, isExecutor, isResponsible, STATUS} from 'config/issues';
import {ROLES} from 'config/roles';
import asId from 'libs/asId';
import PageIssueExecutor from 'page/PageIssueExecutor';
import {FieldFormat} from './FieldFormat';
import {ShowUser} from 'components/showItems';
import asGUID from 'libs/asGUID';

export default {
    listTitle: 'Исполнитель',
    apiName:   'issue_executors',
    itemName:  'исполнителя',
    canAdd:    (props) => {
        /** Кнопка “Добавить Исполнителя”
         - | Текущий Статус   |  Автор | Руководитель | Исполнитель | Админ |
         1 | Новая            | -      | +            | -           | +     |
         2 | В работе         | -      | +            | -           | +     |
         3 | Работы выполнены | -      | -            | -           | +     |
         4 | Закрыта          | -      | -            | -           | +     |
         */
        const {item, parent, me} = props;
        console.log('canAdd', props,{parent, me, isAdmin: isAdmin(me), isResponsible: isResponsible(me, parent), isExecutor: isExecutor(me, parent)})
        if (!me) {
            return false;
        }
    
        switch (asId(parent.status)) {
            case STATUS.NEW:
                return isResponsible(me, parent) || isAdmin(me);
            case STATUS.IN_WORK:
                return isResponsible(me, parent) || isAdmin(me);
            case STATUS.DONE:
                return isAdmin(me);
            case STATUS.CLOSED:
                return isAdmin(me);
            default:
                return false;
        }
    },
    canEdit:   (props) => {
        const {item, parent, me} = props;
        console.log('issue_executors canEdit', props);
        if (asId(parent.status) === STATUS.CLOSED) {
            return false;
        }
        const {roles, guid} = {roles: [], ...me};
        return roles.includes(ROLES.ROLE_ADMIN)
            || guid === asGUID(parent.responsibleFromSubUnit);
        
    },
    canDelete: (props) => {
        const {item, parent, me} = props;
        console.log('issue_executors canDelete', props);
        if (asId(parent.status) === STATUS.CLOSED) {
            return false;
        }
        const {roles, guid} = {roles: [], ...me};
        return roles.includes(ROLES.ROLE_ADMIN)
            || guid === asGUID(parent.responsibleFromSubUnit);
        
    },
    
    roles: [
        ROLES.ROLE_USER,
    ],
    menu:  {
        title:     'Исполнитель',
        path:      '/issue_executors',
        Component: PageIssueExecutor,
        roles:     [ROLES.ROLE_USER],
        icon:      ServiceIcon,
    },
    cols:  [
        // {
        //     title:  'Заявка',
        //     field:  'issue',
        //     format: FieldFormat.FORMAT_NONE,
        //     api:    'issues',
        //     object: ShowApiName,
        // },
        {
            title:  'Исполнитель',
            field:  'executor',
            format: FieldFormat.FORMAT_SELECT_API,
            api:    'users',
            object: ShowUser,
            
        },
        // {
        //     title:  'Сверхурочно',
        //     field:  'overtime',
        //     format: FieldFormat.FORMAT_CHECKBOX,
        //     object: ColBoolean,
        // },
    ],
};
