import {WorkObjectIcon} from 'components/icons';
import {ROLES} from 'config/roles';
import workObjectContract from 'config/workObjectContract';
import workObjectDevice from 'config/workObjectDevice';
import PageWorkObject from 'page/PageWorkObject';
import {FieldFormat} from './FieldFormat';
import {ColObjectName, ColRoadName, ShowDate, ShowUser} from '../components/showItems';

export default {
    listTitle:        'Объект',
    apiName:          'work_objects',
    itemName:         'объект',
    canAdd:           true,
    canEdit:          true,
    canDelete:        true,
    roles:            [
        ROLES.ROLE_USER,
    ],
    menu:             {
        title:     'Объект',
        path:      '/work_objects',
        Component: PageWorkObject,
        roles:     [ROLES.ROLE_USER],
        icon:      WorkObjectIcon,
    },
    onFormDataAction: (values, props) => {
        if (props.field && props.field === 'modelType') {
            return {...values, model: null};
        }

        const {item}        = props && props.event && props.event.target || {};
        const modelTypeName = props.field && props.field === 'modelType' && item && item.name;
        const addressName   = props.field && props.field === 'address' && item && item.name;


        if (!modelTypeName) {
            return values;
        }
        return {
            ...values,
            name: modelTypeName.concat(addressName),
        };
    },
    cols:             [
        {
            title:        'Наименование',
            field:        'name',
            required:     true,
            hiddenInCard: true,
            format:       FieldFormat.FORMAT_NONE,
        },
        {
            title:    'Тип объекта',
            field:    'type',
            format:   FieldFormat.FORMAT_SELECT_API,
            api:      'work_object_types',
            object:   ColObjectName,
            cols:     4,
            required: true,
            // hidden: true,
            // hiddenInCard: true,
            filter: {
                format: FieldFormat.FORMAT_SELECT_API_CHECKBOX,
                field:  'type[]',
            },
        },
        {
            title:       'Адрес объекта',
            field:       'address',
            format:      FieldFormat.FORMAT_SELECT_API,
            api:         'addresses',
            fieldSearch: 'road.name',
            object:      ColObjectName,
            required:    true,
            hidden:      true,
            cols:        4/3,
        },
        {
            title:  'Дорога',
            field:  'road',
            format: FieldFormat.FORMAT_NONE,
            api:    'roads',
            hidden: true,
            hiddenInCard: true,
            cols: 2,
            filter: {
                format: FieldFormat.FORMAT_SELECT_API_CHECKBOX,
                field:  'address.road[]',
            },
        },
        // {
        //     title:  'Модель',
        //     field:  'model',
        //     format: FieldFormat.FORMAT_SELECT_API,
        //     api:       'work_object_models',
        //     apiFilter: values => (
        //         {
        //             type: values && values.modelType && values.modelType.toString()
        //                 .replace('/api/work_object_types/', '') || 0,
        //         }
        //     ),
        //     object: ColObjectName,
        //     cols: 3,
        //     required: true,
        //     hidden: true,
        // },
        {
            title:  'Владелец',
            field:  'customer',
            format: FieldFormat.FORMAT_SELECT_API,
            api:    'customers',
            object: ColObjectName,
            hidden: true,
        },
        {
            title:    'Ответственный',
            field:    'responsibleFromSubUnit',
            format:   FieldFormat.FORMAT_NONE,
            api:      'users',
            object:   ShowUser,
            disabled: true,
            cols:     3,
            // required: true,
        },

        // {
        //     title: 'RTSP поток',
        //     field: 'rtsp',
        // },
        // {
        //     title:  'Электроснабжение',
        //     field:  'powerSupply',
        //     format: FieldFormat.FORMAT_SELECT_API,
        //     api:    'power_supplies',
        //     object: ColObjectName,
        // },
        {
            title:  'Дата ввода объекта',
            field:  'startedAt',
            // format: FieldFormat.FORMAT_DATE,
            // object: ShowDate,
            hidden: true,
        },
        {
            title:    'Статус',
            field:    'status',
            format:   FieldFormat.FORMAT_SELECT_API,
            api:      'work_object_statuses',
            hidden:   true,
            object:   ColObjectName,
            required: true,
        },
    ],
    subApi:           [
        {
            xs:          6,
            title:       'Договор',
            item:        workObjectContract,
            parentField: 'workObject',
            apiFilter:   (item) => (
                {
                    workObject: item.id,
                }
            ),
        },
        {
            xs:          12,
            title:       'Перечень конструктивных элементов',
            item:        workObjectDevice,
            parentField: 'workObject',
            apiFilter:   (item) => (
                {
                    workObject: item.id,
                }
            ),
        },
    ],
};
