import React from 'react';
import {SubFormEditCreateTable} from 'components/SubFormEditCreateTable';
import {config} from 'config';
import {FormDataContext} from 'FormDataContext';
import AdminShow from 'components/FieldFormat/AdminShow';

export default function({item, tracePath}) {
    const {field} = item;
    
    const {state: {values, errors}, handleChange} = React.useContext(FormDataContext);
    
    return <React.Fragment>
        <AdminShow>[SubTable]</AdminShow>
        <SubFormEditCreateTable
            item={item}
            itemValues={values}
            onChange={handleChange(item)}
            // apiFilter={apiFilter && apiFilter(values,parent)}
            values={values[field] || []}
            errors={errors[field]}
            {...config.get(item.subConfig)}
            tracePath={[...tracePath, 'SubFormEditCreateTable']}
        />
    </React.Fragment>;
}