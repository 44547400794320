import {ServiceIcon} from 'components/icons';
import {ColObjectName, ShowUser} from 'components/showItems';
import {ROLES} from 'config/roles';
import {FieldFormat} from 'config/FieldFormat';
import PageWorkObjectContracts from 'page/PageWorkObjectContracts';

export default {
    listTitle: 'Договор Объект',
    apiName:   'work_object_contracts',
    itemName:  'Договор Объект',
    canAdd:    true,
    canDelete: true,
    canEdit:   true,
    roles:     [
        ROLES.ROLE_USER,
    ],
    menu:      {
        title:     'Договор Объект',
        path:      '/work_object_contracts',
        Component: PageWorkObjectContracts,
        roles:     [ROLES.ROLE_USER],
        icon:      ServiceIcon,
    },
    cols:      [
        {
            title:  'Объект',
            field:  'workObject',
            format: FieldFormat.FORMAT_SELECT_API,
            api:    'work_objects',
            object: ColObjectName,
            cols: 3,
        },
        {
            title:  'Договор',
            field:  'contract',
            format: FieldFormat.FORMAT_SELECT_API,
            api:    'contracts',
            object: ColObjectName,
            cols:   3,
        },
        {
            title:  'Заказчик',
            field:  'contractCustomer',
            format: FieldFormat.FORMAT_NONE,
            api:    'customers',
            object: ColObjectName,
        },
    ],
};
