import AdminShow from 'components/FieldFormat/AdminShow';
import React from 'react';
import ErrorBoundary from 'components/ErrorBoundary';
import Checkbox from 'components/FieldFormat/Checkbox';
import Custom from 'components/FieldFormat/Custom';
import FieldFormatDate from 'components/FieldFormat/Date';
import DatetimeLocal from 'components/FieldFormat/DatetimeLocal';
import SelectApi from 'components/FieldFormat/SelectApi';
import SelectApiCheckbox from 'components/FieldFormat/SelectApiCheckbox';
import SelectApiIndex from 'components/FieldFormat/SelectApiIndex';
import SelectDadata from 'components/FieldFormat/SelectDadata';
import SelectOptions from 'components/FieldFormat/SelectOptions';
import SimpleInput from 'components/FieldFormat/SimpleInput';
import SubForm from 'components/FieldFormat/SubForm';
import SubTable from 'components/FieldFormat/SubTable';
import Textarea from 'components/FieldFormat/Textarea';
import ShowTabPanel from 'components/FieldFormat/ShowTabPanel';
import {InfoIcon} from 'components/icons';
import {FormDataContextConsumer} from 'FormDataContext';
import {FieldFormat} from 'config/FieldFormat';
import {
    FormControl,
    Grid,
    AppBar,
    Tabs,
    Tab,
    FormLabel,
    FormGroup,
} from '@material-ui/core';

import ErrorMessage from 'components/ErrorMessage';
import FormDebug from 'components/RequestFormDebug';
import MaskInput from './FieldFormat/MaskInput';
import NumberFormat from './FieldFormat/NumberFormat';
import MaskInputEmail from './FieldFormat/MaskInputEmail';
import PhotoAdd from './FieldFormat/PhotoAdd'

export {FieldFormat} from 'config/FieldFormat';

function a11yProps(index) {
    return {
        id:              `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const defaultProps = {
    handleSuggest: () => {},
    colsInTab:     false,
    // _handleChange: console.log(),
    // handleChange: FormDataContextConsumer(context => console.log('context',context )),
};

function fromArrayOrCallback(list) {
    if (!list) {
        return [];
    }
    return 'function' === typeof list
        ? list()
        : list;
}

const fieldsShowMap = (props, key) => {
    const {cols, render} = {cols: 1, ...props};

    let sm = 12 / cols;

    return <Grid item key={key} sm={sm} xs={12}>
        <ErrorBoundary>{render}</ErrorBoundary>
    </Grid>;
};

const topColsBlocksMap = ({topColsBlocks, fieldsShow}) => (block, blockKey) => {
    const {cols, title, num} = {
        num:  blockKey + 1,
        cols: topColsBlocks.length,
        ...block,
    };
    return (
        <Grid key={blockKey} item sm={12 / cols}>
            <FormControl component="fieldset" style={{width: '100%'}}>
                <FormLabel component="legend">{title}</FormLabel>
                <FormGroup>
                    <Grid container spacing={3}>
                        {fieldsShow
                            .filter(b => b.topColsBlock === num)
                            .map(fieldsShowMap)}
                    </Grid>
                </FormGroup></FormControl>
        </Grid>
    );
};

const eachErrorMessage = ([field, value]) => <ErrorMessage
    title={field + ': ' + value} extended={false}
/>;

/*
                    item.render = <SubComp item={item} submitting={submitting} values={values} errors={errors}/>;
                    break;
                default:
                    item.render = <FormDataContextConsumer>{context => <div>[default:{(new Date()).toJSON()}]<br/>{format}<TextField

* */

export default function RequestFormTabs(props) {
    if (window.tracePath) {
        console.log('tracePath: ', props.tracePath.join('/'));
    }

    const [tabSelected, setTabSelected] = React.useState(0);
    const handleChangeTab               = (event, newValue) => {
        setTabSelected(newValue);
    };

    const {
              handleSuggest,
              submitting,
              cols,
              colsInTab,
              debug,
              topColsBlocks,
              parent,
              subItems,
              tracePath,
          } = {...defaultProps, ...props};

    // https://medium.com/@tofusoup429/how-to-switch-focus-of-reactjs-text-field-components-by-pressing-enter-key-5ffc56c677ea
    const onKeyDown = e => {
        console.log('keyPress', e.target);
        if(e.keyCode === 13){
            console.log('value', e.target.value);
            // put the login here
        }
    };

    const fieldsShow = cols
        .filter(i => i.format !== FieldFormat.FORMAT_NONE)
        .map(item => {
            const {format} = item;
            switch (format) {
                case FieldFormat.FORMAT_CUSTOM:
                    item.render = <Custom item={item} submitting={submitting}/>;
                    break;
                default:
                case FieldFormat.FORMAT_INPUT:
                    item.render = <SimpleInput item={{...item,onKeyDown}} submitting={submitting}/>;
                    break;
                case FieldFormat.FORMAT_INPUT_MASK:
                    item.render = <MaskInput item={item} submitting={submitting}/>;
                    break;
                case FieldFormat.FORMAT_INPUT_EMAIL:
                    item.render = <MaskInputEmail item={item} submitting={submitting}/>;
                    break;
                case FieldFormat.FORMAT_NUMBER:
                    item.render = <NumberFormat item={item} submitting={submitting}/>;
                    break;
                case FieldFormat.FORMAT_TEXTAREA:
                    item.render = <Textarea item={item} submitting={submitting}/>;
                    break;
                case FieldFormat.FORMAT_SELECT:
                    item.render = <SelectOptions item={item} submitting={submitting}/>;
                    break;
                case FieldFormat.FORMAT_SELECT_API_CHECKBOX:
                    item.render = <SelectApiCheckbox item={item} submitting={submitting} tracePath={tracePath}/>;
                    break;
                case FieldFormat.FORMAT_SELECT_API_INDEX:
                    item.render = <SelectApiIndex item={item} submitting={submitting} tracePath={tracePath}/>;
                    break;
                case FieldFormat.FORMAT_SELECT_API:
                    item.render = <SelectApi item={item} submitting={submitting} tracePath={tracePath} parent={parent}/>;
                    break;
                case FieldFormat.FORMAT_SUB_FORM:
                    item.render = <SubForm item={item} submitting={submitting} tracePath={tracePath} parent={parent}/>;
                    break;
                case FieldFormat.FORMAT_SUB_TABLE:
                    item.render = <SubTable item={item} submitting={submitting} tracePath={tracePath} parent={parent}/>;
                    break;
                case FieldFormat.FORMAT_SELECT_DADATA:
                    item.render = <SelectDadata item={item} submitting={submitting} tracePath={tracePath} parent={parent} handleSuggest={handleSuggest}/>;
                    break;
                case FieldFormat.FORMAT_DATE:
                    item.render = <FieldFormatDate item={item} submitting={submitting} tracePath={tracePath} parent={parent}/>;
                    break;
                case FieldFormat.FORMAT_DATETIME_LOCAL:
                    item.render = <DatetimeLocal item={item} submitting={submitting} tracePath={tracePath} parent={parent}/>;
                    break;
                case FieldFormat.FORMAT_CHECKBOX:
                    item.render = <Checkbox item={item} submitting={submitting} tracePath={tracePath} parent={parent}/>;
                    break;
                case FieldFormat.FORMAT_PHOTO_ADD:
                    item.render = <PhotoAdd item={item} submitting={submitting} tracePath={tracePath} parent={parent}/>;
                    break;
            }
            return item;
        },
    );

    const FormFields = (props) => {
        return <React.Fragment>
            {props.inTab && <br/>}
            <Grid container spacing={3}>
                <br/>
                {topColsBlocks
                    ? topColsBlocks.filter(i => i.num).map(topColsBlocksMap({fieldsShow, topColsBlocks}))
                    : fieldsShow.map(fieldsShowMap)
                }
                <FormDataContextConsumer>{context => {
                    let errors = context.state.errors;
                    return errors && Object.entries(errors)
                        .filter(([field]) => !cols.find(i => i.field === field))
                        .slice(0, 10)
                        .map(eachErrorMessage);
                }}</FormDataContextConsumer>
            </Grid>
        </React.Fragment>;
    };

    const FormFields0 = (props) => {
        return <React.Fragment>
            {props.inTab && <br/>}
            <Grid container spacing={3}>
                <br/>
                {topColsBlocks && topColsBlocks
                        .filter(i => i.num === 0)
                        .map(topColsBlocksMap({fieldsShow, topColsBlocks}))

                }
                <FormDataContextConsumer>{context => {
                    let errors = context.state.errors;
                    return errors && Object.entries(errors)
                                           .filter(([field]) => !cols.find(i => i.field === field))
                                           .slice(0, 10)
                                           .map(eachErrorMessage);
                }}</FormDataContextConsumer>
            </Grid>
        </React.Fragment>;
    };

    const tabList = [
        colsInTab && {
            listTitle: 'Основное',
            icon: <InfoIcon/>,
            Component: FormFields,
        },
        ...fromArrayOrCallback(subItems && subItems.list),
    ];

    return (
        <ErrorBoundary>
            <AdminShow>[RequestFormTabs]</AdminShow>
            <Grid container spacing={3}>
                <FormFields0/>
                {!colsInTab && <FormFields/>}
                {subItems && <Grid item sm={12}>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={tabSelected}
                            onChange={handleChangeTab}
                            variant="scrollable"
                            scrollButtons="on"
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            {tabList
                                .map((i, key) => <Tab
                                    key={key}
                                    label={i.listTitle}
                                    icon={i.icon}
                                    {...a11yProps(key)}
                                />)}
                        </Tabs>
                    </AppBar>
                    {tabList.map((i, n) => <ShowTabPanel
                        props={props} i={i} n={n} key={n} tracePath={props.tracePath} tabSelected={tabSelected}
                    />)}
                </Grid>}
                <FormDataContextConsumer>{context => <FormDebug values={context.state.values} debug={debug} errors={context.state.errors}/>}</FormDataContextConsumer>

            </Grid>
        </ErrorBoundary>
    );
}