import React from 'react';
import {TextField} from '@material-ui/core';
import {FormDataContext} from 'FormDataContext';

function MyComponent(props) {
    const {submitting, item, value, error, handleChange}                                              = props;
    const {topColsBlock, disabled, field, title, format, options, api, required, apiFilter, ...other} = item;
    
    
    // export default function({submitting, item}) {
    // const {field, title, required} = item;
    
    // const {state: {values, errors}, handleChange} = React.useContext(FormDataContext);
    
    return <React.Fragment>
        <TextField
            multiline
            rows={4}
            // rowsMax={6}
            disabled={submitting}
            label={title}
            value={value}
            required={required}
            margin={'dense'}
            fullWidth
            variant={'outlined'}
            name={field}
            onChange={handleChange}
            error={!!error}
            helperText={error}
            InputLabelProps={{shrink: true}}
        />
    </React.Fragment>;
}


function areEqual(prevProps, nextProps) {
    return prevProps.value === nextProps.value;
}

const MyComponentMemo = React.memo(MyComponent, areEqual);

function MyComponentMemoUse(props) {
    const {field}                                 = props.item;
    const {state: {values, errors}, handleChange} = React.useContext(FormDataContext);
    const handleChangeLocal                       = (e) => {
        console.log('handleChange Input', e, e.target, e.target.value);
        return handleChange(props.item)(e, e.target.value);
    };
    
    const value = values && values[field];
    const error = errors && errors[field];
    
    return <MyComponentMemo
        values={values}
        value={value || ''}
        error={error || null}
        handleChange={handleChangeLocal}
        {...props}
    />;
}

export default MyComponentMemoUse;