const optionsLines = [
    {
        id:   1,
        name: '1 полоса',
    },
    {
        id:   2,
        name: '2 полосы',
    },
    {
        id:   3,
        name: '3 полосы',
    },
    {
        id:   4,
        name: '4 полосы',
    },
];

export default optionsLines;