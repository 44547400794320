import {ServiceIcon} from 'components/icons';
import {ROLES} from 'config/roles';
import PageService from 'page/PageService';

export default {
    listTitle: 'Услуги',
    apiName:   'services',
    itemName:  'услугу',
    canDelete: true,
    roles:     [
        ROLES.ROLE_USER,
    ],
    menu:      {
        title:     'Услуги',
        path:      '/services',
        Component: PageService,
        roles:     [ROLES.ROLE_USER],
        icon:      ServiceIcon,
    },
    cols:      [
        {
            title:        'Наименование',
            field:        'name',
            topColsBlock: 1,
        },
    ],
};
