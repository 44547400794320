import {PowerSupplyIcon} from 'components/icons';
import {FieldFormat} from 'config/FieldFormat';
import {ROLES} from 'config/roles';
import PagePowerSupplies from 'page/PagePowerSupplies';

export default {
    listTitle: 'Электроснабжение',
    apiName:   'power_supplies',
    itemName:  'электроснабжение',
    canDelete: true,
    roles:     [
        ROLES.ROLE_USER,
    ],
    menu:      {
        title:     'Электроснабжение',
        path:      'power_supplies',
        Component: PagePowerSupplies,
        roles:     [ROLES.ROLE_USER],
        icon:      PowerSupplyIcon,
    },
    cols:      [
        {
            title: 'Наименование',
            field: 'name',
        },
        {
            title: 'Балансодержатель',
            field: 'balanceOwner',
            cols:  3,
        },
        {
            title: 'Статус подключения',
            field: 'connectStatus',
            cols:  3,
        },
        {
            title: 'Контактыне лица',
            field: 'contactPeople',
            format: FieldFormat.FORMAT_TEXTAREA,
        },
        {
            title: 'Тех детали подключения',
            field: 'techInfo',
            format: FieldFormat.FORMAT_TEXTAREA,
        },
        {
            title:  'Примечание',
            field:  'description',
            format: FieldFormat.FORMAT_TEXTAREA,
            
        },
    
    ],
};
