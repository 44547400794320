import React from 'react';
import {useSelector} from 'react-redux';
import * as PropTypes from 'prop-types';

function IfUserCan(props) {
    const {item, action, children, parent} = props;
    const [canDo, setCanDo]              = React.useState(false);
    const me                               = useSelector(s => s.system && s.system.me && s.system.me.items) || {};
    
    React.useEffect(() => {
        setCanDo('function' === typeof action
            ? action({item, me, parent})
            : action,
        );
    }, []);
    return canDo
        ? children
        : null;
}

export function IfUserCanFunc(props) {
    const {item, action, children, parent, me } = props;
    const canDo = 'function' === typeof action
            ? action({item, me, parent})
            : action;
    
    return canDo
        ? children
        : null;
}

IfUserCan.propTypes = {
    action:   PropTypes.string,
    item:     PropTypes.node,
    children: PropTypes.node,
};

export default IfUserCan;