import AdminShow from 'components/FieldFormat/AdminShow';
import React from 'react';
import {TextField} from '@material-ui/core';
import {FormDataContext, FormDataContextConsumer} from 'FormDataContext';
import MaskedInput from 'react-text-mask';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';


function InputWithMask(props) {
    const {inputRef, mask, ...other} = props;
    if(!mask){
        console.error('Mask not set for: ', other.name)
    }
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={mask||[]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}

function MyComponent(props) {
    const {submitting, item, value, error, handleChange} = props;
    const {
              topColsBlock,
              disabled,
              field,
              title,
              format,
              options,
              api,
              required,
              apiFilter,
              mask,
              ...other
          }                                              = item;

    return <React.Fragment>
        <AdminShow/>
        <TextField
            disabled={disabled || submitting}
            label={title}
            value={value}
            required={required}
            margin={'dense'}
            fullWidth
            variant={'outlined'}
            name={field}
            onChange={handleChange}
            error={!!error}
            helperText={error}
            InputLabelProps={{shrink: true}}
            inputProps={{mask}}
            InputProps={{
                onKeyPress: (event) => {
                    const t = event.target;
                    var s   = t.selectionStart;
                    if (s === 4) {
                        s = 5;
                    }
                    t.value        = t.value.substr(0, s) + t.value.substr(s + 1);
                    t.selectionEnd = s;
                },
                startAdornment: <FormDataContextConsumer>{context => item && item.startButtons && item.startButtons({
                    values:         context.state.values,
                    setStateValues: context.setStateValues,
                })}</FormDataContextConsumer>,
                inputComponent: InputWithMask,
            }}
            {...other}
        />
    </React.Fragment>;
}

function areEqual(prevProps, nextProps) {
    return prevProps.value === nextProps.value
        && prevProps.error === nextProps.error;
}

const MyComponentMemo = React.memo(MyComponent, areEqual);

function MyComponentMemoUse(props) {
    const {field,fixValue}                                 = props.item;
    const {state: {values, errors}, handleChange} = React.useContext(FormDataContext);
    const handleChangeLocal                       = (e) => {
        // console.log('handleChange parts Input', e, e.target, e.target.value);
        const value = fixValue ? fixValue(e.target.value) : e.target.value;
        // console.log('parts Input', e.target.value, value);
        return handleChange(props.item)(e, value);
    };

    const transform = value => {
        // console.log('parts',value||'');
        const val = (value||0).toString(),
            match = val.match(/(\d+)\+(:?\d+)/),
            parts = match || val.match(/(\d{0,4}?)(\d{0,3})$/) || [];

        let a = ("0000" + parts[1]).substr(-4);
        let b = ("000" + parts[2]).substr(-3);

        return a + '+'+ b;
    }
    const value = transform(values && values[field]);
    // const value = values && values[field]
    const error = errors && errors[field];

    return <MyComponentMemo
        values={values}
        value={value || ''}
        error={error || null}
        handleChange={handleChangeLocal}
        {...props}
    />;
}

export default MyComponentMemoUse;