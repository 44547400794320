import {withWidth} from '@material-ui/core';
import {EditIcon} from 'components/icons';
import {ApiObjectLoadForMTable} from 'components/showItems';
import {config} from 'config';

import MaterialTable from 'material-table';
import React from 'react';

function SelectorTable(props) {
    const {listTitle, list, onEdit, canEdit,onSelect } = props;
/*    const detectHidden = col => {
        if (!col.hidden) {
            return undefined;
        }
        let _ = col.hidden;
        // let hid =
        // Hidden({...col.hidden,initialWidth:width,children:'children',implementation:'js'});
        // console.log('Hidden',Object.keys(hid));
        switch (width) {
            case 'xl':
                return _.xl
                    || _.xsUp || _.smUp || _.mdUp || _.lgUp || _.xlUp
                    || _.xlDown;
            case 'lg':
                return _.lg
                    || _.xsUp || _.smUp || _.mdUp || _.lgUp
                    || _.xlDown || _.lgDown;
            case 'md':
                return _.md
                    || _.xsUp || _.smUp || _.mdUp
                    || _.xlDown || _.lgDown || _.mdDown;
            case 'sm':
                return _.sm
                    || _.xsUp || _.smUp
                    || _.xlDown || _.lgDown || _.mdDown || _.smDown;
            
            case 'xs':
                return _.xs
                    || _.xsUp
                    || _.xlDown || _.lgDown || _.mdDown || _.smDown || _.xsDown;
        }
        return true;
    };
    */
    const detectRender = col => {
        if (col.render) {
            return col.render;
        }
        else if (col.api && col.object) {
            return rowData => ApiObjectLoadForMTable(col.api, col.object, {
                item:   rowData[col.field],
                row:    rowData,
                column: col.field,
            });
        }
        else if (col.object) {
            return rowData => <col.object
                item={rowData[col.field]}
                row={rowData}
                column={col.field}
            />;
            
        }
        else {
            return undefined;
        }
    };
    
    const transformCols = (cols) => cols.map(i => (
        {
            ...i,
            // title:  i.title,
            // field:  i.field,
            // hidden: false,
            render: detectRender(i),
        }
    ))
    .filter(i => 'string' === typeof i.title);

    const columns = 'function' === typeof props.cols
        ? [...props.cols(props, (list && list.items) || [])]
        : [...props.cols]
    ;
    
    return <MaterialTable
        title={listTitle}
        onRowClick={ (event, rowData) => onSelect(rowData)}
        columns={transformCols(columns)}
        data={(list && list.items) || []}
        actions={[
            canEdit && {
                icon:    EditIcon,
                tooltip: 'Редактировать',
                onClick: (event, rowData) => onEdit(rowData)(),
            },
        ]}
        {...config.MaterialTable}
    />;
}

export default withWidth()(SelectorTable);