import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import {makeStyles} from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {
    Visibility,
    VisibilityOff,
} from '@material-ui/icons';
import clsx from 'clsx';
import ErrorMessage from 'components/ErrorMessage';
import API from 'libs/api';
import React from 'react';
import {
    useDispatch,
} from 'react-redux';
import {SYSTEM} from 'reducers/system';
import request from 'superagent';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import {MeContext} from 'meContext';

const GoogleIcon = () => (
    <div style={{padding: 5, borderRadius: 2}}>
        <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
            <g fill="#000" fillRule="evenodd">
                <path
                    d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
                    fill="#EA4335"
                />
                <path
                    d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
                    fill="#4285F4"/>
                <path
                    d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
                    fill="#FBBC05"
                />
                <path
                    d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
                    fill="#34A853"
                />
                <path fill="none" d="M0 0h18v18H0z"/>
            </g>
        </svg>
    </div>
);

const useStyles = makeStyles((theme) => (
    {
        root:         {
            display:  'flex',
            flexWrap: 'wrap',
        },
        margin:       {
            // margin: theme.spacing(1),
        },
        withoutLabel: {
            marginTop: theme.spacing(3),
        },
        textField:    {
            // width: '25ch',
        },
    }
));

function TabPanel(props) {
    const {children, value, index, ...other} = props;
    
    return (
        <div
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                <React.Fragment>
                    {children}
                </React.Fragment>
            )}
        </div>
    );
}

export default function MeLoader(props) {
    const [me, setMe] = React.useState(null);
    // const loading               = options.length === 0;
    const dispatch    = useDispatch();
    React.useEffect(() => {
        let active = true;
        
        // if (!loading) {
        //     return undefined;
        // }
        
        (
            async () => {
                try {
                    const response = await request.get('/api/me?')
                        .set('accept', 'application/json')
                        .query({});
                    if (active) {
                        dispatch({
                            type:     SYSTEM.RECEIVE,
                            endPoint: 'me',
                            payload:  {items: response.body},
                        });
                        setMe(response.body);
                    }
                }
                catch (error) {
                    console.log('error', error);
                    setMe(error);
                }
            }
        )();
        
        return () => {
            active = false;
        };
    }, [dispatch]);
    
    const classes             = useStyles();
    const [values, setValues] = React.useState({
        login:        '',
        password:     '',
        showPassword: false,
    });
    
    const handleChange            = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };
    const handleClickShowPassword = () => {
        setValues({...values, showPassword: !values.showPassword});
    };
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    
    const api = new API();
    
    const onError = (error) => {
        //error.rawResponse
        if (403 === error.status) {
            setValues({...values, error: 'Неверное имя пользователя или пароль.'});
        }
    };
    
    const handleSubmit = (values) => {
        setValues({...values, error: false});
        return api.post(
            'auth',
            {
                login:    values.login,
                password: values.password,
            },
            onError,
        ).then(
            date => {
                console.log('options setMe',date.body)
                if(date && date.statusCode === 200){
                    dispatch({
                        type:     SYSTEM.RECEIVE,
                        endPoint: 'me',
                        payload:  {items: date.body},
                    });
                    setMe(date.body);
                }
            },
        );
    };
    
    const handleRecover = (values) => {
        setValues({...values, error: false});
        return api.post('recover', {
                email: values.login,
            },
            error => setValues({...values, error: error.response.body.error}),
        ).then(
            date => date && setMe(date.body),
        );
    };
    
    const toGoogleAuth = () => {
        window.location.replace(
            window.location.origin + '/connect/google?',
        );
    };
    /*
        const DialogTitle2 = withStyles(styles)((props) => {
            const { children, classes, onClose, ...other } = props;
            return (
                <MuiDialogTitle disableTypography className={classes.root} {...other}>
                    <Typography variant="h6">{children}</Typography>
                    {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </MuiDialogTitle>
            );
        });
        */
    const [tab, setTab] = React.useState(0);
    
    if (me && me.roles) {
        const App = props.App;
        return <MeContext.Provider value={{...me, setOptions: setMe}}>
            <App/>
            {console.log('options',{options: me})}
            {/*<pre>{JSON.stringify(me,null,2)}</pre>*/}
        </MeContext.Provider>;
    }
    else {
        
        return <Dialog
            maxWidth={'xs'}
            open={true}
        >
            <AppBar position="static" color="default">
                <Tabs
                    value={tab}
                    onChange={(e, v) => setTab(v)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                
                >
                    <Tab label="Форма входа"/>
                    {/*<Tab label="Восстановить пароль"/>*/}
                </Tabs>
            </AppBar>
            <TabPanel value={tab} index={0}>
                <DialogTitle>
                    <Typography>Укажите реквизиты для входа</Typography>
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <Grid
                            container
                            justify="center"
                            alignItems="center"
                            spacing={2}
                        >
                            {/*<Grid item xs={12}>*/}
                            {/*    <Button*/}
                            {/*        title={'Вход через google'}*/}
                            {/*        variant={'contained'}*/}
                            {/*        color={'default'}*/}
                            {/*        fullWidth*/}
                            {/*        onClick={toGoogleAuth}*/}
                            {/*    >*/}
                            {/*        <GoogleIcon/>*/}
                            {/*        Вход через google*/}
                            {/*    </Button>*/}
                            {/*</Grid>*/}
                            <Grid item xs={12}>
                                <TextField
                                    label={'Почта для входа'}
                                    value={values.login}
                                    onChange={handleChange('login')}
                                    variant={'outlined'}
                                    fullWidth
                                />
                            
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    className={clsx(classes.margin, classes.textField)}
                                    fullWidth
                                    variant="outlined"
                                >
                                    <InputLabel htmlFor="outlined-adornment-password">Пароль</InputLabel>
                                    <OutlinedInput
                                        label="Пароль"
                                        type={values.showPassword
                                            ? 'text'
                                            : 'password'}
                                        value={values.password}
                                        onChange={handleChange('password')}
                                        fullWidth
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {values.showPassword
                                                        ? <Visibility/>
                                                        : <VisibilityOff/>}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            {values.error && <Grid item xs={12}>
                                <ErrorMessage title={values.error}/>
                            </Grid>}
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        title={'Вход'}
                        variant={'contained'}
                        color={'primary'}
                        style={{width: '500px'}}
                        fullWidth
                        onClick={() => handleSubmit(values)}
                        // onClick={addNew}
                    >
                        Вход
                    </Button>
                </DialogActions>
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <DialogTitle>
                    <Typography>Запросить восстановление пароля</Typography>
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={handleRecover}>
                        <Grid
                            container
                            justify="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <Grid item xs={12}>
                                <TextField
                                    label={'Почта для восстановления'}
                                    value={values.login}
                                    onChange={handleChange('login')}
                                    variant={'outlined'}
                                    fullWidth
                                />
                            </Grid>
                            {values.error && <Grid item xs={12}>
                                <ErrorMessage title={values.error}/>
                            </Grid>}
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        title={'Запросить восстановление'}
                        variant={'contained'}
                        color={'primary'}
                        style={{width: '500px'}}
                        fullWidth
                        onClick={() => handleRecover(values)}
                    >
                        Запросить
                    </Button>
                </DialogActions>
            
            </TabPanel>
        </Dialog>;
    }
}
