import AdminShow from 'components/FieldFormat/AdminShow';
import {FormDataContext} from 'FormDataContext';
import React from 'react';

export default function(props) {
    const {submitting, item} = props;
    const { field } = item;
    
    const {state: {values, errors}, handleChange, setState} = React.useContext(FormDataContext);
    
    return <React.Fragment>
        <AdminShow>[Custom]</AdminShow>
        <item.custom
            submitting={submitting}
            name={field}
            value={values[field]}
            onChange={handleChange(item)}
            error={!!errors[field]}
            helperText={errors[field]}
            values={values}
            errors={errors}
            setState={setState}
            originalProp={props}
        />
    </React.Fragment>;
}