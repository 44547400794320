import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';

const defaultProps = {
    title:    'Требуется подтверждение',
    text:     'Подтвердите выполнение действия',
    confirm:  'Да',
    cancel:   'Нет',
    onCancel: () => {},
};

function ConfirmDialog(props) {
    const {title, text, confirm, cancel, trigger, children, onCancel, onConfirm, show} = {...defaultProps, ...props};
    
    const [open, setOpen] = React.useState(false);
    
    const handleClickOpen = (event) => {
        event.preventDefault();
        setOpen(true);
    };
    
    const handleClose = (e) => {
        setOpen(false);
        onCancel(e);
    };
    
    const handleSubmit = (e) => {
        setOpen(false);
        onConfirm(e);
    };
    
    return <React.Fragment>
        {!show && <span onClick={handleClickOpen}>{trigger || children}</span>}
        <Dialog open={open || show} onClose={handleClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{text}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {cancel}
                </Button>
                <Button onClick={handleSubmit} color="primary" autoFocus>
                    {confirm}
                </Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>;
}

ConfirmDialog.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    
    title:    PropTypes.string,
    text:     PropTypes.string,
    confirm:  PropTypes.string,
    cancel:   PropTypes.string,
    onCancel: PropTypes.func,
    trigger:  PropTypes.node,
};

export default ConfirmDialog;