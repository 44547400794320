import React from 'react';
import {
    useSelector,
} from 'react-redux';
import {
    Avatar,
    IconButton,
    Menu,
    MenuItem, Modal,
    Typography,
} from '@material-ui/core';

export default function MyProfile() {
    const logout = '/connect/logout';
    const me     = useSelector(state => state.system.me && state.system.me.items) || {};
    
    const [anchorEl, setAnchorEl]     = React.useState(null);
    const [openOption, setOpenOption] = React.useState(null);
    
    const openMenu = openOption === 'menu';
    
    const onMenuOpen = name => (event) => {
        setAnchorEl(event.currentTarget);
        setOpenOption(name);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
        setOpenOption(null);
    };
    
    return <React.Fragment>
        <Typography variant={'body1'}>
            <IconButton onClick={onMenuOpen('menu')}>
                <Avatar alt={me.username} src={me.picture}/>
            </IconButton>
            {me.username}
        </Typography>
        <Modal open={openMenu}>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical:   'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical:   'top',
                    horizontal: 'right',
                }}
                open={openMenu}
                onClose={handleClose}
            >
                <MenuItem onClick={onMenuOpen('settings')}>Настройки</MenuItem>
                <MenuItem onClick={handleClose}>Закрыть</MenuItem>
                <MenuItem onClick={() => {window.location.replace(logout);}}>Выход</MenuItem>
            </Menu>
        </Modal>
    </React.Fragment>
        ;
    
}

