import AdminShow from 'components/FieldFormat/AdminShow';
import FormApiAutocomplete from 'components/FieldFormat/FormApiAutocomplete';
import FormMultiElements from 'components/FormMultiElements';
import {FormDataContext} from 'FormDataContext';
import {apiSplit} from 'libs/apiSplit';
import React from 'react';

function MyComponent({submitting, item, tracePath, value, error, handleChange, apiFilter}) {
    const {disabled, title, api, required, endAdornment, helperText, noOptionsText} = item;

    // const {state: {values, errors}, handleChange} = React.useContext(FormDataContext);

    return <React.Fragment>
        <AdminShow>[SelectApi]</AdminShow>
        {item.multi
        ?
        <FormMultiElements
            item={item}
            label={title}
            value={value}
            api={api}
            apiFilter={apiFilter}
            required={required}
            error={!!error}
            errors={value}
            onChange={handleChange}
            InputLabelProps={{shrink: true}}
            tracePath={[...tracePath, 'FormMultiElements']}
        />
        : <FormApiAutocomplete
            item={item}
            disabled={disabled || submitting}
            label={title}
            value={value && apiSplit(value)}
            api={api}
            apiFilter={apiFilter}
            required={required}
            error={!!error}
            errors={error}
            noOptionsText={noOptionsText}
            helperText={helperText}
            endAdornment={endAdornment}
            onChange={handleChange}
            InputProps={{color: 'secondary'}}
            InputLabelProps={{shrink: true}}
            tracePath={[...tracePath, 'FormApiAutocomplete']}
        />}
    </React.Fragment>;
}

function areEqual(prevProps, nextProps) {
    return prevProps.value === nextProps.value
        && prevProps.error === nextProps.error
        && JSON.stringify(prevProps.apiFilter) === JSON.stringify(nextProps.apiFilter);
}

const MyComponentMemo = React.memo(MyComponent, areEqual);

function MyComponentMemoUse(props) {
    const {parent} = props;
    const {field,apiFilter}                                 = props.item;
    const {state: {values, errors}, handleChange} = React.useContext(FormDataContext);

    const handleChangeLocal                       = (e) => {
        return handleChange(props.item)(e, e.target.value);
    };

    const apiFilterLocal = apiFilter && apiFilter(values, parent)
    const value = values && values[field];
    const error = values && errors && errors[field];

    return <MyComponentMemo
        value={value || ''}
        error={error || null}
        apiFilter={apiFilterLocal}
        handleChange={handleChangeLocal}
        {...props}
    />;
}

export default MyComponentMemoUse;