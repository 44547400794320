import {Paper, Typography} from '@material-ui/core';
import ApiDataLoader from 'components/ApiDataLoader';
import AdminShow from 'components/FieldFormat/AdminShow';
import {ApiDataLoad} from 'components/showItems';
import ShowSimplePage from 'components/ShowSimplePage';
import TableApiParamsLoader from 'components/TableApiParamsLoader';
import React from 'react';
import {Redirect, Route, useParams, useRouteMatch} from 'react-router-dom';
import PageApiShow3 from './PageApiShow3';

function RedirectEdit(props) {
    let {url}  = props;
    let {guid} = useParams();
    return <Redirect
        to={{
            pathname: `${url}/${guid}/edit`,
        }}
    />;
}


const guidMask = '\\w{8}-\\w{4}-\\w{4}-\\w{4}-\\w{12}||\\d+';

export default function PageApiLoader3(props) {
    if (window.tracePath) {
        console.log('tracePath: ', props.tracePath.join('/'));
    }
    
    const [goList, setGoList] = React.useState(false);
    let {title}               = props;
    
    let {url} = useRouteMatch();
    
    function RedirectList({url}) {
        setGoList(false);
        return <Redirect to={{pathname: `${url}`}}/>;
    }

    return <React.Fragment>
        <AdminShow>[PageApiLoader3]</AdminShow>
        <Route exact={true} path={`${url}/:guid(${guidMask})`}>
            {/*<RedirectEdit url={url}/>*/}
            <ApiDataLoader {...props} url={url} AfterComponent={ShowSimplePage} />
        </Route>
        <Route path={[
            `${url}/:action(new)`,
            `${url}/:guid(${guidMask})/:action(edit)`,
            `${url}/:guid(${guidMask})/:sublist`,
            // `${url}/:guid(${guidMask})`,
        ]}>
            {goList && <RedirectList url={url}/>}
            <TableApiParamsLoader
                {...props}
                url={url}
                onElementUpdate={() => setGoList(true)}
                tracePath={[...props.tracePath, 'TableApiParamsLoader']}
            />
        </Route>
        <Route path={url} exact={true}>
            <Typography variant={'h5'}>{title}</Typography>
            <Paper>
                <PageApiShow3
                    newTable
                    {...props}
                    url={url}
                    tracePath={[...props.tracePath, 'PageApiShow3']}
                />
            </Paper>
        </Route>
    </React.Fragment>;
    
}