import {
    AllInbox,
    FormatIndentDecrease,
    FormatIndentIncrease,
    ListAlt as MenuIcon,
    VisibilityOutlined,
} from '@material-ui/icons';

import {ApiDataLoadLookup, ColObjectName, ShowCurrency, ShowDate} from 'components/showItems';
import {FieldFormat} from 'config/FieldFormat';
import {ROLES} from 'config/roles';
import PageDocRequests from 'page/PageDocRequests';
import React from 'react';
import SumDelivery from 'components/FieldFormat/SumDelivery';

// TODO: need update to promise
// const lookupServices      = ApiDataLoadLookup('/api/services', 'guid', 'name');
//const lookupRoutePoints   = ApiDataLoadLookup('/api/route_points', 'guid', false);
// const lookupContactPeople = ApiDataLoadLookup('/api/contact_people', 'guid', 'name');
// const lookupLegals        = ApiDataLoadLookup('/api/legals?exists[contragent]=false', 'guid', 'name');
// const lookupCargoTypes    = ApiDataLoadLookup('/api/cargo_types', 'guid', 'name');

// const lookupServices      = ApiDataLoadLookup('/api/services', 'guid', 'name');
// const lookupRoutePoints   = ApiDataLoadLookup('/api/route_points', 'guid', 'address');
// const lookupContactPeople = ApiDataLoadLookup('/api/contact_people', 'guid', 'name');
// const lookupLegals        = ApiDataLoadLookup('/api/legals', 'guid', 'name');
// const lookupCargoTypes    = ApiDataLoadLookup('/api/cargo_types', 'guid', 'name');

export function generateKey(setRowDataKeyId) {
    let key = (
        [1e7] + -1e3 + -4e3 + -8e3 + -1e11
    ).replace(/[018]/g, c => (
        c ^ (
            (
                crypto.getRandomValues(new Uint8Array(1))[0] & 15
            ) >> c
        ) / 4
    ).toString(16));
    setRowDataKeyId(key);
    return key;
}

const findService = services => place => {
    const getCost = service => Number(service.service_cost);
    return services
        .filter(service => service.key_id && service.key_id === place.keyId)
        .map(getCost)
        .reduce((A, B) => A + B, 0);

};

const calcSumServiceInPlace = (place, values) => {
    return place && Array.isArray(place) && values.services
        ? place.map(findService(values.services))
            .reduce((A, B) => A + B, 0)
        : 0;
};


export default {
    listTitle:        'Заявки на транспорт',
    apiName:          'doc_requests',
    itemName:         'заявку на транспорт',
    roles:            [
        ROLES.ROLE_USER,
    ],
    menu:             {
        title:     'Заявки на транспорт',
        path:      '/doc_requests',
        Component: PageDocRequests,
        icon:      MenuIcon,
        roles:     [ROLES.ROLE_USER, ROLES.ROLE_CLIENT],
    },
    topColsBlocks:    [
        {
            num:  0,
            cols: 1,
        },
        {
            num:   1,
            title: 'Информация о заявке',
            cols:  2,
        },
        {
            num:   2,
            title: 'Требования к транспорту',
            cols:  2,
        },
        {
            num:   3,
            title: 'Стоимость заявки',
            cols:  2,
        },
        {
            num:   4,
            title: 'Информация о перевозчике',
            cols:  2,
        },
    ],
    initialValues:    () => (
        {
            docNumber: 'АВТО',
        }
    ),
    onFormDataAction: (values, props) => {
        const {old, action, value, field} = props;
        console.trace('props', values, props);
        let _fix = {};
        const changeFields = ['carrier', 'typedTransport', 'loadingPlaces', 'unloadingPlaces'];
        if (old && action === 'change' && 'sumDelivery' === field) {
            _fix['tarifFrom'] = undefined;
            _fix['tarifTo'] = undefined;
            _fix['tarifDocument'] = undefined;
            _fix['tarifCurrency'] = undefined;
            _fix['tarifCost'] = undefined;
        }
        if (old && action === 'change' && changeFields.includes(field)) {
            _fix['_needReCalcDelivery'] = true;
        }
        const ndsRateValue = values.ndsRateValue || 1;
        const sumServices  = (
            calcSumServiceInPlace(values.loadingPlaces, values)
            + calcSumServiceInPlace(values.unloadingPlaces, values)
        ) || 0;
        const sumDelivery  = Number(values.sumDelivery) || 0;
        return {
            ...values,
            ..._fix,
            sumServices:    sumServices,
            sumTotal:       sumDelivery + sumServices,
            sumDeliveryNds: Math.round(100 * sumDelivery * ndsRateValue) / 100,
            sumServicesNds: Math.round(100 * sumServices * ndsRateValue) / 100,
            sumTotalNds:    Math.round(100 * (
                sumDelivery + sumServices
            ) * ndsRateValue, 2) / 100,
        };
    },
    colsInTab:        true,
    cols:             [
        {
            title:        'Номер документа',
            field:        'docNumber',
            hidden:       {xsUp: true},
            disabled:     true,
            cols:         2,
            topColsBlock: 1,
        },
        {
            title:        'Номер документа клиента',
            field:        'clientDocNumber',
            cols:         2,
            topColsBlock: 1,
            hidden:       true,
        },
        {
            title:        'Дата документа',
            field:        'docDate',
            object:       ShowDate,
            format:       FieldFormat.FORMAT_DATE,
            cols:         2,
            topColsBlock: 1,
            hidden:       true,
        },
        {
            title:        'Статус документа',
            api:          'doc_request_statuses',
            object:       ColObjectName,
            format:       FieldFormat.FORMAT_SELECT_API,
            fieldShow:    'fullName',
            field:        'status',
            cols:         2,
            topColsBlock: 1,
        },
        {
            title:        'Коментарий',
            field:        'comment',
            hidden:       true,
            topColsBlock: 1,
        },

        {
            title:        'Объем, м^3',
            field:        'transportValue',
            cols:         2,
            topColsBlock: 2,
        },
        {
            title:        'Грузоподьемность, кг',
            field:        'transportMass',
            cols:         2,
            topColsBlock: 2,
        },
        {
            title:        'Тип Кузова',
            api:          'carbody_types',
            object:       ColObjectName,
            format:       FieldFormat.FORMAT_SELECT_API,
            field:        'carbodyType',
            topColsBlock: 2,
            cols:         2,
        },
        {
            title:        'Тип загрузки',
            api:          'loading_types',
            field:        'loadingTypes',
            multiple:     true,
            format:       FieldFormat.FORMAT_SELECT_API_CHECKBOX,
            hidden:       true,
            cols:         2,
            topColsBlock: 2,
        },
        {
            title:        'Опасность',
            api:          'danger_classes',
            field:        'dangerClasses',
            multiple:     true,
            format:       FieldFormat.FORMAT_SELECT_API_CHECKBOX,
            hidden:       true,
            cols:         2,
            topColsBlock: 2,
        },

        {
            title:        'Валюта',
            object:       ShowCurrency,
            format:       FieldFormat.FORMAT_SELECT_API,
            api:          'currencies',
            field:        'currency',
            cols:         2,
            topColsBlock: 3,
            hidden:       true,
        },

        {
            title:        'Сумма доставки',
            type:         'float',
            field:        'sumDelivery',
            format:       FieldFormat.FORMAT_CUSTOM,
            custom:       props => <SumDelivery {...props.originalProp} />,
            cols:         2,
            topColsBlock: 3,
            hidden:       true,
        },
        {
            title:        'Сумма доставки c НДС',
            type:         'float',
            field:        'sumDeliveryNds',
            cols:         2,
            topColsBlock: 3,
            disabled:     true,
            hidden:       true,
        },
        {
            title:        'Сумма услуг',
            type:         'float',
            field:        'sumServices',
            cols:         2,
            readOnly:     true,
            disabled:     true,
            topColsBlock: 3,
            hidden:       true,
        },
        {
            title:        'Сумма услуг c НДС',
            type:         'float',
            field:        'sumServicesNds',
            cols:         2,
            readOnly:     true,
            topColsBlock: 3,
            disabled:     true,
            hidden:       true,
        },
        {
            title:        'Сумма итого',
            type:         'float',
            field:        'sumTotal',
            cols:         2,
            disabled:     true,
            topColsBlock: 3,
        },
        {
            title:        'Сумма итого c НДС',
            type:         'float',
            field:        'sumTotalNds',
            cols:         2,
            disabled:     true,
            topColsBlock: 3,
            hidden:       true,
        },
        // {
        //     title:        'НДС коэффициент',
        //     type:         'float',
        //     field:        'ndsRateValue',
        //     cols:         2,
        //     topColsBlock: 1,
        //     disabled:     true,
        //     hidden:       true,
        // },
        {
            title:        'Перевозчик',
            api:          'legals',
            apiFilter:    () => (
                {'exists[contragent]': true}
            ),
            field:        'carrier',
            format:       FieldFormat.FORMAT_SELECT_API,
            cols:         3,
            topColsBlock: 4,
            hidden:       true,
        },
        {
            title:        'Договор',
            // apiFilter:    values => (
            //     {
            //         contragent: values.carrier || null,
            //     }
            // ),
            object:       ColObjectName,
            api:          'contracts',
            apiFilter:    values => (
                {
                    // 'exists[legal.contragent]': true,
                    contragent: values.carrier || null,
                }
            ),
            format:       FieldFormat.FORMAT_SELECT_API,
            field:        'carrierAgreement',
            cols:         3,
            topColsBlock: 4,
            hidden:       true,
        },
        {
            title:        'Водитель',
            object:       ColObjectName,
            api:          'drivers',
            apiFilter:    values => (
                {
                    // 'exists[contragent]': true,
                    legal: values.carrier || null,
                }
            ),
            field:        'carrierDriver',
            format:       FieldFormat.FORMAT_SELECT_API,
            cols:         3,
            topColsBlock: 4,
            hidden:       true,
        },

        {
            title:        'Звено 1',
            api:          'transports',
            apiFilter:    values => (
                {legal: values.carrier || null}
            ),
            field:        'carrierTransport',
            fieldIndex:   0,
            multiple:     true,
            format:       FieldFormat.FORMAT_SELECT_API_INDEX,
            cols:         3,
            topColsBlock: 4,
            hidden:       true,
        },
        {
            title:        'Звено 2',
            api:          'transports',
            apiFilter:    values => (
                {legal: values.carrier || null}
            ),
            field:        'carrierTransport',
            fieldIndex:   1,
            multiple:     true,
            format:       FieldFormat.FORMAT_SELECT_API_INDEX,
            cols:         3,
            hidden:       true,
            topColsBlock: 4,
        },
        {
            title:        'Звено 3',
            api:          'transports',
            apiFilter:    values => (
                {legal: values.carrier || null}
            ),
            field:        'carrierTransport',
            fieldIndex:   2,
            multiple:     true,
            format:       FieldFormat.FORMAT_SELECT_API_INDEX,
            cols:         3,
            hidden:       true,
            topColsBlock: 4,
        },
        {
            title:        'Вид транспорта',
            field:        'typedTransport',
            format:       FieldFormat.FORMAT_SELECT_API,
            api:          'typed_transports',
            object:       ColObjectName,
            cols:         2,
            topColsBlock: 2,
            hidden:       true,
        },

        // {
        //     title: 'loadingPlace',
        //     field: 'loadingPlace',
        // },
        // {
        //     title: 'unloadingPlace',
        //     field: 'unloadingPlace',
        // },
        // {
        //     title: 'cargo',
        //     field: 'cargo',
        // },
        // {
        //     title: 'dangerClass',
        //     field: 'dangerClass',
        // },
        // {
        //     title: 'services',
        //     field: 'services',
        // },
        // {
        //     title: 'clientProfile',
        //     field: 'clientProfile',
        // },
        // {
        //     title: 'userAuthor',
        //     field: 'userAuthor',
        // },
    ],


};
