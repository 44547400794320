import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import ConfirmDialog from 'components/ConfirmDialog';

const ActionConfirmDialog = props => {
    const {values, title, onClose, submitting} = props;
    return <ConfirmDialog
        onConfirm={onClose(values)}
        trigger={<Button
            color={'secondary'}
            disabled={submitting}
            variant={'outlined'}
        >
            {title}
        </Button>}
    />;
};

export default ActionConfirmDialog;